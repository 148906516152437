import React, { Component } from 'react'


class LocalBusinessSpotlightContentMRSite extends Component {
  

  render() {
    return (
      <>
      <div className="mrSite-home">
      	<h1>Mariner’s Lodge Hotel<br/><br/></h1>


        <div className="home-banner-just4Fun">
          <img src="https://cdn.huntakiller.com/huntakiller/s9/photos/town/hotel-exterior.jpg"  alt="local business spotlight banner"/>
        </div>

        <div className="mrSite-localBusiness-content">

        	<div className="mrSite-localBusinessimg-container">

	        	<img src="https://cdn.huntakiller.com/huntakiller/s9/photos/town/gwen.jpg"  alt="gwen picture"/>

	        	<img src="https://cdn.huntakiller.com/huntakiller/s9/photos/town/hotel-interior.jpg"  alt="gwen picture"/>

        	</div>


        	<p>Proprietor of the Mariner’s Lodge Hotel, Gwen Ferris, is offering a 30% discount on all stays during the off-season.<br/><br/>
This mid-century gem was built in 1959 and had the distinction of being Mallory Rock’s first budget motor inn. Despite its initial success, the building fell into disrepair over the years and was abandoned by its previous owners in 2016.<br/><br/>
Two years ago, Ms. Ferris, with the assistance of local developer Martin Hendricks, renovated the property, reopening it for business.<br/><br/>
Guests at the new Mariner’s Lodge Hotel receive free parking and cable TV, and can relax at the outdoor pool (in-season). The hotel has consistently received four or more stars on TripMate.<br/><br/>
The next time you have guests in town, tell them to give the Mariner’s Lodge a try!</p>

        </div>
        
          
        
      </div>
      </>
    )
  }
}



export default LocalBusinessSpotlightContentMRSite
