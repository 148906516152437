import React, { Component } from 'react'
import store from 'store';
import {getUser} from '../utils.js';

export default class LoginView extends Component {

	constructor(props) {
	    super(props);
	    this.state = {
	      password: "",
	      error: false,
	    };
	    store.set('password', false);
	}

	validateForm() {
    	/*return this.state.password.length > 0;*/
    	return true
  	}


  	handleChange = event => {
  		
	    this.setState({
	      [event.target.id]: event.target.value
	    });
	 }

	handleSubmit = event => {
		//console.log("submit")
	    event.preventDefault();
	    document.getElementById("password").blur();
	    const password = this.state.password;

	    this.setState({ error: false });

	    var user = getUser(password, this.props.users);
		
		//console.log(user)
		//console.log(this.props.selectedSeason)

		if (user == null || (user.season !== this.props.selectedSeason.season)){
		    // wrong PW
		    return this.setState({ error: true });
		}else{
			// redirect to correct season
			//console.log(user)

			store.set('password', password);

			setTimeout(
			    function() {
			        this.props.history.push(user.seasonurl)
			    }
			    .bind(this),
			    500
			);
			
		}

	    
	}

	handleKeyPress = (event) => {
	  if(event.key === 'Enter'){
	    //console.log('enter press here! ')
	    document.activeElement.blur();
	    event.preventDefault();
	  }
	}


    render() {

    	const { error } = this.state;

        return (<div className="h-full w-full flex justify-center items-center z-10">

	        	<div className="login-box rounded-lg flex flex-col justify-center items-center p-4 pt-10 pb-8">
	        		
		        	<h1>{this.props.selectedSeason.name}</h1>

		        	<form className=" rounded  pt-6 " onSubmit={this.handleSubmit}>
		        		<div className="">
		        			
              				<div className=" mb-3">
			        			<input className="shadow appearance-none border  w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" value={this.state.password}
	              onChange={this.handleChange} type="password" autoFocus id="password" placeholder="Password" onKeyPress={this.handleKeyPress}/>
	              				<p className="error mt-2">{error && "Invalid Password"} &nbsp;</p>
              				</div>
		        		</div>
		        		<div className="flex items-center justify-between">
					      <button className="bg-grey mx-8  w-full text-black font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" id="seasonLogin" type="submit" disabled={!this.validateForm()}>
					        ENTER
					      </button>
					     
					    </div>
		        	</form>
		        	<a href="/" className="mt-2"  onClick={this.props.tapBack}>BACK</a>
		        </div>
	        </div>)
    }
}
