import React, { Component } from 'react'
import store from 'store'
import DesktopView from './views/DesktopView'
//import { users } from './datas/users.json';
import { icons } from './datas/season7/icons.json';
import {getUser} from './utils.js';


const users = JSON.parse(process.env.REACT_APP_EPISODE_PW);

class Cadence extends Component {

	constructor(props) {
	    super(props);

      	this.currentEpisode = 1;
	    const password = store.get("password");

	    if (password == null || password === false){
	    	console.log("notloggedin")
	    	props.history.push('/')
	    }else{
				    	
	    	this.user = getUser(password, users);
	    	//console.log(this.user)
	    	if (this.user != null && this.user.seasonurl === "cadence"){
				this.currentEpisode = this.user.episode
			}else{
				props.history.push('/')
			}
	    }
	    
	}


	render() {
		
		const currentEpisode = this.currentEpisode
		//console.log(episodeIndex)
		/*const iconsCurrentEpisode = icons.filter(icon => {
			return ((icon.episode <=  currentEpisode) )
		})*/

		//console.log(iconsCurrentEpisode)

		return (
		  
		  <DesktopView user={this.user || {}} className="cadence" icons={icons} seasonurl="cadence" currentEpisode={currentEpisode} history={this.props.history}  />
		  



		)

	}
}

export default Cadence