import React, { Component } from 'react'
import store from 'store';

var CORRECT_PASSWORD = "IWUF";

class LockScreenKeepSafeSite extends Component {
  
  

  constructor(props) {
	    super(props);
	    this.state = {
	      password1: "",
	      password2: "",
	      password3: "",
	      password4: "",
	      errorPw: false,
	    };
	}



	validateForm() {
    	//return this.state.password.length > 0;
    	return true
  	}


  	handleChange = event => {
  		
  		if(event.target.value.length <= 1){
  			this.setState({
		      [event.target.id]: event.target.value
		    });
  		}
	    
	 }

	handleSubmit = event => {
		//console.log("submit")
	    event.preventDefault();
	    document.getElementById("password1").blur();
	    document.getElementById("password2").blur();
	    document.getElementById("password3").blur();
	    document.getElementById("password4").blur();
	   
	    var pw = this.state.password1+this.state.password2+this.state.password3+this.state.password4;

	    console.log(pw.toUpperCase());
	    if(pw.toUpperCase() === CORRECT_PASSWORD) {
	    	this.props.tapMenu("home");
	    	store.set('keepsafe', CORRECT_PASSWORD);

	    }else {
	    	this.setState({ errorPw: true });
	    }
	    
		/*if (doesSeasonPasswordExist(password, this.props.users) === false){
		    this.setState({ errorPw: true });
		}else{
			store.set('season', password);
			
			setTimeout(
			    function() {
			        this.props.history.push("Desktop")
			    }
			    .bind(this),
			    500
			);
		} */
	}


	handleKeyPress = (event) => {
	  if(event.key === 'Enter'){
	    //console.log('enter press here! ')
	    document.activeElement.blur();
	    event.preventDefault();
	  }
	}



  render() {
    return (
      <>
      <div className="keepsafe-main-locked-screen">

        
        <div className="header">
        	<img src="https://cdn.huntakiller.com/huntakiller/s9/icons/keepsafe/keepsafe-logo.svg"  alt="logo keep sage" className="logo"/>

        	<img className=" close-icon "  alt="close-dt" onClick={this.props.tapBack} src="https://cdn.huntakiller.com/huntakiller/s8/closebutton.svg"/>
		        	
        </div>


        <div className="keepsafe-main-locked-screen-content">

        	<div className="login-box rounded-lg flex flex-col justify-center items-center p-4 pt-10 pb-8">
        		<div className="  px-6 w-full">
        			<h2 className=" text-center pb-3">LOG IN</h2>
	        	</div>
	        	
	        	<p className="text-center mt-3 mb-3"><b>Enter username and password</b><br/>Years of light shift all things, even stone.</p>

	        	<form className=" rounded  pt-3  w-full px-6" onSubmit={this.handleSubmit}>
	        		<div className="">


	        			<div className=" mb-3">
	        				<input className="shadow appearance-none border  w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" value="Bferris"
          onChange={this.handleChange} type="text" autoFocus id="username" placeholder="Username" disabled={true}/>
         					
          				</div>


	        	
	        			
          				<div className=" mb-3">
	          				<div className="flex">

	          <input className="shadow appearance-none border uppercase mr-6 text-center  w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" value={this.state.password1}
              onChange={this.handleChange} type="text" maxLength="1" autoFocus id="password1" onKeyPress={this.handleKeyPress}/>

              <input className="shadow appearance-none border uppercase mr-6 text-center  w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" value={this.state.password2}
              onChange={this.handleChange} type="text"  maxLength="1" id="password2" onKeyPress={this.handleKeyPress}/>

              <input className="shadow appearance-none border uppercase mr-6 text-center  w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" value={this.state.password3}
              onChange={this.handleChange} type="text"  maxLength="1" id="password3" onKeyPress={this.handleKeyPress}/>

              <input className="shadow appearance-none border uppercase  w-full text-center py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" value={this.state.password4}
              onChange={this.handleChange} type="text" maxLength="1"  id="password4" onKeyPress={this.handleKeyPress}/>

	          				</div>
		        			
              				<p className="error mt-2">{this.state.errorPw && "Incorrect Password"} &nbsp;</p>
          				</div>
	        		</div>
	        		<div className="flex items-center justify-between">
				      <button className="bg-grey mx-8  w-full text-black font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" id="episodeLogin" type="submit" disabled={!this.validateForm()}>
				        ENTER
				      </button>
				     
				    </div>
	        	</form>

	        </div>


        </div>
          
        
      </div>
      </>
    )
  }
}



export default LockScreenKeepSafeSite
