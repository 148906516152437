import React, { Component } from 'react'

/*import './menuContent.css'*/

class MenuContentKeepSafeSite extends Component {
  constructor(props) {
    super(props)

    //console.log(this.props.currentView)
    this.items = [["Files","keepsafe-files-folder-icon.svg"]  ,["Recent","keepsafe-files-recent-icon.svg"],["Starred","keepsafe-files-starred-icon.svg"],["Recycled Bin","keepsafe-files-trash-icon.svg"],["Storage","keepsafe-files-storage-icon.svg"]]
    
  }

  render() {
    return (
      <ul className="menu">
        {this.items.map(i => 
          <li className={ i === this.props.currentView ? 'current menu-item flex' : 'menu-item flex' } key={i[0]}  onClick={() => this.props.tapMenu(i[0])}><img src={"https://cdn.huntakiller.com/huntakiller/s9/icons/keepsafe/"+i[1]}/>{i[0]}</li>
        )}    
      </ul>
    )
  }
}



export default MenuContentKeepSafeSite
