import React, { Component } from 'react'


class CurrentPropertiesContentJWESite extends Component {
  

  render() {
    return (
      <>
      <div className="JWESite-home">

        <h1>CURRENT PROPERTIES</h1>
        <h2>1417 WHITECAP WAY</h2>
        <div className="flex JWESite-currentprop-content">
        	<img src="https://cdn.huntakiller.com/huntakiller/s9/photos/jwe/1417-whitecapway-1.jpg"  alt="home banner"/>
          	<div>
          		<img src="https://cdn.huntakiller.com/huntakiller/s9/photos/jwe/1417-whitecapway-2.jpg"  alt="home banner"/>
          		<img src="https://cdn.huntakiller.com/huntakiller/s9/photos/jwe/1417-whitecapway-3.jpg"  alt="home banner"/>
          	</div>
            
        </div>
        
        <p><b><span className="red-text">3 bed/2 bath</span></b><br/><br/>
        <b><span className="red-text">Highlights:</span></b> All new hardwood floors, new roof, sunroom addition, tile backsplash,
kitchen island with granite countertops<br/><br/>
<b><span className="red-text">Market Price:</span></b> $516,000

        </p>
        
        <div className="hr"></div>

        <h2>649 SUMMER STREET</h2>

        <div className="flex JWESite-currentprop-content">
        	<img src="https://cdn.huntakiller.com/huntakiller/s9/photos/jwe/649-summerstreet-1.jpg"  alt="home banner"/>
          	<div>
          		<img src="https://cdn.huntakiller.com/huntakiller/s9/photos/jwe/649-summerstreet-2.jpg"  alt="home banner"/>
          		<img src="https://cdn.huntakiller.com/huntakiller/s9/photos/jwe/649-summerstreet-3.jpg"  alt="home banner"/>
          	</div>
            
        </div>
        
        <p><b><span className="red-text">2 bed/1.5 bath</span></b><br/><br/>
        <b><span className="red-text">Highlights:</span></b> New countertops, new roof, open floor plan, all windows replaced,
newly installed sump pump<br/><br/>
<b><span className="red-text">Market Price:</span></b> $339,500

        </p>
        
        <div className="hr"></div>
        
      </div>
      </>
    )
  }
}



export default CurrentPropertiesContentJWESite
