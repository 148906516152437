//BLACKLIGHT CONCERT?!!!?!?!

import React, { Component } from 'react'



class Thread13 extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      extended: false
    };
  }



  toggleExpand = () =>{
    this.setState({ extended: !this.state.extended });
  }


  render() {

    return (
      <div className="thread-content">
        <div className="thread-header" onClick={this.toggleExpand}>
          <p className="thread-username"> Posted by <span className="font-bold">Honey_Arcade: </span></p>
          <h3 className={ !this.state.extended ? "thread-title-margin" : "" }>BLACKLIGHT CONCERT?!!!?!?!</h3>

        </div>


        {
            this.state.extended &&
            <>
              <div className="thread-header" onClick={this.toggleExpand} >
                  <p>NEXT MONTH!!! And they’re donating the proceeds to Jake’s fav charity omg<span role="img" aria-label="very-sad-imojo">😭</span></p>
              </div>
              <div className="thread-reply-container">
                <div className="thread-reply">
                  <div className="inner-thread-reply  border-left">
                    <p className="font-bold reply-username">MrsFontes24:</p>
                    <p className="reply-content">i’m literally so excited ajgkadlflasjfkd</p>
                  </div>
                  <p className="reply-btn font-bold">... reply</p>

                </div>

                 <div className="thread-reply">
                    <div className="inner-thread-reply  border-left">
                      <p className="font-bold reply-username">just_funfunfunfun:</p>
                      <p className="reply-content">It’s really cool that they’re doing this</p>
                    </div>
                    <p className="reply-btn font-bold">... reply</p>

                 </div>

                 <div className="thread-reply">
                    <div className="inner-thread-reply  border-left">
                      <p className="font-bold reply-username">ollie_oop111:</p>
                      <p className="reply-content">Almost cried when I heard!! They probably worked so hard planning this. I know it’s gonna be so fun but like since it’s a memorial too everyone’s gonna cry ugh I’m not prepared for the roller coaster of emotions</p>
                    </div>
                    <p className="reply-btn font-bold">... reply</p>
                 </div>

               
                 
              </div>
               <div className="thread-comment">
                  <div className="divider"></div>

                  <input placeholder="Sign In to Comment" disabled={true}/>
               </div>
               <div className="divider"></div>

            </>
        }



      </div>
    )
  }
}



export default Thread13
