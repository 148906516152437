import React, { Component } from 'react'

export default class Transcriptscriptnotes extends Component{
  render (){
    return (
    	<div className='rtfdoc'>
    <p>
	 Act I Scene I has been revised. Rehearsals for the updated script are to be held on February 25th. 
<br/><br/><br/>
Is that a new watch? It’s stunning. 
<br/><br/>
Thanks! ¤t’s m¤ l¤st thr¤¤ p¤¤ch¤cks.
<br/><br/>
Geez louise, Vi. That doesn’t seem responsible.
<br/><br/>
Mother taught me that p¤p¤r d¤ll¤rs aren’t as reliable as g¤ms. She left Romania with h¤r j¤w¤lr¤ sewn into the lining of her coat, you know. Besides, a single gal’s entitled to spoil herself a little. 
<br/><br/>
Good for you. There’s no need to wait around for a man to give you gifts. Sp¤¤k¤ng ¤f f¤ll¤s, wh¤t d¤ ¤¤¤ th¤nk ¤f ¤r¤?
<br/><br/>
Why do you ask? 
<br/><br/>
B¤tw¤¤n ¤¤¤ ¤nd m¤, w¤’v¤ b¤¤n s¤¤¤ng ¤¤ch ¤th¤r f¤r ¤ l¤ttl¤ wh¤l¤. H¤ s¤¤s h¤’s ¤v¤r th¤ m¤¤n f¤r m¤, b¤t h¤ w¤nts t¤ k¤¤p th¤ngs ¤n th¤ sl¤ f¤r n¤w. ¤pp¤r¤n¤tl¤, h¤ j¤st g¤t ¤¤t ¤f s¤m¤ fl¤ng. Pl¤s, ¤¤¤ kn¤w, ¤s w¤rk¤ng t¤g¤th¤r ¤nd ¤ll.
<br/><br/>
¤ h¤p¤ h¤ m¤k¤s ¤¤¤ h¤pp¤. Listen, I’m not feeling too hot. I need to step out for a moment. 
<br/><br/>
Oh, alright. ¤’ll c¤v¤r f¤r ¤¤¤.
<br/><br/><br/><br/>
    </p>

    
    </div>
    
  );
  }
};