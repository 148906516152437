import React, { Component } from 'react'

export default class Transcriptevelyniranotes extends Component{
  render (){
    return (
    	<div className='rtfdoc'>
	  <ul>
	  	<li>Add Lydecker and DeWitt to YCLIRH previews press list.</li>
	  	<li>Luncheon with Roderick Tuesday</li>
	  	<li>Check rigging before rehearsal</li>
	  	<li>Ask Joe about UC spot in 3rd act.</li>
	  	<li>Auditions for new understudy</li>
	  	<li>Hector’s entrance in “My Lovely Girl”—still early</li>
	  	<li>Fix those hideous hats</li>
	  	<li>Return Avery M’s call—interview next week?</li>
	  	<li>Ask Lorenz about key change for “Hand In Hand”</li>
	  	<li>Review “Nobody But You” solo with Evelyn</li>
	  	<li>Check on chorus costumes—what is taking so long?</li>
	  	<li>Changes to Gloria’s finale choreo.</li>
	  	<li>Wegner’s new play is good—maybe time for a change next season?</li>
	  </ul>
	<p>
	Can’t we wait until later to discuss this further?
<br/><br/>
You expect me to just continue rehearsing after what •m dl•t r•tc•H?
<br/><br/>
I know you’re upset, darling, but •d •t gn•ht thg•r •ht s’t•. 
<br/><br/>
For you, maybe. I never signed up to •b•b s’•sl• •n••m•s •s••r.
<br/><br/>
Would you rather p•tsr••d s’•d•b•m•s n• m•h •v••l?
<br/><br/>
Hector’s been doing a fine job so far.
<br/><br/>
.•t•l•b•sn•ps•r s’r•tc•H t•n s’•H 
<br/><br/>
Well, r•ht••, •n•m t•n s’•h! I’ve got enough to worry about as it is.
<br/><br/>
.•n•g s’•l••V t•ht w•n •n•l• ll• s’•h t•B 
<br/><br/>
!tl••f •m t•n s’t•hT
<br/><br/>
You have every right to be angry. tr•ts •ht m•rf ht•rt •ht ••• dl•t •v’dl••hs •. 
<br/><br/>
Yes, you should have.
<br/><br/>
Are you really going to dl•hc ss•lpl•h • n• kc•b r••• nr•t because •k•ts•m • •d•m •?


	 </p>
    </div>
    
  );
  }
};