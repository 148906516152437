import React, { Component } from 'react'

export default class Transcriptposterproof extends Component{
  render (){
    return (
    	<div className='rtfdoc'>
    <p>
	  Ira, has Vi really signed the contract on this? I thought she wasn’t even thinking about her next role after Duo.
<br/><br/>
G’s getting ahead of himself again, right?
<br/><br/>
[3x3 square grid with the letters Y and Z written into the central square]<br/><br/>
SC1 UR3 UL3 SL3 DC2 DR2 SL3 DL2<br/>
UR3 UR2 SR1 DC3 ?<br/>
UL1 DC2 DR2 SL1 DL3 DL3 UC3 DC3 DC1 DC2 UL2 ?<br/>
<br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
    </p>

    
    </div>
    
  );
  }
};