import React, { Component } from 'react'


export default class KeepSafeIcon extends Component {
    

   
    render() {

    	const icon = this.props.icon

        let imgUrl = "https://cdn.huntakiller.com/huntakiller/s9/icons/keepsafe/";
        if(icon.icon === "folder"){
            if(icon.passwordprotected === true) {
                imgUrl = imgUrl + "keepsafe-folder-icon-locked.svg";
            }else{
                imgUrl = imgUrl + "keepsafe-folder-icon.svg";
            }
        }else if(icon.icon === "text"){
            imgUrl = imgUrl + "keepsafe-text-file-icon.svg";
        }else if(icon.icon === "image"){
            imgUrl = imgUrl + "keepsafe-image-file-icon.svg";
        }else if(icon.icon === "email"){
            imgUrl = imgUrl + "keepsafe-email-icon.svg";
        }
        
        return (
           
    			<li id={`icon-${icon.id}`} onClick={() => this.props.iconSingleClick(icon.id)}>
    				<div className="keep-safe-icon-container"><img src={imgUrl}  className="" alt={icon.name} /></div>
    				<p>{icon.name} </p>
    			</li>
           
        )
    }
}
