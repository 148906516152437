
import React, { Component } from 'react';
import Div100vh from 'react-div-100vh'
import MenuContentJWESite from '../components/JWESite/MenuContentJWESite';
import CheeseburgerMenu from '../components/just4FunSite/CheeseburgerMenu';
import HomeContentJWESite from '../components/JWESite/HomeContentJWESite';
import CurrentPropertiesContentJWESite from '../components/JWESite/CurrentPropertiesContentJWESite';
import PastProjectsContentJWESite from '../components/JWESite/PastProjectsContentJWESite';
import RetailPropertiesContentJWESite from '../components/JWESite/RetailPropertiesContentJWESite';
import ReviewsContentJWESite from '../components/JWESite/ReviewsContentJWESite';

class JWEView extends Component {

	constructor(props) {
	    super(props);
	    this.currentEpisode = this.props.currentEpisode
      	this.state = {
	      currentView:"HOME",
	      menuOpen: false
	    };
	    
	}
	tapLogo() {
		this.setState({ currentView: "HOME" })
	}


	openMenu() {
		this.setState({ menuOpen: true })
	}

	closeMenu() {
		this.setState({ menuOpen: false })
	}

	tapMenu(item) {
		//console.log("tapMenu")
		
		this.closeMenu()
		//this.setState({selectedIcon:null})
		this.setState({ currentView: item })

		document.getElementById('just4FunSite-container').scrollTop = 0;
	
		//
		
	}


	render() {
		
		return (
		  
		  <Div100vh  dontresize="true" className="h-full w-full flex flex-col just4FunSite JWESite "> 
		  	<div className="header">
		  		<img className="logo" onClick={this.tapLogo.bind(this)} src="https://cdn.huntakiller.com/huntakiller/s9/photos/jwe/jwe-logo.svg" alt="logo-Just4Fun" />

		  		<img className="menu-toggle menu-toggle-icon mobile" alt="menu-toggle" onClick={this.openMenu.bind(this)} src="https://cdn.huntakiller.com/huntakiller/s8/menu-hamburger.png"/>
	        		
		  		<img className=" close-icon "  alt="close-dt" onClick={this.props.tapBack} src="https://cdn.huntakiller.com/huntakiller/s8/closebutton.svg"/>
	        	
	        	<div className="desktop"><MenuContentJWESite currentView = {this.state.currentView} tapMenu={this.tapMenu.bind(this)}/></div>
	        		
		  	</div>


	  	   <CheeseburgerMenu
		        isOpen={this.state.menuOpen}
		        closeCallback={this.closeMenu.bind(this)} tapMenu={this.tapMenu.bind(this)}>
		        <MenuContentJWESite currentView = {this.state.currentView} tapMenu={this.tapMenu.bind(this)}/>
	        </CheeseburgerMenu>

	        <div className="just4FunSite-container JWESite-container flex-1 flex" id="just4FunSite-container">
	        	
	        	{this.state.currentView === "HOME" && (
	        		<HomeContentJWESite tapMenu={this.tapMenu.bind(this)} />
	        	)}
	        	{this.state.currentView === "CURRENT PROPERTIES" && (
	        		<CurrentPropertiesContentJWESite />
	        	)}
	        	{this.state.currentView === "PAST PROJECTS" && (
	        		<PastProjectsContentJWESite />
	        	)}
	        	{this.state.currentView === "RETAIL PROPERTIES" && (
	        		<RetailPropertiesContentJWESite />
	        	)}
	        	{this.state.currentView === "REVIEWS" && (
	        		<ReviewsContentJWESite currentEpisode={this.currentEpisode}/>
	        	)}

	        	<div className="JWESite-pizza-ad-container">
	        		<img src="https://cdn.huntakiller.com/huntakiller/s9/photos/jwe/image-tonypizza.png" className="JWESite-pizza-ad" />
	        	</div>
	        </div>



		    
		    
		  </Div100vh>
		  



		)

	}
}

export default JWEView
