import React, { Component } from 'react'
import ReactAudioPlayer from 'react-audio-player';

export default class VideoWistiaViewer extends Component {

	constructor(props) {
	    super(props);
      this.state = {
          video_url: props.video_url,
        };
	    
	}

  componentWillMount () {
    const script = document.createElement("script");
 
    script.src = "https://fast.wistia.com/embed/medias/"+this.state.video_url+".jsonp"; ;
    script.async = true;
 
    document.body.appendChild(script);

    const script2 = document.createElement("script");
 
    script2.src = "https://fast.wistia.com/assets/external/E-v1.js";
    script2.async = true;
 
    document.body.appendChild(script2);
  }



    render() {
    	   
         const divStyle = {
          
          position: "relative"
        };

        const divStyle2 = {
          height: "100%",
          margin:"0 auto",
          width:"100%",
          maxWidth:"100%",

        };

        const divStyle3 = {
          height: "100%",
          width:"100%",
          position: "relative"
        };

        const divStyle4 = {
          height:"100%",
          left:"0",
          opacity:"1",
          overflow:"hidden",
          position:"absolute",
          top:"0",
          transition:"opacity 200ms",
          width:"100%"
        };

        const imgStyle = {
          filter:"blur(5px)",
          height:"100%",
          objectFit:"contain",
          width:"100%"
        };
    	

        return (
 
      
                
       
                  
                   <div className="wistia_responsive_wrapper" style={divStyle2}>
                     <div className={"wistia_embed wistia_async_"+this.state.video_url+" videoFoam=true popover=true popoverAnimateThumbnail=true"} style={divStyle3}>
                       
                     </div>
                  
                 


    


            

        	</div>)
    }




}
