import React, { Component } from 'react'



class AboutContentJust4Fun extends Component {
  

  render() {
    return (
      <>
        <div className="about-container">

	        <div className = "flex sectionAbout1">
	          <div className="about-img-just4Fun ">
	            <img src="https://cdn.huntakiller.com/huntakiller/s8/group-6.jpg" alt="about1"/>
	          </div>
	          <div className="about-txt-just4Fun">
	            <h2>JAKE</h2>
	            <div className=" divider"></div>
	            <p ><span className="font-bold">Zodiac:</span> Gemini <br/><span className="font-bold">Favorite Color:</span> Red <br/><span className="font-bold">Favorite Food:</span> Pasta <br/><span className="font-bold">Favorite Animal:</span> Panther</p>
	          </div>
	        </div> 
	        <div className = "flex sectionAbout2">
	          <div className="about-txt-just4Fun">
	            <h2>GABE</h2>
	            <div className=" divider"></div>
	            <p><span className="font-bold">Zodiac:</span> Libra <br/><span className="font-bold">Favorite Color:</span> Purple <br/><span className="font-bold">Favorite Food:</span> Ice Cream <br/><span className="font-bold">Favorite Animal:</span> Dog</p>
	          </div>

	          <div className="about-img-just4Fun ">
	            <img src="https://cdn.huntakiller.com/huntakiller/s8/group-7.jpg"  alt="about2"/>
	          </div>
	          
	        </div> 


	        <div className = "flex sectionAbout1">
	          <div className="about-img-just4Fun ">
	            <img src="https://cdn.huntakiller.com/huntakiller/s8/group-9.jpg"  alt="about3"/>
	          </div>
	          <div className="about-txt-just4Fun">
	            <h2>MARCUS</h2>
	            <div className=" divider"></div>
	            <p><span className="font-bold">Zodiac:</span> Virgo <br/><span className="font-bold">Favorite Color:</span> Blue <br/><span className="font-bold">Favorite Food:</span> Sushi <br/><span className="font-bold">Favorite Animal:</span> Snake</p>
	          </div>
	        </div> 


	        <div className = "flex sectionAbout2">
	          <div className="about-txt-just4Fun">
	            <h2>OLLIE</h2>
	            <div className=" divider"></div>
	            <p><span className="font-bold">Zodiac:</span> Pisces <br/><span className="font-bold">Favorite Color:</span> Green <br/><span className="font-bold">Favorite Food:</span> Pizza <br/><span className="font-bold">Favorite Animal:</span> Wombat</p>
	          </div>

	          <div className="about-img-just4Fun ">
	            <img src="https://cdn.huntakiller.com/huntakiller/s8/group-10.jpg"  alt="about4"/>
	          </div>
	          
	        </div> 

	    </div>
        
      </>
    )
  }
}



export default AboutContentJust4Fun
