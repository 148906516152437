import React from 'react'
import ReactDOM from 'react-dom'
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom'
import '../node_modules/tailwindcss/dist/tailwind.min.css'
import './css/index.css'
import 'react-image-lightbox/style.css'; 
import SeasonLogin from './SeasonLogin'
import SeasonsDesktop from './SeasonsDesktop'
import Cadence from './Cadence'
import Just4Fun from './Just4Fun'
import MalloryRock from './MalloryRock'
import Notfound from './Notfound'

const routing = (
	<Router>
	  <Switch>
	      <Route exact path="/" component={SeasonLogin} />
	      <Route exact path="/Desktop" component={SeasonsDesktop} />
	      <Route exact path="/cadence" component={Cadence} />
	      <Route exact path="/just4fun" component={Just4Fun} />
	      <Route exact path="/malloryrock" component={MalloryRock} />
	      <Route component={Notfound} />
	  </Switch>
  	</Router>
)

ReactDOM.render(routing, document.getElementById('root'))