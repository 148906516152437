
import React, { Component, Fragment } from 'react';
import Draggable from 'react-draggable';
import {isMobile, isIOS13} from 'react-device-detect';
import Div100vh from 'react-div-100vh'
import 'react-perfect-scrollbar/dist/css/styles.css';
import PerfectScrollbar from 'react-perfect-scrollbar'
import '../../css/scrollbar.css'

import { Container, Row, Col, Form, FormGroup, Toast, Alert, InputGroup } from 'react-bootstrap';
// HAK styles




export default class BMailEmailer extends Component {
    constructor(props) {
        super(props);
        //console.log(this.props.modal)
        this.updateDimensions = this.updateDimensions.bind(this)
        this.state = {
            subject: "",
            message: "",
            previousPosition: {
              x: 200, y: 200
            },
            let:0,
            top:0,
          };

    }


    onStop = (e, position) => {
        //this.setState({zindex:10});
        //const {x, y} = position;
        //this.setState({controlledPosition: {x, y}});
        //this.props.icon.left = x
       // this.props.icon.top = y

    };

    onStart = (e, position) => {

      //this.props.updateZindex(this.state.modal)
    };

    handleDrag = (e, position) => {
        const {x, y} = position;

        this.setState({left: x});
         this.setState({top: y});
        this.setState({previousPosition: {x, y}});

    };

    setBodyInputValue = (event) => {
    	this.setState({message: event.target.value})
    }

    setSubjectInputValue = (event) => {
    	this.setState({subject: event.target.value})
    }

    componentDidMount() {
        this.updateDimensions()
        if (window.innerWidth < 800 || (isMobile || isIOS13)) {

            this.setState({top: 0,left:0, previousPosition: this.state.controlledPosition});
        }else{
        	const width = 850
	    	const height = 525
            this.setState({top:((window.innerHeight - parseInt(height))/2)+20 ,left:((window.innerWidth - parseInt(width))/2)+20});

        }
        window.addEventListener("resize", this.updateDimensions);
    };

    componentWillUnmount(){
        window.removeEventListener('resize', this.updateDimensions);
    };

    updateDimensions() {
        //console.log("resize")
        
        if (window.innerWidth < 800 || (isMobile || isIOS13)) {
            this.setState({previousPosition: {x: this.state.left , y: this.state.top }});
            //this.setState({controlledPosition: {x:0, y:0}});
            
            this.setState({top: 0,left:0});


          

        }else{
            this.setState({controlledPosition: this.state.previousPosition});
           
            
        }

       
        

    }
    closeModal = (e) => {
        this.props.closeBmailer(e)
    };


    sendEmail = (e) => {
    	e.preventDefault();
    	this.props.sendEmail(this.state.subject + ' ' + this.state.message);
    	this.props.closeBmailer("dd")
    }
   
   
    render() {
        const dragHandlers = { onStop: this.onStop, onDrag:this.handleDrag, onStart:this.onStart};

    	
        let controlledPosition = {x: this.state.left, y: this.state.top}

        var modalStyle;

        
        if (window.innerWidth < 800 || (isMobile || isIOS13)) {
            modalStyle = {
                zIndex:10,
                width:"100%",
                height:"100%",
            }

        }else{
            modalStyle = {
                zIndex:10,
                
        	}
        }
      
        const clearStyle = {
          clear: 'both'
        };

      
        return (
            <Draggable position={controlledPosition}  {...dragHandlers}  disabled={(isMobile || isIOS13) ? true : false}>
    			<div className="modal" style= {modalStyle} >
                <Div100vh   dontresize="true" className="modalDiv" style={{maxHeight: isMobile ? '' : '100%'}}>
                    <div className="modalcontainer">
                        <div className="header">
                            <h2>BMAIL</h2>
                            <button className="close" onClick={this.closeModal}/>
                        </div>
                        
                        <Fragment>
                        
                        <Container fluid className="h-100 HAKEmailChatContainer">
                            <Form  noValidate validated={true} onSubmit={this.sendEmail} className="hak-new-email-form h-100">

                                <Row className="justify-content-between">
                                    <button type='button' className='btn text-primary p-0' onClick={this.showInbox}> </button>

                                    <button type='submit' className="text-primary btn p-0">Send</button>
                                </Row>



                              
                                <br />
                             
                                <Form.Group as={Row} controlId='from' className=" align-items-center border-bottom">
                                    <Form.Label className="mb-0">
                                        From
                                    </Form.Label>
                                    <Col className="label-data">
                                        
                                        Me
                                    	
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} controlId='to' className=" align-items-center border-bottom">
                                    <Form.Label className="mb-0">
                                        To
                                    </Form.Label>
                                    <Col className="label-data">
                                       rocksteadysupportnetwork@bmail.com
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} controlId='subject' className=" align-items-center border-bottom">
                                    <Form.Label className="mb-0">
                                        Subject
                                    </Form.Label>
                                    <Col>
                                        <Form.Control  className="border-0" type='text'  autoFocus={true} onChange={this.setSubjectInputValue}  />
                                        <Form.Control.Feedback type='invalid'>
                                            Please provide the subject.
                                        </Form.Control.Feedback>
                                    </Col>
                                </Form.Group>
                               
                                <FormGroup controlId='body' className="hak-new-email-text-area-container">

                                    <Row className='mb-3 '>
                                        <Form.Control type='textarea'  as="textarea" className='form-control mb-3 border-0' value={this.state.message}  placeholder='Compose email'  onChange={this.setBodyInputValue}   />
                                        
                                    </Row>
                                </FormGroup>
                            </Form>
                        </Container>
                       
                    </Fragment>
                       
                      
                        
        			</div>
                </Div100vh>
                </div>
            </Draggable>
        )
    }
}



