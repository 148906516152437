
import React, { Component } from 'react'

export default class Transcriptledger extends Component{
  render (){
    return (
    	<div className='rtfdoc'>
			<table>
				<thead>
				<tr>
					<th>Date</th>
					<th>Description</th>
					<th>Transaction</th>
					<th>Notes</th>
				</tr>
				</thead>
				<tbody>
				<tr>
					<td>Oct 5</td>
					<td>YCLiRH marketing - Oct</td>
					<td>-$6,407.67</td>
					<td></td>
				</tr>
				<tr>
					<td>Oct 5</td>
					<td>Union payment - Oct</td>
					<td>-$4,000</td>
					<td></td>
				</tr>
				<tr>
					<td>Oct 7</td>
					<td>Liquor sales Oct/1 - Oct/7</td>
					<td>$1,500</td>
					<td>3/4 Eviryxhirg's koirg ts be skac, noa thet ysu'ri coqink bagk.</td>
				</tr>
				<tr>
					<td>Oct 7</td>
					<td>Ticket sales Oct/1 - Oct/7</td>
					<td>$7,543.15</td>
					<td></td>
				</tr>
				<tr>
					<td>Oct 7</td>
					<td>Donations Oct/1 - Oct/7</td>
					<td>$5.23</td>
					<td></td>
				</tr>
				<tr>
					<td>Oct 7</td>
					<td>YCLiRH script printing</td>
					<td>-$1.83</td>
					<td></td>
				</tr>
				<tr>
					<td>Oct 8</td>
					<td>Halloween decorations/costume</td>
					<td>-$6.8</td>
					<td></td>
				</tr>
				<tr>
					<td>Oct 10</td>
					<td>Legal fee - Viola contract</td>
					<td>-$15</td>
					<td>4/8 Get qn tocch ajout toana</td>
				</tr>
				<tr>
					<td>Oct 10</td>
					<td>New spotlight/lamps</td>
					<td>-$104.63</td>
					<td></td>
				</tr>
				<tr>
					<td>Oct 11</td>
					<td>Food</td>
					<td>-$26.16</td>
					<td></td>
				</tr>
				<tr>
					<td>Oct 11</td>
					<td>Stock liquor</td>
					<td>-$4,000</td>
					<td></td>
				</tr>
				<tr>
					<td>Oct 12</td>
					<td>1/6 Molzy</td>
					<td>-$30.02</td>
					<td>3/6 Carl bgnk gboat murtmagk</td>
				</tr>
				<tr>
					<td>Oct 12</td>
					<td>Duo royalties</td>
					<td>-$7,693.43</td>
					<td></td>
				</tr>
				<tr>
					<td>Oct 12</td>
					<td>Payment for finalized posters 24x36</td>
					<td>-$10.46</td>
					<td></td>
				</tr>
				<tr>
					<td>Oct 14</td>
					<td>Liquor sales Oct/8 - Oct/14</td>
					<td>$1,245.52</td>
					<td>Music from plays on records? Sell them at the door? Ask Ira</td>
				</tr>
				<tr>
					<td>Oct 14</td>
					<td>Ticket sales Oct/8 - Oct/14</td>
					<td>$6,798.45</td>
					<td></td>
				</tr>
				<tr>
					<td>Oct 14</td>
					<td>Donations Oct/8 - Oct/14</td>
					<td>$4</td>
					<td></td>
				</tr>
				<tr>
					<td>Oct 19</td>
					<td>1/6 Rugt 30% Otzkxkyz</td>
					<td>$1,500</td>
					<td></td>
				</tr>
				<tr>
					<td>Oct 21</td>
					<td>Ticket sales Oct/15 - Oct/21</td>
					<td>$6,398.78</td>
					<td></td>
				</tr>
				<tr>
					<td>Oct 21</td>
					<td>Liquor sales Oct/15 - Oct/21</td>
					<td>$1,432.78</td>
					<td></td>
				</tr>
				<tr>
					<td>Oct 21</td>
					<td>Donations Oct/15 - Oct/21</td>
					<td>$4.15</td>
					<td></td>
				</tr>
				<tr>
					<td>Oct 21</td>
					<td>YCLiRH set materials/construction</td>
					<td>-$4,500</td>
					<td></td>
				</tr>
				<tr>
					<td>Oct 22</td>
					<td>Food</td>
					<td>-$30.42</td>
					<td></td>
				</tr>
				<tr>
					<td>Oct 23</td>
					<td>Telephone - September</td>
					<td>-$1.6</td>
					<td></td>
				</tr>
				<tr>
					<td>Oct 23</td>
					<td>Telephone - October</td>
					<td>-$1.6</td>
					<td></td>
				</tr>
				<tr>
					<td>Oct 26</td>
					<td>Stock liquor</td>
					<td>-$5,492.89</td>
					<td></td>
				</tr>
				<tr>
					<td>Oct 27</td>
					<td>Donations Oct/22 - Oct/27</td>
					<td>$4.03</td>
					<td></td>
				</tr>
				<tr>
					<td>Oct 27</td>
					<td>Liquor sales Oct/22 - Oct/27</td>
					<td>$3,392.41</td>
					<td></td>
				</tr>
				<tr>
					<td>Oct 27</td>
					<td>Ticket sales Oct/22 - Oct/27</td>
					<td>$7,089.03</td>
					<td></td>
				</tr>
				<tr>
					<td>Oct 29</td>
					<td>Gas and electric</td>
					<td>-$45</td>
					<td></td>
				</tr>
				<tr>
					<td>Oct 29</td>
					<td>Water</td>
					<td>-$3.66</td>
					<td></td>
				</tr>
				<tr>
					<td>Oct 30</td>
					<td>Mortgage</td>
					<td>-$70</td>
					<td></td>
				</tr>
				<tr>
					<td>Oct 31</td>
					<td>Duo wrap party/Halloween</td>
					<td>-$105.45</td>
					<td></td>
				</tr>
				<tr>
					<td>Oct 31</td>
					<td>Food</td>
					<td>-$34.78</td>
					<td></td>
				</tr>
				<tr>
					<td>Nov 1</td>
					<td>1/9 Uxjw 30% rwcnanbc</td>
					<td>$3,000</td>
					<td>Send Cole another "thank you" (Even though AG wasn't that good)</td>
				</tr>
				<tr>
					<td>Nov 1</td>
					<td>YCLiRH marketing - Nov</td>
					<td>-$6,000</td>
					<td></td>
				</tr>
				<tr>
					<td>Nov 1</td>
					<td>Thanksgiving decorations</td>
					<td>-$5.75</td>
					<td></td>
				</tr>
				<tr>
					<td>Nov 2</td>
					<td>Tickets - Anything Goes (preview)</td>
					<td>------</td>
					<td></td>
				</tr>
				<tr>
					<td>Nov 2</td>
					<td>Food</td>
					<td>-$24.56</td>
					<td></td>
				</tr>
				<tr>
					<td>Nov 4</td>
					<td>Ticket sales Oct/29 - Nov/4</td>
					<td>$5,231.45</td>
					<td></td>
				</tr>
				<tr>
					<td>Nov 4</td>
					<td>Liquor sales Oct/29 - Nov/4</td>
					<td>$3,467.92</td>
					<td></td>
				</tr>
				<tr>
					<td>Nov 4</td>
					<td>Donations Oct/29 - Nov/4</td>
					<td>$3</td>
					<td></td>
				</tr>
				<tr>
					<td>Nov 4</td>
					<td>YCLiRH costumes</td>
					<td>-$523.15</td>
					<td></td>
				</tr>
				<tr>
					<td>Nov 4</td>
					<td>YCLiRH props</td>
					<td>-$500</td>
					<td>Christmas season keeping everyone away?</td>
				</tr>
				<tr>
					<td>Nov 4</td>
					<td>YCLiRH set construction</td>
					<td>-$1,000</td>
					<td></td>
				</tr>
				<tr>
					<td>Nov 4</td>
					<td>1/6 Molzy</td>
					<td>-$50</td>
					<td></td>
				</tr>
				<tr>
					<td>Nov 5</td>
					<td>Gas and electric</td>
					<td>-$40</td>
					<td></td>
				</tr>
				<tr>
					<td>Nov 6</td>
					<td>Union payment - Nov</td>
					<td>-$4,000</td>
					<td></td>
				</tr>
				<tr>
					<td>Nov 9</td>
					<td>Loading dock door repair</td>
					<td>-$10.46</td>
					<td>PI fees - $4.15/hour - Cover for Olga?</td>
				</tr>
				<tr>
					<td>Nov 11</td>
					<td>Water</td>
					<td>-$3.24</td>
					<td></td>
				</tr>
				<tr>
					<td>Nov 11</td>
					<td>Ticket sales Nov/5 - Nov/11</td>
					<td>$5,260.43</td>
					<td></td>
				</tr>
				<tr>
					<td>Nov 11</td>
					<td>Liquor sales Nov/5 - Nov/11</td>
					<td>$2,463.12</td>
					<td></td>
				</tr>
				<tr>
					<td>Nov 11</td>
					<td>Donations Nov/5 - Nov/11</td>
					<td>$2.05</td>
					<td></td>
				</tr>
				<tr>
					<td>Nov 12</td>
					<td>Replacement instruments for YCLiRH</td>
					<td>-$78.47</td>
					<td></td>
				</tr>
				<tr>
					<td>Nov 12</td>
					<td>Food</td>
					<td>-$40</td>
					<td></td>
				</tr>
				<tr>
					<td>Nov 13</td>
					<td>Telephone</td>
					<td>-$1.7</td>
					<td></td>
				</tr>
				<tr>
					<td>Nov 15</td>
					<td>Duo royalties</td>
					<td>-$8,746.75</td>
					<td>2/5 Bzyjrx? Iwa?</td>
				</tr>
				<tr>
					<td>Nov 16</td>
					<td>1/8 Wtoi</td>
					<td>-$20</td>
					<td></td>
				</tr>
				<tr>
					<td>Nov 18</td>
					<td>Donations Nov/12 - Nov/18</td>
					<td>$2.1</td>
					<td></td>
				</tr>
				<tr>
					<td>Nov 18</td>
					<td>Liquor sales Nov/12 - Nov/18</td>
					<td>$1,645.24</td>
					<td></td>
				</tr>
				<tr>
					<td>Nov 18</td>
					<td>Ticket sales Nov/12 - Nov/18</td>
					<td>$3,978.56</td>
					<td></td>
				</tr>
				<tr>
					<td>Nov 22</td>
					<td>Christmas decorations</td>
					<td>-$13</td>
					<td></td>
				</tr>
				<tr>
					<td>Nov 23</td>
					<td>Food</td>
					<td>-$53.68</td>
					<td></td>
				</tr>
				<tr>
					<td>Nov 25</td>
					<td>Donations Nov/19 - Nov/25</td>
					<td>$2</td>
					<td></td>
				</tr>
				<tr>
					<td>Nov 25</td>
					<td>Liquor sales Nov/19 - Nov/25</td>
					<td>$1,509.51</td>
					<td></td>
				</tr>
				<tr>
					<td>Nov 25</td>
					<td>Ticket sales Nov/19 - Nov/25</td>
					<td>$3,101.31</td>
					<td></td>
				</tr>
				<tr>
					<td>Nov 28</td>
					<td>Cadence Thanksgiving dinner</td>
					<td>-$4.19</td>
					<td></td>
				</tr>
				<tr>
					<td>Nov 30</td>
					<td>YCLiRH cast & crew payments</td>
					<td>-$13,984.73</td>
					<td></td>
				</tr>
				</tbody>
			</table>
    
    </div>
    
  );
  }
};