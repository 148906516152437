import emailsDatas from '../datas/inbox/emails.json'


export function getCurrentSubject(currentSeason, currentEpisode) {
	var i;
	//var isCorrect = false;
	var subject = ""
	
	for (i = 0; i < emailsDatas.emails.length; i++) {
		if((parseInt(emailsDatas.emails[i].season) === parseInt(currentSeason)) &&  (emailsDatas.emails[i].episode === currentEpisode)){
			subject = emailsDatas.emails[i].subject

		}


	}

	return subject
}


export function nextStep(currentSeason, currentEpisode) {
	//console.log("isStep")
	var i;
	var nextStep = false
	for (i = 0; i < emailsDatas.emails.length-1; i++) {
		if((parseInt(emailsDatas.emails[i].season) === parseInt(currentSeason)) &&  (emailsDatas.emails[i].episode === currentEpisode)){

			let nextEpisode = emailsDatas.emails[i+1].episode
			let abs = Math.abs(parseInt(currentEpisode) - nextEpisode)
		
			if(abs < 1) {
				nextStep = nextEpisode
			}

		}

	}

	return nextStep

}