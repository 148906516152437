//Is anybody selling old merch?

import React, { Component } from 'react'



class Thread11 extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      extended: false
    };
  }



  toggleExpand = () =>{
    this.setState({ extended: !this.state.extended });
  }


  render() {

    return (
      <div className="thread-content">
        <div className="thread-header" onClick={this.toggleExpand}>
          <p className="thread-username"> Posted by <span className="font-bold">SuperLavender: </span></p>
          <h3 className={ !this.state.extended ? "thread-title-margin" : "" }>Is anybody selling old merch?</h3>

        </div>


        {
            this.state.extended &&
            <>
              <div className="thread-header" onClick={this.toggleExpand} >
                  <p>I’d kill any one of you people to get my hands on that one shirt from their early tour days with the holographic detailing... you know the one.</p>
              </div>
              <div className="thread-reply-container">
                <div className="thread-reply">
                  <div className="inner-thread-reply  border-left">
                    <p className="font-bold reply-username">meadowgoth:</p>
                    <p className="reply-content">Same!!! Commenting on this thread in the hope that it gets more attention</p>
                  </div>
                  <p className="reply-btn font-bold">... reply</p>

                </div>

                 <div className="thread-reply">
                    <div className="inner-thread-reply  border-left">
                      <p className="font-bold reply-username">rydogrydog:</p>
                      <p className="reply-content">Let me know if you figure this out, I’ve been on the lookout for a loooong time but no luck so far.</p>
                    </div>
                    <p className="reply-btn font-bold">... reply</p>
                 </div>

                 <div className="thread-reply">
                    <div className="inner-thread-reply  border-left">
                      <p className="font-bold reply-username">just_funfunfunfun:</p>
                      <p className="reply-content">You just need to be really consistent about checking and re-checking online sellers. You never know what’s gonna turn up and when.</p>
                    </div>
                    <p className="reply-btn font-bold">... reply</p>
                 </div>
              </div>
               <div className="thread-comment">
                  <div className="divider"></div>

                  <input placeholder="Sign In to Comment" disabled={true}/>
               </div>
               <div className="divider"></div>

            </>
        }



      </div>
    )
  }
}



export default Thread11
