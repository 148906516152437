import React, { Component } from 'react'


import Thread1 from './forum/Thread1';
import Thread2 from './forum/Thread2';
import Thread3 from './forum/Thread3';
import Thread4 from './forum/Thread4';
import Thread5 from './forum/Thread5';
import Thread6 from './forum/Thread6';
import Thread7 from './forum/Thread7';
import Thread8 from './forum/Thread8';
import Thread9 from './forum/Thread9';
import Thread10 from './forum/Thread10';
import Thread11 from './forum/Thread11';
import Thread12 from './forum/Thread12';
import Thread13 from './forum/Thread13';


class ForumContentJust4Fun extends Component {


  constructor(props) {
        super(props);
        
    this.currentEpisode = this.props.currentEpisode

  }



  render() {
    return (
      <> 
      	<div className="forum-container">
      		<div className="newest-thread-section">
	        	<h2>Newest Threads</h2>
	        	<div className="forum-thread-container">

	        		{this.currentEpisode === 1 && (
	        			<Thread1 />
	        		)}
	        		{this.currentEpisode === 2 && (
	        			<>
		        			<Thread6 />
		        			<Thread7 />
		        		</>
	        		)}
	        		{this.currentEpisode === 3 && (
	        			<>
		        			<Thread8 />
		        			<Thread9 />
		        		</>
	        		)}
	        		{this.currentEpisode === 4 && (
	        			<>
		        			<Thread10 />
		        		</>
	        		)}
	        		{this.currentEpisode === 5 && (
	        			<>
		        			<Thread11 />
		        			<Thread12 />
		        		</>
	        		)}
	        		{this.currentEpisode === 6 && (
	        			<>
		        			<Thread13 />
		        		</>
	        		)}

	        		
	        	</div>
	        </div>
	        
	        <div className="popular-thread-section">
	        	<h2>Popular Threads</h2>

	        	<div className="forum-thread-container">
	        		{this.currentEpisode > 5 && (
	        			<>
		        			<Thread11 />
		        			<Thread12 />
		        		</>
	        		)}
	        		{this.currentEpisode > 4 && (
	        			<>
		        			<Thread10 />
		        		</>
	        		)}
	        		{this.currentEpisode > 3 && (
	        			<>
		        			<Thread8 />
		        			<Thread9 />
		        		</>
	        		)}
	        		{this.currentEpisode > 2 && (
	        			<>
		        			<Thread6 />
		        			<Thread7 />
		        		</>
	        		)}
	        		{this.currentEpisode > 1 && (
	        			<Thread1 />
	        		)}
	        		<Thread2 />
	        		<Thread3 />
	        		<Thread4 />
	        		<Thread5 />
	        	</div>
	        </div>

      	</div>
      	
      </>
    )
  }
}



export default ForumContentJust4Fun
