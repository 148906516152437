import React, { Component } from 'react';
import Div100vh from 'react-div-100vh'
import MenuContentMRSite from '../components/MRSite/MenuContentMRSite';
import CheeseburgerMenu from '../components/just4FunSite/CheeseburgerMenu';
import HomeContentMRSite from '../components/MRSite/HomeContentMRSite';
import TownConcilContentMRSite from '../components/MRSite/TownConcilContentMRSite';
import LocalBusinessSpotlightContentMRSite from '../components/MRSite/LocalBusinessSpotlightContentMRSite';
import SafetyContentMRSite from '../components/MRSite/SafetyContentMRSite';
import WhatsNewContentMRSite from '../components/MRSite/WhatsNewContentMRSite';
import Modal from '../components/Modal'

class MRView extends Component {

	constructor(props) {
	    super(props);
	    this.currentEpisode = this.props.currentEpisode
      	this.state = {
	      currentView:"Home",
	      menuOpen: false,
	      showMap:false,
	      showScienceFairMap:false
	    };
	    
	}
	tapLogo() {
		this.setState({ currentView: "Home" })
	}


	openMenu() {
		this.setState({ menuOpen: true })
	}

	closeMenu() {
		this.setState({ menuOpen: false })
	}

	tapMenu(item) {
		//console.log("tapMenu")
		
		this.closeMenu()
		//this.setState({selectedIcon:null})
		this.setState({ currentView: item })

		document.getElementById('just4FunSite-container').scrollTop = 0;
	
		//
		
	}


	tapMap() {
		//alert("display map")
		this.setState({ showMap: true })
	}

	tapScienceFairMap() {
		this.setState({ showScienceFairMap: true })

	}

	closeModal = dataModal => {
		console.log(dataModal)
		//this.setState({ showMap: false, showScienceFairMap: false })
		if(dataModal.id === 1) {
			this.setState({ showMap: false })
		}else {
			this.setState({ showScienceFairMap: false })
		}	
	
	}

	handleUpdateZindex = dataModal => {
	    return


   }


	render() {


		var modals = [];
		if(this.state.showMap){
			let width = 850
	        let height = 525
	        
	        let left = (window.innerWidth - parseInt(width))/2
	        let top = (window.innerHeight - parseInt(height))/2
			let modal =  {"z":10,"type":"image", name:"Rip Current Advisory Map", icons:[], id:1,image_url:"s9/photos/town/general-safety-map.jpg", width:null, height:null, left:left, top:top};
			modals.push(modal)
		}

		if(this.state.showScienceFairMap){
			let width = 850
	        let height = 525
	        
	        let left = (window.innerWidth - parseInt(width))/2
	        let top = (window.innerHeight - parseInt(height))/2
			let modal =  {"z":10,"type":"image", name:"Tracking Currents on Mallory Rock through Messages in Bottles", icons:[], id:2,image_url:"s9/photos/town/mr-science-fair-map.jpg", width:null, height:null, left:left, top:top};
			modals.push(modal)
		}


		const modalviews = modals.map((modal, index) => {
        
	        return (
	        	<Modal modal={modal} key={modal.id} updateZindex={this.handleUpdateZindex.bind(this)}  closeModal={this.closeModal.bind(this)} /> 
	        )
	        
	      })

		
		return (
		  
		  <Div100vh  dontresize="true" className="h-full w-full flex flex-col  mrSite"> 
		  	
			  	<div className="header">
			  		<img src="https://cdn.huntakiller.com/huntakiller/s9/photos/town/mr-header.jpg" className="hero-header"/>
			  		<div className="ribbon hero-ribbon"></div>
			  		<img className="menu-toggle menu-toggle-icon mobile" alt="menu-toggle" onClick={this.openMenu.bind(this)} src="https://cdn.huntakiller.com/huntakiller/s8/menu-hamburger.png"/>
		        	<img src="https://cdn.huntakiller.com/huntakiller/s9/photos/town/mallory-rock-logo.svg" className="logo mobile"  onClick={this.tapLogo.bind(this)} />
			  		<img className=" close-icon "  alt="close-dt" onClick={this.props.tapBack} src="https://cdn.huntakiller.com/huntakiller/s8/closebutton.svg"/>
		        	
		        	<div className="desktop menu-mrsite-container"><img  onClick={this.tapLogo.bind(this)}  src="https://cdn.huntakiller.com/huntakiller/s9/photos/town/mallory-rock-logo.svg" className="logo" width="232"/><MenuContentMRSite currentView = {this.state.currentView} tapMenu={this.tapMenu.bind(this)}/></div>
		        		
			  	</div>


		  	   <CheeseburgerMenu
			        isOpen={this.state.menuOpen}
			        closeCallback={this.closeMenu.bind(this)} tapMenu={this.tapMenu.bind(this)}>
			        <MenuContentMRSite currentView = {this.state.currentView} tapMenu={this.tapMenu.bind(this)}/>
		        </CheeseburgerMenu>

		        <div className="mrSite-container " id="just4FunSite-container">
		        	
		        	{this.state.currentView === "Home" && (
		        		<HomeContentMRSite />
		        	)}
		        	{this.state.currentView === "Town Council" && (
		        		<TownConcilContentMRSite />
		        	)}
		        	{this.state.currentView === "Local Business Spotlight" && (
		        		<LocalBusinessSpotlightContentMRSite />
		        	)}
		        	{this.state.currentView === "Safety" && (
		        		<SafetyContentMRSite tapMap={this.tapMap.bind(this)}/>
		        	)}
		        	{this.state.currentView === "What's New" && (
		        		<WhatsNewContentMRSite tapMap={this.tapScienceFairMap.bind(this)}/>
		        	)}

		        	
		        	
		        </div>


		        {this.state.currentView === "Safety" && (
	        		<div className="mrSite-safety-ribbon-section">
		        		<div className="ribbon"> &nbsp;</div>

				        <p className="text-center"><br/><b>SPECIAL NOTE: Rip Current Advisory<br/><br/>Please note that the following areas are extremely likely to develop rip current conditions. Stay safe, islanders!</b><br/><br/></p>

				        <div className="ribbon"> &nbsp;</div>
			        </div>
	        	)}
			    
			    <div className="footer"  onClick={this.tapLogo.bind(this)} >

	        		<img src="https://cdn.huntakiller.com/huntakiller/s9/photos/town/mr-footer.png" className="hero-footer"/>
	        	</div>
		    

	        	{modalviews}
            
		  </Div100vh>
		  



		)

	}
}

export default MRView
