import React, { Component } from 'react'
import store from 'store'
import DesktopView from './views/DesktopView'
//import { users } from './datas/users.json';
import { icons } from './datas/season9/icons.json';
import {getUser} from './utils.js';


const users = JSON.parse(process.env.REACT_APP_EPISODE_PW);

class MalloryRock extends Component {

	constructor(props) {
	    super(props);

      	this.currentEpisode = 1;
	    const password = store.get("password");

	    if (password == null || password === false){
	    	console.log("notloggedin")
	    	props.history.push('/')
	    }else{
	    	
	    	this.user = getUser(password, users);
	    	//console.log(user)
	    	if (this.user != null && this.user.seasonurl === "malloryrock"){
				this.currentEpisode = this.user.episode
			}else{
				props.history.push('/')
			}
	    }
	    
	}


	render() {
		
		const currentEpisode = this.currentEpisode
		//console.log(currentEpisode)

		return (
		  
		  <DesktopView user={this.user || {}} className="malloryrock" icons={icons}  seasonurl="malloryrock" currentEpisode={currentEpisode} history={this.props.history}  />
		  



		)

	}
}

export default MalloryRock