import React, { Component } from 'react'
import ForgotPasswordJust4Fun from '../just4FunSite/ForgotPasswordJust4Fun';
import UserProfileJust4Fun from  '../just4FunSite/UserProfileJust4Fun';


class AccountContentJust4Fun extends Component {
	constructor(props) {
	    super(props);

	  	this.state = {
	  	  password: "",
	      username: "",
	      isForgotPasswordOpen:false,
	      error:false,
	      isLoggedIn:false,
	    };
	    
	}

	validateForm() {
    	//return this.state.password.length > 0;
    	return true
  	}


  	handleChange = event => {
  		this.setState({ error: false });
	    this.setState({
	      [event.target.id]: event.target.value
	    });
	 }

	handleKeyPress = (event) => {
	  if(event.key === 'Enter'){
	    //console.log('enter press here! ')
	    document.activeElement.blur();
	    event.preventDefault();
	  }
	}


	handleSubmit = event => {
		//console.log("submit")
	    event.preventDefault();
	    document.getElementById("username").blur();
	    document.getElementById("password").blur();

	    this.setState({ error: true });
	    
		
	}


	goToProfile = e => {
		 e.preventDefault()
         this.setState({  error:false, isLoggedIn: true, isForgotPasswordOpen:false })

     }



	forgotPassword = event => {
		event.preventDefault();
		this.setState({ isForgotPasswordOpen: true });
	}

	render() {

		if(!this.state.isForgotPasswordOpen && !this.state.isLoggedIn) {
			return (
				<div>
			  <div className="flex-1 flex signinJust4Fun">
			    <div className="login-box flex flex-col justify-center items-center p-4 pt-10 pb-8">
					<form className="  " onSubmit={this.handleSubmit}>
			    		<div className="">
			    			<p className="annoucement  mb-12"> We are not accepting new<br/>accounts at this time.</p>
			    			<div className=" mb-3 mx-8">
			    				<input className="shadow appearance-none border  rounded  w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"  type="text" autoFocus id="username" placeholder="Username"  onKeyPress={this.handleKeyPress} value={this.state.username}
	              onChange={this.handleChange} />
			  				</div>
			  				<div className=" mb-3 mx-8">
			        			<input className="shadow appearance-none border  rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" value={this.state.password}
			      onChange={this.handleChange} type="password" id="password" placeholder="Password" onKeyPress={this.handleKeyPress}/>
			  				</div>


			  				<p className="error mt-8 mb-2">{this.state.error && "Incorrect Username/Password"} &nbsp;</p>


			    		</div>
			    		<div className="flex items-center justify-between">
					      <button className="login-btn text-white px-12 rounded focus:outline-none focus:shadow-outline" id="just4FunSiteLogin" type="submit"  disabled={!this.validateForm()}>
					        Sign In
					      </button>
					    
					    </div>
					     <a className="forgotPassword mt-2" href="/" onClick={this.forgotPassword}>Forgot Your Password?</a>
			    	</form>

			    </div>
			  </div>
			  </div>
			)
		}else if(this.state.isForgotPasswordOpen) {
			return (
				<ForgotPasswordJust4Fun handleGoToRPofile={this.goToProfile.bind(this)} />
				
			)
		}else if(this.state.isLoggedIn) {
			return (
				<UserProfileJust4Fun />
				
			)
		}



		
	}
}



export default AccountContentJust4Fun
