import React, { Component } from 'react'

export default class Transcriptcodedletter3 extends Component{
  render (){
    return (
    	<div className='rtfdoc'>
    <p>
November 6, 1934 <br/>
Fiktyv, 
<br/><br/>
If you find this letter confusing, remember Mother’s ring. She once said a wedding ring’s true meaning was the sum of the year you’ve spent with your betrothed. Hearing her say that shifted my thinking about love. I wish the past year of my life had been as happy as her first year with my late father. Instead, it’s been a nightmare. Today’s events may have finally broken me. 
<br/><br/>
As if being attacked by Aytfiv for failing to call her when I arrived at the theater weren’t bad enough, I was castigated by Iriboz just now for not tibbezg fiv mlyst ao kfebj. She doesn’t understand how hard it is to find the right words when anything you say will hurt someone you care about. For her sake, I hope she never has to find out. 
<br/><br/>
I thought I could stay to hezeuf yst ao kyztvmkt, but today’s events have made it clear it’s time for me to leave. I can’t keep making excuses when Aytfiv fsvtu ai or watching tfi hmtfiv yh ao kfebj make eyes at one of my best friends. I miss Dsbemz so much it aches. I belong with him, and I can’t keep pretending he doesn’t exist for Aytfiv'u sake. 
<br/><br/>
I need you to help me kybbikt ao diqibvo hvya etu fejezg xbmki ez tfi hmbui lyttya yh tfi bewsyv kmlezit mt tfi Gvmki Zyti tomorrow night. I uqexij tfi kmlezit'u cio hvya Giyvgi'u yhheki a few days ago. After that, I’ll go to New Canaan and pick up Dsbemz. I’ll contact you once I’ve set myself up somewhere Aytfiv will never hezj. 
<br/><br/>
Don’t try to talk me out of this again, all right? We’ll be fine. The diqibvo ez tfmt bykclyp eu qyvtf ayvi tfmz izysgf ty ciix ai mzj Dsbemz kyahyvtmlbi. For the first time in my life, I’m going to be free. Not only that, but I can ensure that ao kfebj will enjoy the freedom I never had growing up. 
<br/><br/>
I’m nervous about what Giyvgi is going to say when I tell him tonight, since I know he’s worked hard to center the publicity of “You Can Leave It Right Here” around me, but I think I can get him to understand. He’s always had a bit of a sweet spot for me. 
<br/><br/>
Talk to you later,<br/>  Reybm


    </p>

    
    </div>
    
  );
  }
};