import React from "react";
export default class CheeseburgerMenu extends React.Component {



  render() {

  	if(!this.props.isOpen){
          return null;
      }


    return <div className="cheeseburgerMenu h-full w-full flex flex-col justify-center items-center">
    	  <div className="cheeseburgerMenu-inner">
  	    	

  	    	<img className=" close-icon "  alt="close-dt" onClick={this.props.closeCallback} src="https://cdn.huntakiller.com/huntakiller/s8/closebutton.svg"/>
  		        	
  		        	
  	    	{this.props.children}
  	   	</div>

    </div>;
  }
}