import React, { Component } from 'react'


class NotFound extends Component {

	


	render() {

		return <div>NOT FOUND</div>
	}
}
export default NotFound