import React, { Component } from 'react'

export default class Cipherguide extends Component{
  render (){
    return (
    <div className='rtfdoc'>
      <p>Hi, Julia.<br/><br/>
Before I hand this case off entirely, I want to leave you with a small parting gift. <br/><br/>

When you’re looking into the private lives of people with things to hide, you’ll almost certainly come across a few codes and ciphers. I’ve created a simple guide to a few of the most common cipher types. <br/><br/>

If you find a piece of evidence that seems to be using a permutation of one of these codes, you can send it on ahead to my protégé. They’re more than capable of cracking whatever cryptogram comes their way. 
</p>
<h2>Abjad:</h2>
<p>
This is less a form of cipher and more a system of writing, but I’ve seen it used to encode information plenty of times in the past. In simple terms, it’s the omission of vowels from the text. Sometimes the writer will replace the vowels with a neutral symbol.
<br/><br/>
If you come across a word written in abjad that could be read several different ways depending on the missing vowels, try looking at the big picture. Context clues can be a lifesaver when you’re trying to decipher messages written in this format. 
<br/><br/>
Written in abjad, the word example would look like this: </p>
<ul>
<li>xmpl</li>
<li>-x-mpl-</li>
</ul>
<h2>Atbash Cipher:</h2>
<p>In an Atbash cipher, the alphabet is mapped onto itself backwards. 
<br/><br/>
Here’s a chart that shows what I mean:</p>
<img src="https://cdn.huntakiller.com/huntakiller/s7/cipherguide-atbashtable1.svg" alt="cipherguide-atbashtable1"/>
<img src="https://cdn.huntakiller.com/huntakiller/s7/cipherguide-atbashtable2.svg"  alt="cipherguide-atbashtable2"/>
 <p>In an Atbash cipher, the word example would be: vcznkov </p>
<h2>Book Cipher:</h2>
<p>
A book cipher needs to be used in conjunction with a pretty large section of text. This text might be found on a separate piece of paper from the code itself <b>or</b> on the same page. 
<br/><br/>
The cipher tells the reader what words or letters <b>within</b> a passage to look at, and in what order, to find a hidden message. 
<br/><br/>
The text can be anything from a letter to the page of a book to a shopping list, so be vigilant! 
<br/><br/>
To decode a book cipher, be on the lookout for anything that might indicate on what line of a page, what word in a line, or what letter in a word you should focus.
<br/><br/>
If I wanted to call your attention to the word example in the following text, I could do it by providing you with the location of the word itself with this key: (2/7).

</p>
<ul>
<li>He was exacting in his standards and minimal in his praise.</li>
<li>He always strove to lead by example. </li>
</ul>
<p>The key (2/7) indicates the location of the hidden word. The word example is on the second line. It is the seventh word of that line. 
<br/><br/>
I could scatter the information even further by providing you with something like this, which gives you the necessary information in the format of <b>line/word/letter</b>:
<br/><br/>
1/1/2 1/3/2 1/6/3 1/8/1 1/11/1 2/5/1 2/7/7
</p>
<ul>
<li>H<b>e</b> was e<b>x</b>acting in his st<b>a</b>ndards and <b>m</b>inimal in his <b>p</b>raise.</li>
<li>He always strove to <b>l</b>ead by exampl<b>e</b>.</li>
</ul>
<p>When read in order, the letters indicated by the key spell out the word example.</p>
<h2>Keypad Cipher:</h2>
<p>The basic idea of this cipher is to assign all of the letters of the alphabet to specific places on a grid like this one: </p>
<img src="https://cdn.huntakiller.com/huntakiller/s7/cipherguide-keypadtable.svg"  alt="cipherguide-keypadtable"/>
<p>You might notice that it looks like the keypad of a telephone. While this example organizes the alphabet onto a 3x3 grid reminiscent of a phone, the alphabet can be spread across grids of any organization: 5x2, 3x6, and so on.
<br/><br/>
Once the writer has assigned letters to places on the grid, there must be some kind of shorthand to refer to the physical position of each letter on the grid. 
<br/><br/>
Keep in mind that these grids don’t necessarily have to start with A in the upper right hand corner. You may need to determine how the alphabet is supposed to fall on the grid before you can start decoding. 
<br/><br/>
To decode a keypad cipher, look for notes that might indicate the position of a specific letter. 
<br/><br/>
For example, since A is the first letter in the box that would be mapped onto the number 1 on a typical keypad (the model for my grid above), one way to denote its position would be 1-1. By the same logic, E would be 2-2, since it is the second letter in the second box.
<br/><br/>
The word example spelled out this way is therefore: 2-2 8-3 1-1 5-1 6-1 4-3 2-2.</p>
<h2>Reverse Acronym: </h2>
<p>This one is a great way to hide short communications in plain sight. It uses one of the following to spell out a hidden message:</p>
<ul>
<li>all of the capital letters in a sentence</li>
<li>all of the first letters in a line</li>
<li>all of the first letters of each sentence</li>
</ul>
<p>
See if you can find the word example in the following text:
<br/><br/>
Everyone in the hospital knows the unlucky patient. X-ray technicians, nurses, doctors, and custodial staff see her all the time. All the infamy comes from the way her terrible luck plays out. Most people get injured every now and then, but the unlucky patient’s clumsiness is on another level. Pain and suffering seem to follow wherever she goes. Layers of bandages wind up and down her limbs. Everything bad that could possibly happen to someone has happened to her. 
<br/><br/>
See what I did there? Let me bold the letters that make up the word <i>example</i>: 
<br/><br/>
<b>E</b>veryone in the hospital knows the unlucky patient. <b>X</b>-ray technicians, nurses, doctors, and custodial staff see her all the time. <b>A</b>ll the infamy comes from the way her terrible luck plays out. <b>M</b>ost people get injured every now and then, but the unlucky patient’s clumsiness is on another level. <b>P</b>ain and suffering seem to follow wherever she goes. <b>L</b>ayers of bandages wind up and down her limbs. <b>E</b>verything bad that could possibly happen to someone has happened to her. 
<br/><br/>
It could also be done like this, using capitalized proper nouns: 
<br/><br/>
<b>E</b>dward <b>X</b>avier didn’t want an <b>A</b>pple computer, but <b>M</b>ary was planning on getting him one anyway. <b>P</b>leasing him was impossible, after all. <b>L</b>ucy and <b>E</b>leanor appreciated her efforts, but not he. 
</p>
<h2>Shift Cipher:</h2>
<p>This cipher is sometimes called a Caesar Shift, as it is thought that Julius Caesar used it to encrypt his personal communications. To encode a sentence using a shift cipher, the writer shifts each letter of the alphabet forward by a certain fixed number. 
<br/><br/>
For example, the top row of the following chart is the regular, un-encoded alphabet. The bottom row is the alphabet shifted forward by five letters. </p>
<img src="https://cdn.huntakiller.com/huntakiller/s7/cipherguide-shifttable1.svg" alt="cipherguide-shifttable1"/>
<img src="https://cdn.huntakiller.com/huntakiller/s7/cipherguide-shifttable2.svg" alt="cipherguide-shifttable2"/>
<p>The word <i>example</i> shifted forward by five letters is jcfruqj. 
<br/><br/>
To decode jcfruqj, you must shift each individual letter backward by five places in the alphabet. 
<br/><br/>
The alphabet can be shifted by any number, although shifting by numbers greater than 26 can get a bit redundant (since, of course, the English alphabet only has 26 letters). In other words, shifting by 27 would be the same as shifting by one, shifting by 28 would be the same as shifting by two, and so on. 
<br/><br/>
Remember: there are plenty of ways to add complexity to a shift cipher. Because of this, not all shift ciphers will appear to be straightforward. Here are a few examples of potential complications you might encounter when decoding shift ciphers:
 </p>
 <ul>
<li>The shift is only used on parts of the message. Perhaps every second word is shifted, while the other words in a sentence are left alone. This can also be done by letter. For example, every third letter might be shifted, while the rest of the message is left alone.</li>
<li>More than one shift is used within the same message. For example, one sentence in a message could be shifted by 12, while the next sentence is shifted by seven. On a more difficult level, this could even occur within the words themselves. Perhaps every even letter is shifted by two, while every odd letter is shifted by four.  </li>
<li>The shifted word itself is spelled backwards or scrambled in some other manner.  </li>
<li>On the most difficult level, you might encounter a cipher that is layered over another cipher. In this case, you might decode a phrase into a nonsensical answer and then have to decipher it again by some additional method that is discovered in a separate place from the original key.  </li>
<li>To find the key to a shift cipher, look around for numbers that seem like they've been highlighted in some way or numbers that might be relevant to the cipher's writer. For example, you might find an underlined digit or a birthday. If you can't find a key, you can always try to "brute force" a cipher by running it through an online decryption service.
</li>
 </ul>
 <p>
 For further information, you can check out these websites: <br/><br/>
<a href="http://practicalcryptography.com/ciphers/caesar-cipher/" target="_blank" rel="noopener noreferrer">http://practicalcryptography.com/ciphers/caesar-cipher/</a><br/><br/>
<a href="https://cryptii.com/" target="_blank" rel="noopener noreferrer">https://cryptii.com/</a><br/><br/>

Well, that should be enough of an overview to clue you in to the most common ciphers and codes. <br/><br/>

I’m excited to see where your case ends up going, and I hope that this information will help you get there. <br/><br/>

Break a leg, as your folk say. <br/>
 Michelle
</p>
    </div>
  );
  }
};