import React, { Component } from 'react'

export default class transcripttheaterblueprint extends Component{
  render (){
    return (
    	<div className='rtfdoc'>
    <p>
	  SC1 UL3 UL1 DL2<br/>
UR2 UR3 DL1 UR3 UL2 UR1 SR1 UC3 DC1 DC3 DL2 SC1<br/>
DL3 UL3 SR3 SC2 UR2 DL2 SC1 DL2 UL2 SC2 UR3 UL2 DL2 UC3<br/>
<br/><br/>
[Rooms labeled with Roman numerals. Rooms IV, V, VII, XI, and XIV are circled.]
<br/><br/>
Loading Dock - I<br/>
Stage - [No number]<br/>
Office - II<br/>
Storage - III<br/>
Orchestra Pit - [No number]<br/>
Dressing Room 1 - IV<br/>
Dressing Room 2 - V<br/>
Storage - VII<br/>
Seats - VIII<br/>
Green Room - VI<br/>
Storage - IX<br/>
Coat Check - X<br/>
Storage - XI<br/>
Ladies - XII<br/>
Men - XIII<br/>
Bar - XIV<br/>
[Unlabeled room] - WV<br/>
Lobby - XVI<br/><br/>

[ 3x3 grid with PQR written in the middle left-hand square.]

    </p>

    
    </div>
    
  );
  }
};