import React, { Component } from 'react';
import Div100vh from 'react-div-100vh'
import MenuContentKeepSafeSite from '../components/KeepSafeSite/MenuContentKeepSafeSite';
import CheeseburgerMenu from '../components/just4FunSite/CheeseburgerMenu';
import LockScreenKeepSafeSite from '../components/KeepSafeSite/LockScreenKeepSafeSite';
import KeepSafeIcon from '../components/KeepSafeSite/KeepSafeIcon';
import LoginViewModalKeepSafe from '../components/KeepSafeSite/LoginViewModalKeepSafe';
import Modal from '../components/Modal'
import BMailEmailer from '../components/KeepSafeSite/BMailEmailer'

import store from 'store';
import { Toast } from 'react-bootstrap';


import { icons } from '../datas/season9/keepsafe.json';

class KeepSafeView extends Component {

	constructor(props) {
	    super(props);
	    this.currentEpisode = this.props.currentEpisode
      	this.state = {
	      currentView:"Lock",
	      menuOpen: false,
	      currentFolder:-1,
	      modal:false,
	      showPwModal:false,
	      selectedIconPw:-1,
	      showEmailer:false,
	      userEmail: store.get("userEmail"),
	      showModalEmailAddress:false
	    };

	    

	    let isLoggedIn = store.get("keepsafe");

	    if (typeof isLoggedIn !== "undefined") {
	    	this.state.currentView = "Home";
	    }
	    
	}
	tapLogo() {
		this.setState({ currentView: "Home" })
		this.setState({ modal: null })
		this.setState({ currentFolder: -1 })
	}


	openMenu() {
		this.setState({ menuOpen: true })
	}

	closeMenu() {
		this.setState({ menuOpen: false })
	}

	tapMenu(item) {
		console.log("tapMenu"+item)
		
		this.closeMenu()
		//this.setState({selectedIcon:null})
		if(item === "Files" || item === "home") {
			this.setState({ currentView: "Home" })
			this.setState({ modal: null })
			this.setState({ currentFolder: -1 })
		}
		


		
	}







	closeModal() {
		
		this.setState({ modal: null })

	
	}

	handleUpdateZindex = dataModal => {
	    return


   }


   handleClickIcon = iconId => {

       console.log(iconId)

       var icon;
       
       for( var i = 0; i < icons.length; i++){ 
          if ( parseInt(iconId) ===  icons[i].id) {
            icon = icons[i]
           
          }
        }

        if (typeof icon.passwordprotected !== 'undefined' && store.get(icon.name) !== icon.password){
              
              this.setState({showPwModal:true});
              this.setState({selectedIconPw:icon})
              return
        }

        if(icon.type === "folder") {
        	
        	this.setState({ currentFolder: icon})
        	return
        }

        var width = 850
	    var height = 525
	   
	    /*if (typeof icon.width !== 'undefined'){
	      width = icon.width
	    }
	    if (typeof icon.height !== 'undefined'){
	      height = icon.height
	    }*/

        let left = (window.innerWidth - parseInt(width))/2
    	let top = (window.innerHeight - parseInt(height))/2
        var modal = {"z":10,"type":icon.type, icons:[], name:icon.name, id:icon.id, width:icon.width, height:icon.height, left:left, top:top, image_url:icon.imageURL};
    
        this.setState({modal:modal});
        
    }


    openSelectedIconPw = (icon) => {
    	store.set(icon.name, icon.password);
    	this.setState({showPwModal:false});
    	this.setState({selectedIconPw:-1})
    	this.setState({ currentFolder: icon})
    	
    }


      handleTapBack = e => {
	      //If tapped on the overlay bg
	       if (e.target){
	       		
	    		if ( ((" " + e.target.className + " ").replace(/[\n\t]/g, " ").indexOf(" overlaypw ") > -1) || ((" " + e.target.className + " ").replace(/[\n\t]/g, " ").indexOf(" close-folderlogin ") > -1 ) ) {
			        e.preventDefault()  
			        this.setState({showPwModal:false, selectedIconPw:-1, modal:false});
			      }else if( ( (" " + e.target.className + " ").replace(/[\n\t]/g, " ").indexOf(" close-icon ") > -1 ) || ( (" " + e.target.className + " ").replace(/[\n\t]/g, " ").indexOf(" login-dt ") > -1 ) || ( (" " + e.target.className + " ").replace(/[\n\t]/g, " ").indexOf(" close-dtlogin") > -1 ) ) {
			        e.preventDefault()  
			        this.setState({showPwModal:false, selectedIconPw:-1, modal:false});

			      }
	    	}else {
	    		this.setState({showPwModal:false, selectedIconPw:-1, modal:false});
	    	}
	      
	  }

	  openEmailer = e => {
	  	
	  	if(this.state.userEmail  !== undefined && this.currentEpisode === 4){
	  		if (e.target){
	       		
	    		if ( ((" " + e.target.className + " ").replace(/[\n\t]/g, " ").indexOf(" overlaypw ") > -1) ) {
			        e.preventDefault()  
			        this.setState({showEmailer:false});
			      }else if( ( (" " + e.target.className + " ").replace(/[\n\t]/g, " ").indexOf(" close ") > -1 )  ) {
			        e.preventDefault()  
			        this.setState({showEmailer:false});

			      }else if(  ( (" " + e.target.className + " ").replace(/[\n\t]/g, " ").indexOf(" bridge-grp-email-thread-hit-area ") > -1 )   ) {
			      	this.setState({showEmailer:true});
			      }
	    	}else {
	    		this.setState({ showEmailer: !this.state.showEmailer })
	    	}
	  	}

	  	if(this.state.userEmail  === undefined) {
	  		
	  		this.setState({ showModalEmailAddress: true})
	  		
	  	}
	  	



		
	  }

	  handleShowModalEmailAddress = () => {
	  	this.setState({showModalEmailAddress:!this.state.showModalEmailAddress});
	  }

	  sendEmail = (message) => {
	  	this.props.triggerEmail(message)
	  }
	  handleUpdateZindex = dataModal => {
	    return


   }



	render() {


		if(this.state.currentView === "Lock") {

			return (
				 <Div100vh  dontresize="true" className="h-full w-full flex flex-col keepSafeSite"> 
					<LockScreenKeepSafeSite tapBack={this.props.tapBack} tapMenu={this.tapMenu.bind(this)}/>
					
				</Div100vh>
			)
		}


		const iconviews = icons.map((icon, index) => {
	        
			if(this.state.currentFolder === -1) {
				if (icon.parent === -1){
				    return (
				      <KeepSafeIcon icon={icon} key={index} iconSingleClick={this.handleClickIcon.bind(this)} /> 
				    )
		        }else {return false}
			}else if(icon.parent === this.state.currentFolder.id ) {
				return (
			      <KeepSafeIcon icon={icon} key={index} iconSingleClick={this.handleClickIcon.bind(this)} /> 
			    )
			}else {
				return false
			}
	        
		
		})

		
		return (
		  
		  <Div100vh  dontresize="true" className="h-full w-full flex flex-col keepSafeSite"> 
		  	
		  		
		  		<div className="keepsafe-main-screen">


				  	<div className="header">
				  		
				  		
			        	<img src="https://cdn.huntakiller.com/huntakiller/s9/icons/keepsafe/keepsafe-logo-blue.svg" className="logo" alt="logo" onClick={this.tapLogo.bind(this)} />
			   			
			   			<div className="keepsafe-search-container">
			        		<img src="https://cdn.huntakiller.com/huntakiller/s9/icons/keepsafe/keepsafe-searchbar.svg"  />
			        	</div>

			        	<img src="https://cdn.huntakiller.com/huntakiller/s9/icons/keepsafe/keepsafe-files-file-options-icon.svg" className="keepsafe-header-right-icons"/>

				  		<img className=" close-icon "  alt="close-dt" onClick={this.props.tapBack} src="https://cdn.huntakiller.com/huntakiller/s9/closebutton.svg"/>
			        	
			        	<img className="menu-toggle menu-toggle-icon mobile" alt="menu-toggle" onClick={this.openMenu.bind(this)} src="https://cdn.huntakiller.com/huntakiller/s8/menu-hamburger.png"/>
			        	

			        		
				  	</div>


			  	   <CheeseburgerMenu
				        isOpen={this.state.menuOpen}
				        closeCallback={this.closeMenu.bind(this)} tapMenu={this.tapMenu.bind(this)}>
				        <MenuContentKeepSafeSite currentView = {this.state.currentView} tapMenu={this.tapMenu.bind(this)}/>
			        </CheeseburgerMenu>



			        <div className="keepsafe-container " >
			        	
			        	<div className="desktop">
			        		<MenuContentKeepSafeSite currentView = {this.state.currentView} tapMenu={this.tapMenu.bind(this)}/>
			        	</div>
			        	

			        	<div className="keepsafe-container-content">
			        		<h3><span onClick={this.tapLogo.bind(this)} >Files</span>{this.state.currentFolder !== -1 && (" > "+this.state.currentFolder.name)}</h3>
			        		<p>Name</p>
			        		<ul className="keepsafe-iconlist">
				        		{iconviews}
				        	</ul>
			        	</div>
			        	
			        	
			        </div>



		            {this.state.showPwModal && (
		                <LoginViewModalKeepSafe tapBack={this.handleTapBack.bind(this)} icon={this.state.selectedIconPw}  openIcon={this.openSelectedIconPw.bind(this)} />
		             )}

		             {this.state.modal && ( 

			        	<div className="h-full w-full flex justify-center items-center z-10 overlaypw" onClick={this.handleTapBack}>
			          		<Modal modal={this.state.modal} key={this.state.modal.id}  closeModal={this.handleTapBack} openEmailer={this.openEmailer} updateZindex={this.handleUpdateZindex.bind(this)}/>
			            
			          	</div>	
			        )}

			        {this.state.showEmailer && ( 

			        	<div className="h-full w-full flex justify-center items-center z-10 overlaypw" onClick={this.openEmailer}>
			          		
			          		<BMailEmailer closeBmailer={this.openEmailer} sendEmail={this.sendEmail} />
			          	</div>	
			        )}




		        </div>
			  

		        {this.state.showModalEmailAddress  !== false && (
                <div className="HAKEmailChatContainer">
                    <Toast className='hak-email-sent-toast pt-2 pb-2'   delay={5000} autohide  onClose={() => this.handleShowModalEmailAddress()} style={{zIndex:10000000000}}>
                                            
                        <Toast.Body className="text-light text-center pb-0"><h4>Warning</h4>Please go to the Contact Section and set your email address before sending a message<br/>
                        <button className="btn text-info mt-2" onClick={this.handleShowModalEmailAddress}>Got It!</button></Toast.Body>
                    </Toast>
                </div>
            )}
            
		  </Div100vh>
		  



		)

	}
}

export default KeepSafeView
