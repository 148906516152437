import React, { Component } from 'react';
import Div100vh from 'react-div-100vh'
import MenuContentJust4Fun from '../components/just4FunSite/MenuContentJust4Fun';
import CheeseburgerMenu from '../components/just4FunSite/CheeseburgerMenu';
import HomeContentJust4Fun from '../components/just4FunSite/HomeContentJust4Fun';
import AboutContentJust4Fun from '../components/just4FunSite/AboutContentJust4Fun';
import MerchContentJust4Fun from '../components/just4FunSite/MerchContentJust4Fun';
import ForumContentJust4Fun from '../components/just4FunSite/ForumContentJust4Fun';
import AccountContentJust4Fun from '../components/just4FunSite/AccountContentJust4Fun';

class Just4FunSiteView extends Component {

	constructor(props) {
	    super(props);
	    this.currentEpisode = this.props.currentEpisode
      	this.state = {
	      currentView:"Home",
	      menuOpen: false
	    };
	    
	}
	tapLogo() {
		this.setState({ currentView: "Home" })
	}


	openMenu() {
		this.setState({ menuOpen: true })
	}

	closeMenu() {
		this.setState({ menuOpen: false })
	}

	tapMenu(item) {
		//console.log("tapMenu")
		
		this.closeMenu()
		//this.setState({selectedIcon:null})
		this.setState({ currentView: item })

		document.getElementById('just4FunSite-container').scrollTop = 0;
	
		//
		
	}


	render() {
		
		return (
		  
		  <Div100vh  dontresize="true" className="h-full w-full flex flex-col just4FunSite"> 
		  	<div className="header">
		  		<img className="logo" onClick={this.tapLogo.bind(this)} src="https://cdn.huntakiller.com/huntakiller/s8/317logo.png" alt="logo-Just4Fun" />

		  		<img className="menu-toggle menu-toggle-icon mobile" alt="menu-toggle" onClick={this.openMenu.bind(this)} src="https://cdn.huntakiller.com/huntakiller/s8/menu-hamburger.png"/>
	        		
		  		<img className=" close-icon "  alt="close-dt" onClick={this.props.tapBack} src="https://cdn.huntakiller.com/huntakiller/s8/closebutton.svg"/>
	        	
	        	<div className="desktop"><MenuContentJust4Fun currentView = {this.state.currentView} tapMenu={this.tapMenu.bind(this)}/></div>
	        		
		  	</div>


	  	   <CheeseburgerMenu
		        isOpen={this.state.menuOpen}
		        closeCallback={this.closeMenu.bind(this)} tapMenu={this.tapMenu.bind(this)}>
		        <MenuContentJust4Fun currentView = {this.state.currentView} tapMenu={this.tapMenu.bind(this)}/>
	        </CheeseburgerMenu>

	        <div className="just4FunSite-container flex-1 flex" id="just4FunSite-container">
	        	{this.state.currentView === "Sign In" && (
	        		<AccountContentJust4Fun />
	        	)}
	        	{this.state.currentView === "Home" && (
	        		<HomeContentJust4Fun tapMenu={this.tapMenu.bind(this)} />
	        	)}
	        	{this.state.currentView === "About" && (
	        		<AboutContentJust4Fun />
	        	)}
	        	{this.state.currentView === "Merch" && (
	        		<MerchContentJust4Fun />
	        	)}
	        	{this.state.currentView === "Forum" && (
	        		<ForumContentJust4Fun currentEpisode={this.currentEpisode}/>
	        	)}

	        	
	        	<div className="footer">

	        		<div>&nbsp;</div>
	        	</div>
	        </div>
		    
		    
		  </Div100vh>
		  



		)

	}
}

export default Just4FunSiteView