import React, { Component } from 'react'


class PastProjectsContentJWESite extends Component {
  

  render() {
    return (
      <>
      <div className="JWESite-home">

        <h1>PAST PROJECTS</h1>
        
        <h2>81 QUEEN ANNE STREET</h2>


        <div className="flex JWESite-pastproject-content">

        	<div>
	        	<img src="https://cdn.huntakiller.com/huntakiller/s9/photos/jwe/81-queenannestreet-1.jpg"  alt="home banner"/>
	          	 <p>
	          	 	<b><span className="red-text">Duration of Remodel:</span></b>10 weeks<br/><br/> 
	          	 	<b><span className="red-text">JWE purchased for:</span></b> $157,800<br/><br/> 
	          	 	<b><span className="red-text">Sold for:</span></b> $290,000
				 </p>
            </div>


            <div>
	        	<img src="https://cdn.huntakiller.com/huntakiller/s9/photos/jwe/81-queenannestreet-2.jpg"  alt="home banner"/>
	          	 <p>
	          	 	<b><span className="red-text">Renovation Highlights:</span></b><br/>
	          	 	New wood floors on 1st floor, open floor plan with LED-control fireplace, new water heater and HVAC system
				 </p>
            </div>


        </div>
        
        <div className="hr"></div>

        <h2>203 SOUTHDOWNS ROAD</h2>

        <div className="flex JWESite-pastproject-content">

        	<div>
	        	<img src="https://cdn.huntakiller.com/huntakiller/s9/photos/jwe/203-southdownsroad-1.jpg"  alt="home banner"/>
	          	 <p>
	          	 	<b><span className="red-text">Duration of Remodel:</span></b>15 weeks<br/><br/> 
	          	 	<b><span className="red-text">JWE purchased for:</span></b> $229,000<br/><br/> 
	          	 	<b><span className="red-text">Sold for:</span></b> $352,000
				 </p>
            </div>


            <div>
	        	<img src="https://cdn.huntakiller.com/huntakiller/s9/photos/jwe/203-southdownsroad-2.jpg"  alt="home banner"/>
	          	 <p>
	          	 	<b><span className="red-text">Renovation Highlights:</span></b><br/>
	          	 	All new wood floors, new gourmet kitchen with stainless steel appliances and ample counter and cabinet space, structurally reinforced walls, new roof, whirlpool bathtub, heated floors in master bedroom
				 </p>
            </div>


        </div>
        
        <div className="hr"></div>

        <h2>655 SUMMER STREET</h2>


        <div className="flex JWESite-pastproject-content">

        	<div>
	        	<img src="https://cdn.huntakiller.com/huntakiller/s9/photos/jwe/655-summerstreet-1.jpg"  alt="home banner"/>
	          	 <p>
	          	 	<b><span className="red-text">Duration of Remodel:</span></b>7 weeks<br/><br/> 
	          	 	<b><span className="red-text">JWE purchased for:</span></b> $206,000<br/><br/> 
	          	 	<b><span className="red-text">Sold for:</span></b> $350,000
				 </p>
            </div>


            <div>
	        	<img src="https://cdn.huntakiller.com/huntakiller/s9/photos/jwe/655-summerstreet-2.jpg"  alt="home banner"/>
	          	 <p>
	          	 	<b><span className="red-text">Renovation Highlights:</span></b><br/>
	          	 	All new, compressed wood banisters, open floor plan, new asphalt driveway, high ceilings with recessed lighting
				 </p>
            </div>


        </div>
        
        <div className="hr"></div>
        

         <p className="blue-text">AND MANY MORE<br/><br/></p>
        
      </div>
      </>
    )
  }
}



export default PastProjectsContentJWESite
