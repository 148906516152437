import React, { Component } from 'react'


class WhatsNewContentMRSite extends Component {
  

  render() {
    return (
      <>
      <div className="mrSite-home mrSite-safety">
      	<h1>What’s New<br/></h1>


        <div className="mrSite-localBusiness-content">

        	<div className="mrSite-whatsnewimg-container">

	        	<img src="https://cdn.huntakiller.com/huntakiller/s9/photos/town/whatsnew-student.jpg"  alt="what's new picture"/>
	        	
        	</div>


        	<p>
        		<b>Cora Reaver Memorial Scholarship Winner</b><br/><br/>

        		Congratulations to Mallory Rock High School senior Eve Journey, who was recently awarded the Cora Reaver Memorial Scholarship.<br/><br/>
Eve plans to study Digital Communications at Portland Maritime College. Way to go, Eve!<br/><br/>
Thanks to all who donated to the scholarship fund in memory of Beth Ferris-Hendricks. Your contributions are greatly appreciated.
        	</p>

        </div>


        <div className="mrSite-safety-content-bottom">
        	<p><b>Edgar Mallory Middle School Science Fair</b><br/><br/>Sixth-graders Anna Saracco, Simon Whittaker, and Devin Snow swept the categories, including Best Overall Project, at the annual Edgar Mallory Middle School science fair.<br/>
Congratulations to Anna, Simon, and Devin! You can view their presentation, called "Tracking Currents on Mallory Rock through Messages in Bottles" <span className="link"  onClick={() => {this.props.tapMap()}}>here</span>.
	</p>
        </div>
        
     
        
      </div>
      </>
    )
  }
}



export default WhatsNewContentMRSite
