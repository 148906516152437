
import React, { Component } from 'react'

export default class Transcriptrehearsalnotes extends Component{
  render (){
    return (
    <div className='rtfdoc'>
    <p>
	  <u>To do:</u>
	</p>
	  <ul>
	  	<li>Blocking points for rehearsal </li>
	  	<li>Edit scenery plans w/ Ira</li>
	  	<li>Check rigging before rehearsal</li>
	  	<li>Check program printing
	  		<ul>
	  			<li>Full cast cover, not solo</li>
	  		</ul>
	  	</li>

	  	<li>Did mail lose Evelyn's makeup kit?</li>
	  	<li>Check progress on costumes</li>
	  	<li>Pull out more sandbag counterweights</li>
	  </ul>
	<p>
	  <u>For home:</u>
	</p>
	<ul>
		<li>Milk</li>
		<li>Dry cleaning</li>
		<li>Louise's Cosmopolitan</li>
	</ul>
	<p>
	  <u>Nov. 6, 1934</u>
	</p>
	<p>[3 x 3 grid with ABC in the upper right-hand corner]</p>
    <p>
	  <u>Rehearsal Notes</u>
	</p>
	<p>Who’s needed?<br/>
Viola<br/>
Evelyn<br/>
Hector<br/>
Ira<br/>
George<br/>
Myself - Get there early to set up
</p>
<ul>
	<li>Vi forgetting lines [seven tally marks]
		<ul>
			<li>Run lines w/ her. Tomorrow?</li>
		</ul>
	</li>
	<li>SL curtain not pulling fast enough? Or SR too fast?</li>
	<li>Adjust lighting cue at Evelyn’s first entrance</li>
	<li>Tighten blocking during 1st scene - too far apart
		<ul>
			<li>Status on cityscape backdrop?</li>
		</ul>
	</li>
	<li>Ira favoring Evelyn
		<ul>
			<li>Ira telephone? Priorities?</li>
		</ul>
	</li>
	<li>SC1 SR2 DR3 DC3 SC1 SR2
		<ul>
			<li>DL2 DR3 DR3</li>
			<li>SC2 UL1 DR2 SR1 DL1</li>
			<li>DR3 DR2</li>
			<li>SC3 DR2 DL2 SR2 DR2 DL1 SR2</li>
			<li>SC3 DR2</li>
			<li>DL2 SC2 SR2</li>
			<li>UR2 UL1 UC1</li>
			<li>Have sit down. <u>Ira</u> is director</li>
		</ul>
	</li>
	<li>4:52 - Ruth stops in. Take 5</li>
	<li>5:00  Back to it.</li>
	<li>Spotlight dimming? Extra lamps in back</li>
	<li>DL floorboard creaking <u>so loud</u> - trip hazard?</li>
	<li>Hector excellent
		<ul>
			<li>Last play here? Getting too big</li>
			<li>Can Hector get everyone back in line?</li>
		</ul>
	</li>
	<li>Vocal/acoustics check - seems quiet</li>
	<li>Peace offering for Olga?
		<ul>
			<li><span className="strikethrough">Żubrówka</span> <u>Bad idea</u></li>
			<li><span className="strikethrough">Motion picture tickets?</span></li>
			<li><span className="strikethrough">Boxing match?</span></li>
			<li>Ask V</li>
		</ul>
	</li>
	<li>5:13. Olga. Everyone dismissed
		<ul>
			<li>Return her cane</li>
		</ul>
	</li>
	<li>Flowers for Vi?
		<ul>
			<li>George demanded dog roses - Once and Floral 616 E 75th</li>
			<li>Something bigger later?</li>
		</ul>
	</li>
	<li>DL2 UL1 SL3 SL2 DL2 DR3 SC2 SR2 UL3 DL2 DR3 DC3</li>
</ul>
    </div>
  );
  }
};