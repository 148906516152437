import React, { Component } from 'react'

export default class Transcriptjewelryappraisal extends Component{
  render (){
    return (
    	<div className='rtfdoc'>
    <p>
Never expected it would be worth so much. I feel so scrambled up inside. R vwhpz iN. wlmTvni fylgz gvvhrwix, kibizglnv gzilhvt. vS nlnivwxwmvv z mrKzzow ywzim clolpyx.
    </p>

    
    </div>
    
  );
  }
};