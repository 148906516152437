
import React, { Component } from 'react'

export default class Transcriptruthnote extends Component{
  render (){
    return (
    	<div className='rtfdoc'>
    <p>
	  September 2, 1934
<br/><br/>
Mr. Adler,
<br/><br/>
You must know I have the utmost respect for your work. With that in mind, I hope you’ll consider what might seem an inordinate request. Do not cast Viola Vane in your next production.
<br/><br/>
She is immoral and sets a poor example for those around her. As a new mother, I cannot expose my child to such vulgar influence. Miss Vane masquerades as an ingénue, but no respectable woman would act in such a manipulative and conceited manner. Her presence will only do your production harm.
<br/><br/>
I pray you will make the right decision. As Proverbs says, “Withhold not good from them to whom it is due, when it is in the power of thine hand to do it.”
<br/><br/>
Respectfully,<br/>
Mrs. Ruth Erikson<br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
    </p>

    
    </div>
    
  );
  }
};