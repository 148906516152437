 //Last-ever rehearsal

import React, { Component } from 'react'

import Lightbox from 'react-image-lightbox';

const images = [
 "https://cdn.huntakiller.com/huntakiller/s8/photo-crew-1.png",
 "https://cdn.huntakiller.com/huntakiller/s8/photo-crew-2.png",
 "https://cdn.huntakiller.com/huntakiller/s8/photo-crew-3.png",
 "https://cdn.huntakiller.com/huntakiller/s8/photo-crew-4.png",
 "https://cdn.huntakiller.com/huntakiller/s8/photo-crew-5.png",
 "https://cdn.huntakiller.com/huntakiller/s8/photo-crew-6.png",
 "https://cdn.huntakiller.com/huntakiller/s8/photo-crew-7.png",
 "https://cdn.huntakiller.com/huntakiller/s8/photo-crew-8.png"

];


class Thread2 extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      extended: false,
       photoIndex: 0,
       isOpen: false,
    };
  }



  toggleExpand = (event) =>{
    if(event.target.tagName !== "IMG"){
      this.setState({ extended: !this.state.extended });
    }else{

      var res = parseInt(event.target.src.charAt(event.target.src.length - 5))-1;
      
      this.setState({ isOpen: true, photoIndex:res });
    }
  }


  render() {
    const { photoIndex, isOpen } = this.state;
    return (
      <div className="thread-content">
        <div className="thread-header" onClick={this.toggleExpand}>
          <p className="thread-username"> Posted by <span className="font-bold">SuperLavender: </span></p>
          <h3 className={ !this.state.extended ? "thread-title-margin" : undefined }>Last-ever rehearsal</h3>

        </div>


        {
            this.state.extended &&
            <>
              <div className="thread-header" onClick={this.toggleExpand} >
                  <p>So, I happened to find a Blither account belonging to one of the reunion concert’s crew members. They were working the day Jake died and had posted some photos from that morning <span role="img" aria-label="sad-imojo">😢</span></p>
              
                  <div className="forum-image-container">
                     <img src="https://cdn.huntakiller.com/huntakiller/s8/photo-crew-1.png"  alt="about2"/>
                     <img src="https://cdn.huntakiller.com/huntakiller/s8/photo-crew-2.png"  alt="about2"/>
                     <img src="https://cdn.huntakiller.com/huntakiller/s8/photo-crew-3.png"  alt="about2"/>
                     <img src="https://cdn.huntakiller.com/huntakiller/s8/photo-crew-4.png"  alt="about2"/>
                     <img src="https://cdn.huntakiller.com/huntakiller/s8/photo-crew-5.png"  alt="about2"/>
                     <img src="https://cdn.huntakiller.com/huntakiller/s8/photo-crew-6.png"  alt="about2"/>
                     <img src="https://cdn.huntakiller.com/huntakiller/s8/photo-crew-7.png"  alt="about2"/>
                     <img src="https://cdn.huntakiller.com/huntakiller/s8/photo-crew-8.png"  alt="about2"/>
                     <div className="clear"></div>
                  </div>


                  <p>Look how much fun they were having during rehearsal! I bet everyone involved worked SO hard preparing for this concert, especially J4F <span role="img" aria-label="sad-imojo">😢</span></p>
              

              </div>

              <div className="thread-reply-container">

                <div className="thread-reply">
                  <div className="inner-thread-reply  border-left">
                    <p className="font-bold reply-username">Honey_Arcade:</p>
                    <p className="reply-content">Now I’m sad all over again.</p>
                  </div>
                  <p className="reply-btn font-bold">... reply</p>

                  <div className="thread-reply">
                    <div className="inner-thread-reply  border-left">
                      <p className="font-bold reply-username">SuperLavender:</p>
                      <p className="reply-content">Noooo, I’m so sorry, I didn’t mean to make anyone sad<span role="img" aria-label="crying-imojo">😭</span>. ​I just thought I should share <span role="img" aria-label="sad-imojo">😢</span></p>
                    </div>

                    <p className="reply-btn font-bold">... reply</p>

                    <div className="thread-reply">
                      <div className="inner-thread-reply  border-left">
                        <p className="font-bold reply-username">Honey_Arcade:</p>
                        <p className="reply-content">No no, I’m glad you did, I’m just being a baby.</p>
                      </div>

                      <p className="reply-btn font-bold">... reply</p>
                    </div>
                  </div>



                </div>

                <div className="thread-reply">
                    <div className="inner-thread-reply  border-left">
                      <p className="font-bold reply-username">meadowgoth:</p>
                      <p className="reply-content">Can’t believe these were taken just hours before he died...</p>
                    </div>
                    <p className="reply-btn font-bold">... reply</p>
                 </div>


                 <div className="thread-reply">
                    <div className="inner-thread-reply  border-left">
                      <p className="font-bold reply-username">Dryan:</p>
                      <p className="reply-content">dang. would love to see more behind-the-scenes. they should make a documentary</p>
                    </div>
                    <p className="reply-btn font-bold">... reply</p>
                </div>
              </div>



               <div className="thread-comment">
                  <div className="divider"></div>

                  <input placeholder="Sign In to Comment" disabled={true}/>
               </div>
               <div className="divider"></div>


            </>
        }

         {isOpen && (
          <Lightbox
            mainSrc={images[photoIndex]}
            nextSrc={images[(photoIndex + 1) % images.length]}
            prevSrc={images[(photoIndex + images.length - 1) % images.length]}
            onCloseRequest={() => this.setState({ isOpen: false })}
            onMovePrevRequest={() =>
              this.setState({
                photoIndex: (photoIndex + images.length - 1) % images.length,
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                photoIndex: (photoIndex + 1) % images.length,
              })
            }
          />
        )}


      </div>
    )
  }
}



export default Thread2
