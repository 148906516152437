import React, { Component } from 'react'
import store from 'store'
import DesktopIcon from '../components/DesktopIcon'
import Dock from '../components/Dock'
import Modal from '../components/Modal'
import Just4FunSiteView from './Just4FunSiteView'
import MRView from './MRView'
import KeepSafeView from './KeepSafeView'
import JWEView from './JWEView'
import Div100vh from 'react-div-100vh'
import LoginViewModal from './LoginViewModal'

import Inbox from '../InboxMOM'
import { Toast } from 'react-bootstrap';

// HAK styles
import '../styles/app.scss';
import '../components/EmailClient/email-client.scss';

import {getGetChildrenFromFolder} from '../utils.js';


export default class DesktopView extends Component {

	constructor(props) {
    	super(props);
      this.updateDimensions = this.updateDimensions.bind(this)
	    this.state = {
	      icons:  this.props.icons,
	      showPwModal: false,
        modals: [],
        showJust4FunSite:false,
        showMRSite:false,
        showKeefSafeSite:false,
        showJWESite:false,
        showInbox:false,
        selectedIconPw: null,
        currentEpisode: props.currentEpisode,
        showToastInbox:false
        
	    }

      console.log(props.currentEpisode)
      this.inbox = React.createRef()
	  }

  triggerEmail = (message) => {
    this.inbox.current.triggerEmail(message)
  }

  openInbox = e => {


    this.setState({showInbox:true});

  }


  closeInbox = e => {


    this.setState({showInbox:false});

  }

  updateEpisode = newEpisode => {
    //console.log("updateEpisode "+newEpisode)
    this.setState({currentEpisode:newEpisode});

  }

	updateDimensions() {
		//console.log("resize")
		var newIcons = this.state.icons
    if (window.innerWidth < 800) {
        let i = 0
        let top = 10
        let left = 20
        newIcons.forEach(icon => {
          if(icon.parent === -1){

            if (i % 3 === 0) {
              if (i !== 0) {
                top += 104
              }
              left = 20
            } else {
              left += 105
            }
            icon.top = top
            icon.left = left

            i++
          }

          
          //console.log("after "+i)
        })
        this.setState({icons:newIcons});
      } else {
          let i = 0
          let top = 50
          let left = 50
          newIcons.forEach(icon => {
            if(icon.parent === -1){
              if (i % 3 === 0) {
                if (i !== 0) {
                  top += 120
                }
                left = 50
              } else {
                left += 105
              }
              icon.top = top
              icon.left = left

              i++;
            }
            
            
            
          })
          this.setState({icons:newIcons});
        } 
	}


  handleTapBack = e => {

      //If tapped on the overlay bg
      if ( ((" " + e.target.className + " ").replace(/[\n\t]/g, " ").indexOf(" overlaypw ") > -1) || ((" " + e.target.className + " ").replace(/[\n\t]/g, " ").indexOf(" close-folderlogin ") > -1 ) ) {
        e.preventDefault()  
        this.setState({showPwModal:false});
        this.setState({selectedIconPw:null})
      }else if( ( (" " + e.target.className + " ").replace(/[\n\t]/g, " ").indexOf(" close-icon ") > -1 ) || ( (" " + e.target.className + " ").replace(/[\n\t]/g, " ").indexOf(" login-dt ") > -1 ) || ( (" " + e.target.className + " ").replace(/[\n\t]/g, " ").indexOf(" close-dtlogin") > -1 ) ) {
        e.preventDefault()  
        this.setState({showJust4FunSite:false, showKeefSafeSite:false, showMRSite:false, showJWESite:false});

      }
      

  }


  openSelectedIconPw(icon){
    //    console.log("openSelectedIconPw")
       //console.log(icon)

    store.set(icon.name, icon.password);



    let modals = this.state.modals

    var childrensIcon = getGetChildrenFromFolder(icon.id, this.state.icons)
    
    var width = 850
    var height = 525
    if (typeof icon.width !== 'undefined'){
      width = icon.width
    }
    if (typeof icon.height !== 'undefined'){
      height = icon.height
    }
    let left = (window.innerWidth - parseInt(width))/2
    let top = (window.innerHeight - parseInt(height))/2
    let z = this.getMaxZModal(modals) 
    if(modals.length >= 1){
      left = 50 + modals[modals.length-1].left
      top = 55 + modals[modals.length-1].top
    }
    var modal = {"z":z+1,"type":icon.type, name:icon.name, icons:childrensIcon, id:icon.id, width:icon.width, height:icon.height, left:left, top:top, image_url:icon.imageURL};
    modals = modals.concat(modal);
   
    this.setState({showPwModal:false});
    this.setState({selectedIconPw:null})
    this.setState({modals:modals});

  }


	handleClickIcon = e => {
      //console.log(e.target.id)
		   var iconId =  parseInt(e.target.id.substring(5, e.target.id.length));
       //console.log(iconIndex)
       //console.log(this.state.icons)
       //console.log("OPEN OBJECT "+iconId)
       let icon = this.state.icons[iconId]
       for( var i = 0; i < this.state.icons.length; i++){ 
          if ( parseInt(iconId) ===  this.state.icons[i].id) {
            icon = this.state.icons[i]
            //console.log(icon)
          }
        }


       if(icon.type === "external_url"){
        window.open(icon.url, "_blank")
        return

       }else if(icon.type === "Just4FunSite"){
          //console.log("devianttracker")
          this.setState({showJust4FunSite:true});
       }else if(icon.type === 'MRSite'){
          this.setState({showMRSite:true});

       }else if(icon.type === 'Keep Safe'){
          this.setState({showKeefSafeSite:true});


       }else if(icon.type === 'JWE Site'){
          this.setState({showJWESite:true});
       }else {
          let modals = this.state.modals

          
          let isPresent=false
          for( var k = 0; k < modals.length; k++){ 
            if ( modals[k].id === icon.id) {
              isPresent = true
              let z = this.getMaxZModal(modals)
              modals[k].z = z+1;
            }
          }
          if(!isPresent){
            
            if (typeof icon.passwordprotected !== 'undefined' && store.get(icon.name) !== icon.password){
                  


                  this.setState({showPwModal:true});
                  this.setState({selectedIconPw:icon})
                  
                  return
            }


            var childrensIcon = getGetChildrenFromFolder(icon.id, this.state.icons)

            // if the new modal is from another modal, replace the parent modal w new modal
            if(icon.parent > -1 && icon.type==="folder") {
                //console.log("REPLACEMODAL")
                for( var j = 0; j < modals.length; j++){ 
                  if(modals[j].id === icon.parent){
                    modals[j].name = icon.name
                    
                    modals[j].icons = childrensIcon
                    modals[j].id = icon.id
                  }

                }

            }else{
                var width = 850
                var height = 525
                if (typeof icon.width !== 'undefined'){
                  width = icon.width
                }
                if (typeof icon.height !== 'undefined'){
                  height = icon.height
                }
                let left = (window.innerWidth - parseInt(width))/2
                let top = (window.innerHeight - parseInt(height))/2
                let z = this.getMaxZModal(modals) 
                if(modals.length >= 1){
                  left = 50 + modals[modals.length-1].left
                  top = 55 + modals[modals.length-1].top
                }
                var modal = {"z":z+1,"type":icon.type, name:icon.name, icons:childrensIcon, id:icon.id, width:icon.width, height:icon.height, left:left, top:top, image_url:icon.imageURL, audio_url:icon.audioURL, video_url:icon.videoURL};
                modals = modals.concat(modal);
            }

          }

          //console.log(modals)



          this.setState({modals:modals});
          
       }
   }


  getMaxZModal(modals) {
    if(modals.length === 0) return 0
    return modals.reduce((max, b) => Math.max(max, b.z), modals[0].z);
  }

   handleLogout= e => {
       /*var iconId =  parseInt(e.target.id.substring(5, e.target.id.length));
       alert("OPEN MODAL "+iconId)*/
       e.preventDefault();
       //console.log("handleLogout")


       store.set('password', false);
       store.set('season', false);

       this.props.history.push('/')
       //this.props.history.push("Desktop")
   }

   handleUpdateZindex = dataModal => {
    //console.log("handleUpdateZindex")
    var modalId =  dataModal.id;
    var arr = this.state.modals;
    for( var i = 0; i < arr.length; i++){ 
       if ( arr[i].id === modalId) {
         let z = this.getMaxZModal(arr) 
         arr[i].z = z+1
       }
    }
    //console.log(arr)
    this.setState({modals:arr});
   }

   handleCloseModal = dataModal => {
    var modalId =  dataModal.id;
    //console.log(modalId)
    var arr = this.state.modals;

    for( var i = 0; i < arr.length; i++){ 
       if ( arr[i].id === modalId) {
         arr.splice(i, 1); 
       }
    }
    //console.log(arr)
    this.setState({modals:arr});
    
    //console.log(this.state)
   }

    handleShowInboxToast = () => {
        
        this.setState({showToastInbox:!this.state.showToastInbox});
        
    }

	componentDidMount() {
		  this.updateDimensions()
    	window.addEventListener("resize", this.updateDimensions);
  	}


     componentWillUnmount(){
        window.removeEventListener('resize', this.updateDimensions);
    };


    render() {
    	const   icons   = this.state.icons
      const modals = this.state.modals
      //console.log(this.state.modals)
      //console.log(modals)
    	const iconviews = icons.map((icon, index) => {

        if (icon.parent === -1 && icon.episode <= this.state.currentEpisode){
		    return (
		      <DesktopIcon icon={icon} key={index} iconSingleClick={this.handleClickIcon.bind(this)} /> 
		    )
        }else {return false}
		  })


      const modalviews = modals.map((modal, index) => {
        
        return (
          <Modal modal={modal} key={modal.id} currentSeason={this.props.user.season} currentEpisode={this.state.currentEpisode} closeModal={this.handleCloseModal} updateZindex={this.handleUpdateZindex}  iconSingleClick={this.handleClickIcon.bind(this)}/> 
        )
        
      })


      var urlEmailIcon = this.props.user.season < 8 ? "https://cdn.huntakiller.com/huntakiller/s7/icon-email.svg" : "https://cdn.huntakiller.com/huntakiller/s8/icon-email.svg";
      if(this.props.user.season === 9) {
        urlEmailIcon = "https://cdn.huntakiller.com/huntakiller/s9/icons/icon-contact.svg"
      }


        return (
          <>
            <Div100vh dontresize="true" className={`app ${this.props.className}`} >

            <div className="flex-1">
              <div className="relative">{iconviews}</div>
            </div> 

            <div className="icon-wrapper" onClick={this.openInbox}  id="email-icon">
              <img src={urlEmailIcon}  className="icon-image" alt="inbox" />
            </div>

            <Dock logout="true"  clickLogout={this.handleLogout.bind(this)}/>{modalviews}</Div100vh>
            
            {this.state.showPwModal && (
                <LoginViewModal tapBack={this.handleTapBack.bind(this)} icon={this.state.selectedIconPw}  openIcon={this.openSelectedIconPw.bind(this)} />
              )}


              <div style={{display: this.state.showInbox  ? 'block' : 'none'}}>
           <Inbox  ref={this.inbox} showInboxToast={this.handleShowInboxToast.bind(this)}  tapBack={this.closeInbox.bind(this)} currentSeason={this.props.user.season} currentEpisode={this.props.user.episode} updateEpisode={this.updateEpisode.bind(this)}/>
              </div>


            {this.state.showJust4FunSite && (
                <Just4FunSiteView tapBack={this.handleTapBack.bind(this)} currentEpisode={this.props.currentEpisode}/>
              )}

              {this.state.showMRSite && (
                <MRView tapBack={this.handleTapBack.bind(this)} currentEpisode={this.props.currentEpisode}/>
              )}

              {this.state.showKeefSafeSite && (
                <KeepSafeView tapBack={this.handleTapBack.bind(this)} triggerEmail={this.triggerEmail.bind(this)} currentEpisode={this.props.currentEpisode}/>
              )}

              {this.state.showJWESite && (
                <JWEView tapBack={this.handleTapBack.bind(this)} currentEpisode={this.props.currentEpisode}/>
              )}

              {this.state.showToastInbox  !== false && (
                <div className="HAKEmailChatContainer m-0">
                    <Toast className='hak-email-sent-toast pt-2 pb-2'   delay={6000} autohide  onClose={() => this.handleShowInboxToast()} style={{zIndex:10000000000}}>
                                            
                        <Toast.Body className="text-light text-center pb-0"><h4>New Message</h4>You received a message!<br/> Go check the Contact Section<br/>
                        <button className="btn text-info mt-2" onClick={this.handleShowInboxToast}>Got It!</button></Toast.Body>
                    </Toast>
                </div>
            )}
              
          </>
        )

    }
}
