import React from "react";
import store from 'store';

export default class EnterEmailView extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      email: "",
      isValid:false,
      error:false,
    }
  }

  setEmail = () => {
    console.log("setEmail")
    
    if (/^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(this.state.email)) {
      store.set('userEmail', this.state.email);
      this.props.closeCallback(this.state.email)
      this.setState({error:false});
    }else{
      this.setState({error:true});
    }

    
  }


  updateEmail = (event) => {

    this.setState({error:false,email:event.target.value, isValid:(event.target.value !== "" ? true : false)})
  }


  render() {


    return (

      <div className="h-full w-full flex justify-center items-center ">
         <div className="h-full w-full flex justify-center items-center overlay">


         </div>
        <div className="login-box z-10 rounded-lg flex flex-col justify-center items-center p-4 pt-10 pb-8">
              <p className="text-center text-white pb-6">Please provide your email address before sending an email.</p>

              <div className=" mb-3">
                  <input className="shadow appearance-none border  w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" value={this.state.email}
            onChange={this.updateEmail} type="text" autoFocus id="username" placeholder="Email" />
                   <p className="error mt-2">{this.state.error && "Invalid Email"} &nbsp;</p>
                    </div>




              <div className="flex  items-center justify-between">

                <button  onClick={() => this.setEmail()} className="newmessage bg-grey  mt-2  w-full text-black font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" id="sendNewMessage"  disabled={this.state.isValid ? false : true}  >
                  ENTER
                </button>


              </div>

        </div>





    </div>);
  }
}