import React, { Component } from 'react'


class HomeContentJWESite extends Component {
  

  render() {
    return (
      <>
      <div className="JWESite-home">

        <h1>About JWE</h1>
        
        <div className="flex JWESite-home-content">
             <img className="jordan-realtor" src="https://cdn.huntakiller.com/huntakiller/s9/photos/jwe/jordan-realtor.jpg"  alt="home banner"/>
             <p>JW Enterprises is owned and run solely by Mr. Jordan Woodard, premier Mallory Rock real estate owner, investor and developer, entrepreneurial mogul, and philanthropist. Mr. Woodard is renowned for his streamlined efficiency, top notch values, and keen design eye, which all lend to his great success in property renovation projects. Mr. Woodard’s work is currently exclusive to Mallory Rock, ME, providing services to support the local economy and uphold standards as a community leader.</p>
        </div>
        
        <h3>Looking to buy, sell, or remodel? Call 207-200-4909 today.</h3>
        
      </div>
      </>
    )
  }
}



export default HomeContentJWESite
