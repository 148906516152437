import React, { Component } from 'react'

/*import './menuContent.css'*/

class HomeContentJust4Fun extends Component {
  

  render() {
    return (
      <>
      <div>
        <div className="home-banner-just4Fun">
          <img src="https://cdn.huntakiller.com/huntakiller/s8/lhp-1915.jpg"  alt="home banner"/>
        </div>


        <div className = "flex sectionAbout ">
          <div className="about-img-just4Fun ">
            <img src="https://cdn.huntakiller.com/huntakiller/s8/lhp-0818.jpg" alt="home 1"/>
          </div>
          <div className="about-txt-just4Fun clickable"  onClick={() => {this.props.tapMenu("About")}}>
            <h2>ABOUT</h2>
            <div className="mobile divider"></div>
            <p>Get to know the guys of Just4Fun: Jake, Gabe, Marcus, and Ollie! Their hit album, Ultraviolet, took the world by storm in 2001 and became an instant classic, paving the way for their legendary Ultraviolet tour.</p>
          </div>
        </div> 


        <div className = "flex sectionMerch ">
          <div className="about-txt-just4Fun clickable"  onClick={() => {this.props.tapMenu("Merch")}}>
            <h2>MERCH</h2>
            <div className="mobile divider"></div>
            <p>Purchase exclusive merchandise to rep your love for Just4Fun! We offer t-shirts, hats, and more, featuring J4F’s iconic eye-catching logo. Your purchase will support the band and future merchandising efforts.</p>
          </div>
          <div className="about-img-just4Fun ">
            <img src="https://cdn.huntakiller.com/huntakiller/s8/store-shirt.png"  alt="home 2"/>
          </div>
        </div>

        <div className = "flex sectionForum " >
          <div className="about-img-just4Fun ">
            <img src="https://cdn.huntakiller.com/huntakiller/s8/lhp-1917.jpg"  alt="home 3"/>
          </div>  
          <div className="about-txt-just4Fun clickable" onClick={() => {this.props.tapMenu("Forum")}}>
            <h2>FORUM</h2>
            <div className="mobile divider"></div>
            <p>Chat with fellow fans about all things Just4Fun! This moderated forum allows for safe, friendly interactions about the band and other related topics. Only those with an account may create new threads or post.</p>
          </div>
          
        </div>
      </div>
      </>
    )
  }
}



export default HomeContentJust4Fun
