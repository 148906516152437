//Wish we had more

import React, { Component } from 'react'



class Thread4 extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      extended: false
    };
  }



  toggleExpand = () =>{
    this.setState({ extended: !this.state.extended });
  }


  render() {

    return (
      <div className="thread-content">
        <div className="thread-header" onClick={this.toggleExpand}>
          <p className="thread-username"> Posted by <span className="font-bold">lovetorevolt: </span></p>
          <h3 className={ !this.state.extended ? "thread-title-margin" : undefined }>Wish we had more</h3>

        </div>


        {
            this.state.extended &&
            <>
              <div className="thread-header" onClick={this.toggleExpand} >
                  <p>Anyone else wish this group did something more original when they were together? Didn’t it sound like they were always kind of missing something? Jake’s solos were decent, but, man, anyone seen that Columbus Day movie? He should’ve stuck to singing.</p>
              </div>
              <div className="thread-reply-container">

                <div className="thread-reply">
                  <div className="inner-thread-reply  border-left">
                    <p className="font-bold reply-username">jakesfan444:</p>
                    <p className="reply-content">Lulz Columbus Day even I gotta say that was bad</p>
                  </div>
                  <p className="reply-btn font-bold">... reply</p>
                </div>

                <div className="thread-reply">
                  <div className="inner-thread-reply  border-left">
                    <p className="font-bold reply-username">rydogrydog:</p>
                    <p className="reply-content">I mean, no complaints here, I just listen to Ultraviolet on repeat. Maybe my standards arent the best but whatev lol</p>
                  </div>
                  <p className="reply-btn font-bold">... reply</p>

                  <div className="thread-reply">
                    <div className="inner-thread-reply  border-left">
                      <p className="font-bold reply-username">Honey_Arcade:</p>
                      <p className="reply-content">Jake + Kitten Club collab &gt; just4fun’s entire discography no offense</p>
                    </div>

                    <p className="reply-btn font-bold">... reply</p>

                    <div className="thread-reply">
                      <div className="inner-thread-reply  border-left">
                        <p className="font-bold reply-username">arcangel4902:</p>
                        <p className="reply-content">Jake + Kylene 4ver!</p>
                      </div>

                      <p className="reply-btn font-bold">... reply</p>
                    </div>
                  </div>
                </div>

                 <div className="thread-reply">
                    <div className="inner-thread-reply  border-left">
                      <p className="font-bold reply-username">just_funfunfunfun:</p>
                      <p className="reply-content">Wasn’t a fan of his solo stuff. Why didn’t the remaining three just try to start up again without Jake? They coulda gotten someone to replace him</p>
                    </div>
                    <p className="reply-btn font-bold">... reply</p>

                    <div className="thread-reply">
                      <div className="inner-thread-reply  border-left">
                        <p className="font-bold reply-username">jakesfan444:</p>
                        <p className="reply-content">Uhhhh becuse that’s sacrilege, no one can replace THE jake</p>
                      </div>
                      <p className="reply-btn font-bold">... reply</p>
                   </div>

                    <div className="thread-reply">
                      <div className="inner-thread-reply  border-left">
                        <p className="font-bold reply-username">lovetorevolt:</p>
                        <p className="reply-content">Well, did you ever hear about J4F’s phantom member? They were supposedly supposed to be five, per the usual formula.</p>
                      </div>
                      <p className="reply-btn font-bold">... reply</p>

                      <div className="thread-reply">
                        <div className="inner-thread-reply  border-left">
                          <p className="font-bold reply-username">Rydogrydog:</p>
                          <p className="reply-content">Oh yeah word. Reminds me of that one rumor that all boy bands are scientifically fabricated in some giant music company’s basement lol. Why didn’t they just clone Jake?? Guess then there’d be two of him walking around lollll</p>
                        </div>
                        <p className="reply-btn font-bold">... reply</p>
                      </div>

                      <div className="thread-reply">
                        <div className="inner-thread-reply  border-left">
                          <p className="font-bold reply-username">just_funfunfunfun:</p>
                          <p className="reply-content">Dunno if it would’ve made a difference</p>
                        </div>
                        <p className="reply-btn font-bold">... reply</p>
                      </div>

                    </div>


                 </div>

                 
              </div>
               <div className="thread-comment">
                  <div className="divider"></div>

                  <input placeholder="Sign In to Comment" disabled={true}/>
               </div>
               <div className="divider"></div>

            </>
        }



      </div>
    )
  }
}



export default Thread4
