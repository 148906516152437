import React, { Component } from 'react'

export default class transcriptdeliveryslip extends Component{
  render (){
    return (
    	<div className='rtfdoc'>
    <p>
	  Paladin Protective Services
<br/>556 E. 95th Street, NY
<br/><br/>
Delivery Note
<br/><br/>
Order # 4815162342<br/>
Delivery Date: Feb. 20, 1934
<br/><br/>
Mr. Joe Sampson<br/>
Address: Cadence Theatre, 39th & Broadway<br/>
Model: Sentinel lockbox (medium size)
</p>
<table>
<tbody>
<tr>
	<td>Box</td>
	<td>$3.53</td>
</tr>
<tr>
	<td>Delivery fee</td>
	<td>$0.50</td>
</tr>
<tr>
	<td className="strikethrough">Installation fee</td>
	<td>Customer will install</td>
</tr>
<tr>
	<td>Total</td>
	<td>$4.03</td>
</tr>

</tbody>
</table>

<p><br/>
Delivered by: Bill Highman<br/>
Received by: Joe Sampson 
<br/><br/>
[3x3 grid with the letters MNO written in the top right-hand corner]
<br/><br/>
UC3 DL2 SC2 UL1 DL2 UC2 UL2 UL3 SL2 UR3 DL3 DR1 UL3 SC3 UL2 DL2 DL2 DL1 UR2 DL2 UC3 SR1 UR2 UR3 DL1 UR3 UL2 UR1 SC2 UR2 UR3 SC1 DL3 SR3 UL3 UL1 UR2 UR3 UL1
    </p>

    
    </div>
    
  );
  }
};