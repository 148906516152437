import React, { Component } from 'react'

export default class Transcripolgaletter extends Component{
  render (){
    return (
    	<div className='rtfdoc'>
    <p>
	 Mr. Adler,
<br/><br/>
I must tell you about my Viktoria, and I need to do it in a way where you will sit and listen carefully, because you should be clear of mind for all the things I have to say. I see the looks you are giving my daughter, even when she is beside Hector, and I know because of those looks that you will listen if I say it is important and I say it is about Viktoria.
<br/><br/>
Viktoria worked very hard to get to where she is, but she still has proven to me that she needs me—not anyone else, not Hector, not you—to take care of her life. I worked hard too. And Viktoria is thinking of throwing all of that work away. My Iosif and I escaped Romania and after he died, God rest his soul, I raised Viktoria myself. I gave her a good life where she would not have to run away like I ran away. I worked in places other people look down their noses at. All so I could keep my Viktoria fed and clothed.
<br/><br/>
Now we have a good life, a life where I no longer have to work in butcher shops or scrub floors. What I do is for my daughter and her career on the stage now, and so I do not think of it so much as work. Viktoria does not have to worry about where she will find food or shelter or clothing, because everyone knows her face now, and yet I sense that she longs for something else. We go to parties, and Viktoria can afford to buy us nice things, things we never even dreamed of back home, and yet I sense she longs for something else. Without this, we will be forced to go back to what we had before, which was nothing.
<br/><br/>
You and I do not like each other, but I know we both want what is best for my daughter: that she not throw away all she has now. Her fame. Her money. Her marriage. We have all helped Viktoria get to where she is, and we cannot throw away all that hard work. Perhaps, though it pains me to say it, she will listen more to you than she will to me. 
<br/><br/>
You cannot let her give up all that she has gained.
<br/><br/>
Olga<br/>
December 20th, 1933

    </p>

    
    </div>
    
  );
  }
};