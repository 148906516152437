import React, { Component } from 'react'
import store from 'store';
import Div100vh from 'react-div-100vh'
import {doesSeasonPasswordExist} from '../utils.js';
export default class SeasonLoginView extends Component {

	constructor(props) {
	    super(props);
	    this.state = {
	      password: "",
	      username: "",
	      errorPw: false,
	      errorUsername: false
	    };
	    store.set('season', false);
	}

	validateForm() {
    	//return this.state.password.length > 0;
    	return true
  	}


  	handleChange = event => {
  		
	    this.setState({
	      [event.target.id]: event.target.value
	    });
	 }

	handleSubmit = event => {
		//console.log("submit")
	    event.preventDefault();
	    document.getElementById("username").blur();
	    document.getElementById("password").blur();
	    const password = this.state.password;

	    this.setState({ errorUsername: false });
	    this.setState({ errorPw: false });

	    
		if (doesSeasonPasswordExist(password, this.props.users) === false){
		    this.setState({ errorPw: true });
		}else{
			store.set('season', password);
			
			setTimeout(
			    function() {
			        this.props.history.push("Desktop")
			    }
			    .bind(this),
			    500
			);
		} 
	}


	handleKeyPress = (event) => {
	  if(event.key === 'Enter'){
	    //console.log('enter press here! ')
	    document.activeElement.blur();
	    event.preventDefault();
	  }
	}


    render() {

    	const { errorPw, errorUsername } = this.state;


        return (<Div100vh  dontresize="true"  className="h-full w-full flex justify-center items-center z-10 seasonDesktop">

	        	<div className="login-box rounded-lg flex flex-col justify-center items-center p-4 pt-10 pb-8">
	        		<img src="https://cdn.huntakiller.com/huntakiller/s7/logo.png" alt="logo" />
		        	
		        	

		        	<form className=" rounded  pt-6 " onSubmit={this.handleSubmit}>
		        		<div className="">
		        			<div className=" mb-3">
		        				<input className="shadow appearance-none border  w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" value={this.state.username}
              onChange={this.handleChange} type="text" autoFocus id="username" placeholder="Username" onKeyPress={this.handleKeyPress}/>
             					<p className="error mt-2">{errorUsername && "Invalid Username"} &nbsp;</p>
              				</div>
              				<div className=" mb-3">
			        			<input className="shadow appearance-none border  w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" value={this.state.password}
	              onChange={this.handleChange} type="password" autoFocus id="password" placeholder="Password" onKeyPress={this.handleKeyPress}/>
	              				<p className="error mt-2">{errorPw && "Invalid Password"} &nbsp;</p>
              				</div>
		        		</div>
		        		<div className="flex items-center justify-between">
					      <button className="bg-grey mx-8  w-full text-black font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" id="episodeLogin" type="submit" disabled={!this.validateForm()}>
					        ENTER
					      </button>
					     
					    </div>
		        	</form>

		        </div>
	        </Div100vh>)
    }
}
