import React, { Component } from 'react'

export default class LoginViewModalKeepSafe extends Component {

	constructor(props) {
	    super(props);
	    this.state = {
	      password1: "",
	      password2: "",
	      password3: "",
	      password4: "",
	      error: false,
	    };
	}

	validateForm() {
    	/*return this.state.password.length > 0;*/
    	return true
  	}


  	handleChange = event => {
  		if(event.target.value.length <= 1){
		    this.setState({
		      [event.target.id]: event.target.value
		    });
		}
	 }

	handleSubmit = event => {
		//console.log("submit")
	    event.preventDefault();

	    document.getElementById("password1").blur();
	    document.getElementById("password2").blur();
	    document.getElementById("password3").blur();
	    document.getElementById("password4").blur();


	    var pw = this.state.password1+this.state.password2+this.state.password3+this.state.password4;

	    

	    this.setState({ error: false });
	    if(pw.toLowerCase() !== this.props.icon.password.toLowerCase()){

	    	return this.setState({ error: true });
	    }else{
	    	this.props.openIcon(this.props.icon)
	    }
	    
	}

	handleKeyPress = (event) => {
	  if(event.key === 'Enter'){
	    //console.log('enter press here! ')
	    document.activeElement.blur();
	    event.preventDefault();
	  }
	}



    render() {
        return (<div className="h-full w-full flex justify-center items-center z-10 overlaypw" onClick={this.props.tapBack}>

	        	<div className="login-box login-box-keepsafe-folder rounded-lg flex flex-col justify-center items-center p-4 pt-10 pb-8">
	        		<div className="mr-2 "><img onClick={this.props.tapBack} className=" close-icon-modal close-folderlogin"  alt="close-dt" onClick={this.props.tapBack} src="https://cdn.huntakiller.com/huntakiller/s5/dt-close-button.svg"/></div>
	        	
		        	<p className="text-center mt-3"><b>Enter password</b></p>
		        	<p className="text-center mb-3 mt-1">{this.props.icon.hint}</p>

		        	<form className=" rounded  pt-6 " onSubmit={this.handleSubmit}>
		        		<div className="">
		        			
              				<div className=" mb-3 px-6">
			        			<div className="flex">

	          <input className="shadow appearance-none border uppercase mr-6 text-center  w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" value={this.state.password1}
              onChange={this.handleChange} type="text" maxLength="1" autoFocus id="password1" onKeyPress={this.handleKeyPress}/>

              <input className="shadow appearance-none border uppercase mr-6 text-center  w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" value={this.state.password2}
              onChange={this.handleChange} type="text"  maxLength="1" id="password2" onKeyPress={this.handleKeyPress}/>

              <input className="shadow appearance-none border uppercase mr-6 text-center  w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" value={this.state.password3}
              onChange={this.handleChange} type="text"  maxLength="1" id="password3" onKeyPress={this.handleKeyPress}/>

              <input className="shadow appearance-none border uppercase  w-full text-center py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" value={this.state.password4}
              onChange={this.handleChange} type="text" maxLength="1"  id="password4" onKeyPress={this.handleKeyPress}/>

	          				</div>
	              				<p className="error mt-2">{this.state.error && "Invalid Password"} &nbsp;</p>
              				</div>
		        		</div>
		        		<div className="flex items-center justify-between">
					      <button className="bg-grey mx-8  w-full text-black font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="submit" disabled={!this.validateForm()}>
					        ENTER
					      </button>
					     
					    </div>
		        	</form>
		        	
		        </div>
	        </div>)
    }
}
