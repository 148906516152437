//I MET OLLIE!!!

import React, { Component } from 'react'
import Lightbox from 'react-image-lightbox';

const images = [
 "https://cdn.huntakiller.com/huntakiller/s8/i-met-ollie.png",
];


class Thread6 extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      extended: false,
      photoIndex: 0,
      isOpen: false,
    };
  }



  toggleExpand = (event) =>{
    if(event.target.tagName !== "IMG"){
      this.setState({ extended: !this.state.extended });
    }else{
      this.setState({ isOpen: true });
    }
    
  }


  render() {
    const { photoIndex, isOpen } = this.state;
    return (
      <div className="thread-content">
        <div className="thread-header" onClick={this.toggleExpand}>
          <p className="thread-username"> Posted by <span className="font-bold">hanabanana: </span></p>
         <h3 className={ !this.state.extended ? "thread-title-margin" : undefined }>I MET OLLIE!!!</h3>

        </div>


        {
            this.state.extended &&
            <>
              <div className="thread-header" onClick={this.toggleExpand} >
                  <p>Okay so I haven’t logged in to this place in a million years but I just had to share this</p>
                  <div className="forum-image-container">
                    <img src="https://cdn.huntakiller.com/huntakiller/s8/i-met-ollie.png"  alt="olliefan" width="400"/>
                    <div className="clear"></div>
                  </div>
                  <p>I MET OLLIE AT A LIQUOR STORE LMAO. WHAT IS MY LIFE. Like, I knew his studio was apparently nearby, but I never visit that part of town?? And I’m just with my friend trying to get some wine for game night and BAM Ollie’s there. It was weird though, I didn’t really get all starstruck?? I just said I was a fan and asked for a photo, and he was like yeah that’s cool. Such a sweet guy omg <span role="img" aria-label="heart-emoji">❤️❤️❤️❤️❤️</span></p>
              </div>
              <div className="thread-reply-container">
                <div className="thread-reply">
                  <div className="inner-thread-reply  border-left">
                    <p className="font-bold reply-username">meadowgoth:</p>
                    <p className="reply-content">Oh... my... god... You are so freaking lucky</p>
                  </div>
                  <p className="reply-btn font-bold">... reply</p>
                </div>

                <div className="thread-reply">
                    <div className="inner-thread-reply  border-left">
                      <p className="font-bold reply-username">jakesfan444:</p>
                      <p className="reply-content">WHAT HOLY SHIT AHKJFDKLKFHJAHF</p>
                    </div>
                    <p className="reply-btn font-bold">... reply</p>
                </div>

                <div className="thread-reply">
                    <div className="inner-thread-reply  border-left">
                      <p className="font-bold reply-username">Dryan:</p>
                      <p className="reply-content">Dude, that’s so awesome! What liquor store?</p>
                    </div>
                    <p className="reply-btn font-bold">... reply</p>

                    <div className="thread-reply">
                        <div className="inner-thread-reply  border-left">
                          <p className="font-bold reply-username">hanabanana:</p>
                          <p className="reply-content">Idk if I should say, I don’t want people to start stalking him or anything lol. I mean everyone knows his studio is just outside L.A. I don’t think he gets recognized a lot tho cuz he was surprised when I came up to him</p>
                        </div>
                        <p className="reply-btn font-bold">... reply</p>

                        <div className="thread-reply">
                          <div className="inner-thread-reply  border-left">
                            <p className="font-bold reply-username">Dryan:</p>
                            <p className="reply-content">Yeah, you’re right. That’s crazy though</p>
                          </div>
                          <p className="reply-btn font-bold">... reply</p>
                      </div>

                    </div>

                </div>

                <div className="thread-reply">
                    <div className="inner-thread-reply  border-left">
                      <p className="font-bold reply-username">ollie_oop111:</p>
                      <p className="reply-content">I’m insanely jealous wtf HE’S STILL SO CUTE.</p>
                    </div>
                    <p className="reply-btn font-bold">... reply</p>
                </div>


                <div className="thread-reply">
                    <div className="inner-thread-reply  border-left">
                      <p className="font-bold reply-username">Lust4fun:</p>
                      <p className="reply-content"><span role="img" aria-label="very-sad-imojo">😭</span> God I wish that were me</p>
                    </div>
                    <p className="reply-btn font-bold">... reply</p>
                </div>


              </div>



               <div className="thread-comment">
                  <div className="divider"></div>

                  <input placeholder="Sign In to Comment" disabled={true}/>
               </div>
               <div className="divider"></div>

            </>
        }


        {isOpen && (
          <Lightbox
            mainSrc={images[photoIndex]}
            onCloseRequest={() => this.setState({ isOpen: false })}
            onMovePrevRequest={() =>
              this.setState({
                photoIndex: (photoIndex + images.length - 1) % images.length,
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                photoIndex: (photoIndex + 1) % images.length,
              })
            }
          />
        )}

      </div>
    )
  }
}



export default Thread6
