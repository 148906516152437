//RIP JAKE

import React, { Component } from 'react'



class Thread9 extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      extended: false
    };
  }



  toggleExpand = () =>{
    this.setState({ extended: !this.state.extended });
  }


  render() {

    return (
      <div className="thread-content">
        <div className="thread-header" onClick={this.toggleExpand}>
          <p className="thread-username"> Posted by <span className="font-bold">arcangel4902: </span></p>
          <h3 className={ !this.state.extended ? "thread-title-margin" : undefined }>RIP JAKE</h3>

        </div>


        {
            this.state.extended &&
            <>
              <div className="thread-header" onClick={this.toggleExpand} >
                  <p>I know there’s a bunch of other threads on this already, but I wanted to give people another place to talk about what happened with Jake. We’re all superfans, and we all love the music and the band and the boys in it, so this should be a space where we can all help each other out and deal with what happened.</p>
              </div>
              <div className="thread-reply-container">
                <div className="thread-reply">
                  <div className="inner-thread-reply  border-left">
                    <p className="font-bold reply-username">Dryan:</p>
                    <p className="reply-content">Yeah that really sucks, what a horrible way to go :( hope his family’s doing alright</p>
                  </div>
                  <p className="reply-btn font-bold">... reply</p>
                </div>

                <div className="thread-reply">
                  <div className="inner-thread-reply  border-left">
                    <p className="font-bold reply-username">Honey_Arcade:</p>
                    <p className="reply-content">RIP Jake we’ll miss you!</p>
                  </div>

                  <p className="reply-btn font-bold">... reply</p>
                </div>


                <div className="thread-reply">
                  <div className="inner-thread-reply  border-left">
                    <p className="font-bold reply-username">lovetorevolt:</p>
                    <p className="reply-content">It sucks, but shouldn’t we all be trying to move on? Like you said, this forum is full of these kinds of threads. Let’s just let it rest.</p>
                  </div>
                  <p className="reply-btn font-bold">... reply</p>

                  <div className="thread-reply">
                    <div className="inner-thread-reply  border-left">
                      <p className="font-bold reply-username">arcangel4902:</p>
                      <p className="reply-content">Yeah, I get it, but it’s not cool to be so abrasive about it. Jake was like a close friend to a lot of us. Imagine telling this to someone who lost a friend??</p>
                    </div>
                    <p className="reply-btn font-bold">... reply</p>

                    <div className="thread-reply">
                      <div className="inner-thread-reply  border-left">
                        <p className="font-bold reply-username">lovetorevolt:</p>
                        <p className="reply-content">A friend? He was a celebrity who had no idea you existed. He lived in a totally different world. And not to be that person, but I heard he was kind of a diva.</p>
                      </div>
                      <p className="reply-btn font-bold">... reply</p>

                      <div className="thread-reply">
                        <div className="inner-thread-reply  border-left">
                          <p className="font-bold reply-username">jakesfan444:</p>
                          <p className="reply-content">Don’t you talk bad aboutmyhusband1!!!!</p>
                        </div>
                        <p className="reply-btn font-bold">... reply</p>

                        <div className="thread-reply">
                          <div className="inner-thread-reply  border-left">
                            <p className="font-bold reply-username">lovetorevolt:</p>
                            <p className="reply-content">It’s just the truth, though. Everybody likes to talk about how singers and actors and stuff are misunderstood. But apparently the band members treated their staff super horribly. Made them do all the work while they just sat around.</p>
                          </div>
                          <p className="reply-btn font-bold">... reply</p>

                          <div className="thread-reply">
                            <div className="inner-thread-reply  border-left">
                              <p className="font-bold reply-username">Dryan:</p>
                              <p className="reply-content">thats what the staff’s job is my dude lmao! that’s what they get PAID for. if they didn’t have managers and roadies and stuff, how would they get anything done? you ever picked up those big pyro cannons? theyre freakin HEAVY! you think theyre gonna make the band do that?? stupid</p>
                            </div>
                            <p className="reply-btn font-bold">... reply</p>

                            <div className="thread-reply">
                              <div className="inner-thread-reply  border-left">
                                <p className="font-bold reply-username">lovetorevolt:</p>
                                <p className="reply-content">It wasn’t just that. I heard Jake went solo because the other guys couldn’t stand him, and he was greedy, always thinking about money. Stuff like that. Honestly, I wouldn’t be surprised if they were happy they don’t have to deal with him anymore.</p>
                              </div>
                              <p className="reply-btn font-bold">... reply</p>

                              <div className="thread-reply">
                                <div className="inner-thread-reply  border-left">
                                  <p className="font-bold reply-username">arcangel4902:</p>
                                  <p className="reply-content">Where did you hear that? That just sounds like a blatant rumor. Actually, it seems more like slander at this point.<br/><br/>@Mod: Can we get this person banned please?</p>
                                </div>
                                <p className="reply-btn font-bold">... reply</p>
                              </div>

                              <div className="thread-reply">
                                <div className="inner-thread-reply  border-left">
                                  <p className="font-bold reply-username">Dryan:</p>
                                  <p className="reply-content">damn disrespect the dudes memory much? he just died man lay off</p>
                                </div>
                                <p className="reply-btn font-bold">... reply</p>

                                <div className="thread-reply">
                                  <div className="inner-thread-reply  border-left">
                                    <p className="font-bold reply-username">lovetorevolt:</p>
                                    <p className="reply-content">I’M JUST SAYING that sometimes someone who makes good music can also be a shitty person. That’s all.</p>
                                  </div>
                                  <p className="reply-btn font-bold">... reply</p>
                                </div>
                              </div>

                            </div>

                          </div>


                        </div>



                      </div>

                    </div>

                  </div>

                </div>

                
              </div>
               <div className="thread-comment">
                  <div className="divider"></div>

                  <input placeholder="Sign In to Comment" disabled={true}/>
               </div>
               <div className="divider"></div>

            </>
        }



      </div>
    )
  }
}



export default Thread9
