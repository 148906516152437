import React, { Component } from 'react'
import Div100vh from 'react-div-100vh'
import 'react-perfect-scrollbar/dist/css/styles.css';
//import PerfectScrollbar from 'react-perfect-scrollbar'
//import './css/scrollbar.css'

import store from 'store';

import EnterEmailView from './components/inbox/EnterEmailView'


import { Player } from './models/player';

import EmailClient from './components/EmailClient/email-client';
//import ChatClient from './components/ChatClient/chat-client';

import {sendKlaviyoEvent} from './utils/klaviyo.js';
import {getCurrentSubject} from './utils/emailUtils.js';
import {nextStep} from './utils/emailUtils.js';


export default class Inbox extends Component {
    constructor(props) {
        super(props);

        //this.emailClient = React.createRef()
        this.currentSeasonIndex = this.props.currentSeason
       //this.currentEpisodeIndex = this.props.currentEpisode
       // this.subject = getCurrentSubject(this.currentSeasonIndex, this.currentEpisodeIndex)


        

        //console.log(this.subject)
        
        const userEmail = store.get("userEmail");

        //console.log(this.currentSeasonIndex)
        //console.log(this.currentEpisodeIndex)
        this.state = {
            showEmailClient: true,
            userEmail:userEmail,
            subject:getCurrentSubject(this.currentSeasonIndex, this.props.currentEpisode),
            currentEpisodeIndex:this.props.currentEpisode
        };

        //console.log(this.state)

    }

    triggerEmail = (message) => {
        //alert("Trigger inbox MOM"+message)
        this.refs.emailClient.loadBridgeEmailGroupAnswer(message)

        /*this.refs.emailClient.current.loadBotAnswer(message)*/
        

        //
    }

    closeModal = (e) => {
        this.props.tapBack()
    };


    closeEmailPopUp = (email) => {
        this.setState({ userEmail:email})
    }

    handleBotEmailResponse = (botResponse) => {

        //console.log(index)
        //this.setState({ subject: "toto" });

        const correctQuestion = botResponse.indexOf('{{CorrectQuestion}}');
        

        if (correctQuestion !== -1) {
            var currentEpisodeIndex = this.state.currentEpisodeIndex
            // Call API
            //console.log('Correct'+currentEpisodeIndex);

            var step = nextStep(this.currentSeasonIndex, currentEpisodeIndex)

            //console.log(step)


            if(step != false) {


                //sendKlaviyoEvent("HAK0"+this.currentSeasonIndex+"0"+currentEpisodeIndex,this.state.userEmail);
                
                this.setState({ currentEpisodeIndex:step})
                var newSubject = getCurrentSubject(this.currentSeasonIndex, step)
                //console.log(newSubject)

                this.refs.emailClient.updateSubject(newSubject, step);

                this.props.updateEpisode(step)


            }else {
                sendKlaviyoEvent(this.currentSeasonIndex, currentEpisodeIndex,this.state.userEmail);
            }

            var event = new CustomEvent('CHARACTERRESPONSE', {detail :  { "season":"hak0"+this.currentSeasonIndex, "episode":"0"+currentEpisodeIndex, "success": "hak0"+this.currentSeasonIndex + "0"+currentEpisodeIndex} });
            window.dispatchEvent(event); 

        } else {
            console.log('Incorrect');
        }
    }

    showEmailSentToast = () => {
        
        this.props.showInboxToast()
        
    }




    render() {
        // Render appointment list if active.
        // playerName and playerAvatar values are need to invoke client.
        const renderEmailClient = () => {
            


            if (this.state.showEmailClient ) {

                //console.log("renderEmailClient")

                var initialEmailSubject = this.state.subject;
                var arraySubject = []
                
                if(Array.isArray(this.state.subject)){
                    initialEmailSubject = this.subject[0]
                    arraySubject = this.subject
                }


                const player = new Player();
                player.email = this.state.userEmail;
                player.name = '';
                player.avatar = '';
                //const initialEmailSubject = this.subject;
                const configurationLocation = '/config/config_'+this.currentSeasonIndex+'.json';
                //console.log(configurationLocation)

                //console.log(initialEmailSubject)

                return <EmailClient player      = {player}
                    initialEmailSubject         = {initialEmailSubject}
                    configurationLocation       = {configurationLocation}
                    botEmailResponse            = {this.handleBotEmailResponse}
                    currentEpisode              = {this.state.currentEpisodeIndex}
                    emailArray                  = {arraySubject}
                    showToast                   = {this.showEmailSentToast}
                    ref                         ="emailClient"
                />;
            }
            return null;
        };



        return (
          <Div100vh  dontresize="true" className="h-full w-full flex flex-col inbox">
                <div className="modalcontainer inboxcontainer w-full h-full">
                    <div className="header">

                        <h2>Contact</h2>< button className="close" onClick={this.closeModal}/>  
                    </div>
                    <div className="HAKEmailChatContainer  w-full h-full">
                     {this.state.userEmail  !== undefined && (renderEmailClient())}
                     {this.state.userEmail  === undefined && (<EnterEmailView closeCallback={this.closeEmailPopUp.bind(this)}/>)}
                    </div>

                 </div>


            </Div100vh>
        )
    }
}
