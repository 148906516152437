import React, { Component } from 'react'

export default class Cocktails extends Component{
  render (){
    return (
    <div className='rtfdoc'>
    <p>
	    Michelle says that having a drink sometimes helps clear her head when she’s stuck on a case, so I thought I’d share the recipes for the Cadence’s two signature cocktails. The Crescendo was a drink my grandfather Ira liked to make for special occasions. The Tempo Twist is inspired by one of bartender Merrick Webster’s long-time regulars at the Grace Note bar in the 1930s. I hope they’ll be of use if you ever hit a rough patch in your investigation. And if you’re not a drinker, the Tempo Twist is still pretty good without the vodka. — Julia
	   <br/><br/>
     <u>Crescendo</u>
     <br/><br/>
      4 oz Champagne <br/>
      ½ oz absinthe<br/>
      ½ shot lime simple syrup <br/>
      Dash of bitters<br/>
      Lime wedge <br/>
<br/><br/>
      In a Champagne flute, combine Champagne and absinthe. Add lime simple syrup and bitters. Run lime wedge around rim of glass and garnish.
<br/><br/>
      <u>Tempo Twist</u>
<br/><br/>
      1 shot bison grass vodka<br/>
      3 oz grapefruit juice <br/>
      ½ oz sweetened lime juice<br/>
      Club soda<br/>
      Sprig of mint <br/>
      Grapefruit peel<br/>
<br/><br/>
      Fill a Tom Collins glass halfway with ice. Add vodka, grapefruit juice, and lime juice. Fill to top with club soda and stir. Garnish with a sprig of mint and a twist of grapefruit peel. 
    </p>

    
    </div>
  );
  }
};