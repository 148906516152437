// STORY TIME

import React, { Component } from 'react'



class Thread3 extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      extended: false
    };
  }



  toggleExpand = () =>{
    this.setState({ extended: !this.state.extended });
  }


  render() {

    return (
      <div className="thread-content">
        <div className="thread-header" onClick={this.toggleExpand}>
          <p className="thread-username"> Posted by <span className="font-bold">MarcusMyWordz:</span></p>
          <h3 className={ !this.state.extended ? "thread-title-margin" : undefined }> STORY TIME</h3>
          
        </div>


        {
            this.state.extended &&
            <>
              <div className="thread-header" onClick={this.toggleExpand} >
                  <p>Like we used to do! I’ll start<br/>
Once upon a time, Jake Morgan...</p>
              </div>
              <div className="thread-reply-container">
                <div className="thread-reply">
                  <div className="inner-thread-reply  border-left">
                    <p className="font-bold reply-username">MrsFontes24:</p>
                    <p className="reply-content">Ate a big handful of...</p>
                  </div>
                  <p className="reply-btn font-bold">... reply</p>

                  <div className="thread-reply">
                    <div className="inner-thread-reply  border-left">
                      <p className="font-bold reply-username">ollie_oop111:</p>
                      <p className="reply-content">Maraschino cherries? lol</p>
                    </div>

                    <p className="reply-btn font-bold">... reply</p>

                    <div className="thread-reply">
                      <div className="inner-thread-reply  border-left">
                        <p className="font-bold reply-username">just_funfunfunfun:</p>
                        <p className="reply-content">Pills that would make him less of an @$$h0le</p>
                      </div>

                      <p className="reply-btn font-bold">... reply</p>

                      <div className="thread-reply">
                        <div className="inner-thread-reply  border-left">
                          <p className="font-bold reply-username">schnitzelwnoodles:</p>
                          <p className="reply-content">Shut the hell up dude</p>
                        </div>

                        <p className="reply-btn font-bold">... reply</p>


                        <div className="thread-reply">
                          <div className="inner-thread-reply  border-left">
                            <p className="font-bold reply-username">just_funfunfunfun:</p>
                            <p className="reply-content">No u</p>
                          </div>

                          <p className="reply-btn font-bold">... reply</p>

                          <div className="thread-reply">
                            <div className="inner-thread-reply  border-left">
                              <p className="font-bold reply-username">ollie_oop111:</p>
                              <p className="reply-content">Sooooo mature</p>
                            </div>

                            <p className="reply-btn font-bold">... reply</p>
                          </div>

                        </div>

                      </div>

                    </div>

                  </div>
                </div>

                 <div className="thread-reply">
                    <div className="inner-thread-reply  border-left">
                      <p className="font-bold reply-username">rollercoaster420:</p>
                      <p className="reply-content">Told me he liked my shirt (this is real btw)</p>
                    </div>
                    <p className="reply-btn font-bold">... reply</p>

                    <div className="thread-reply">
                      <div className="inner-thread-reply  border-left">
                        <p className="font-bold reply-username">MarcusMyWordz:</p>
                        <p className="reply-content">For real?</p>
                      </div>
                      <p className="reply-btn font-bold">... reply</p>

                      <div className="thread-reply">
                        <div className="inner-thread-reply  border-left">
                          <p className="font-bold reply-username">rollercoaster420:</p>
                          <p className="reply-content">Yeah, when they were on Hits on Demand the first time! I was waiting outside, and he was signing autographs. Still have the shirt! But his manager was such a bitch, she was like “ok, move it along...” Like wtf this is the greatest moment of my life and you’re RUINING IT</p>
                        </div>
                        <p className="reply-btn font-bold">... reply</p>

                        <div className="thread-reply">
                          <div className="inner-thread-reply  border-left">
                            <p className="font-bold reply-username">MarcusMyWordz:</p>
                            <p className="reply-content">Ughhh what a buzzkill. But still lucky you</p>
                          </div>
                          <p className="reply-btn font-bold">... reply</p>
                        </div>


                      </div>


                    </div>


                 </div>



                 <div className="thread-reply">
                    <div className="inner-thread-reply  border-left">
                      <p className="font-bold reply-username">xxgoodbyegirlxx:</p>
                      <p className="reply-content">Left the band</p>
                    </div>
                    <p className="reply-btn font-bold">... reply</p>

                    <div className="thread-reply">
                      <div className="inner-thread-reply  border-left">
                        <p className="font-bold reply-username">Fan4Life_8:</p>
                        <p className="reply-content">She said story time, not the real (sad) truth</p>
                      </div>
                      <p className="reply-btn font-bold">... reply</p>
                    </div>

                 </div>
              </div>




               <div className="thread-comment">
                  <div className="divider"></div>

                  <input placeholder="Sign In to Comment" disabled={true}/>
               </div>
               <div className="divider"></div>

            </>
        }



      </div>
    )
  }
}



export default Thread3
