import React, { Component } from 'react'

export default class Investigationguide extends Component{



  render (){

  	if(this.props.currentSeason === 8) {
  		return (
		    <div className='rtfdoc'>
		    <p>
		Hello, Investigator.
		<br/><br/>
		Before I hand this case off to you entirely, I thought it might be helpful to give you a quick refresher course on how to go about investigating a case of this caliber. 
		<br/><br/>
		In order to eliminate certain suspects and elevate others, you’ll need to keep three key factors in mind: 
		</p>
		<ol><li>Motive: Did the suspect benefit from the murder in any way? Did they have a personal reason to commit the murder? Can their reason be proven? </li>
		<li>Means: Did the suspect have the ability to commit the murder? Did they have access to the crime scene and the murder weapon?</li>
		<li>Opportunity: Could the suspect have comitted the murder during the known timeframe of the crime? Were they somewhere close to the the crime scene at the time of the murder? Can you disprove their alibi?</li>
		</ol>
		<p>
		If the answer to one or more of these questions is “no,” and there’s definitive evidence to support your claim, a suspect can be eliminated. 
		<br/><br/>
		On the flip side, proving that a suspect had all three of these factors is, for our purposes, enough proof to get the police involved.
		<br/><br/>
		My razor-sharp deductive skills are telling me what you must be thinking now: “That’s all well and good, Michelle, but how am I supposed to find all of those things in the first place?”
		<br/><br/>
		I’m glad you asked!  Here are some tips, tricks, and strategies to keep in mind as you conduct your investigation: 
		</p>
		<ol><li>Remember to consistently use all of the tools at your disposal. A magnifying glass, for example, would likely come in handy more than once over the course of the investigation. </li>
		<li>Examine every piece of evidence very carefully. You never know when you’ll find a note written somewhere unexpected, or when an innocuous-seeming phrase will be the key to confirming an alibi. </li>
		<li>Always keep an eye on the big picture, even when you’re combing through the small details. If something isn’t lining up right, try to cross-reference it with other pieces of evidence. </li>
		<li>Always take notes on your findings. Any piece of information, no matter how inconsequential it may seem at first, could prove crucial in cracking the case wide open. </li>
		<li>Hold on to every piece of evidence that comes your way. Evidence collected at the very beginning of your investigation could gain new significance in light of new discoveries. </li>
		</ol>
		<p>
		Finally: You should always follow two things above all else—the evidence and your gut. 
		<br/><br/>
		Good luck, <br/>
		Michelle Gray<br/>
		Private Investigator 
		</p>

		    
		    </div>
		  );
  	} else {
  		return (
		    <div className='rtfdoc'>
			<p>Hello,<br/><br/>
I thought it might be helpful to give you a quick refresher course on how to go about
investigating a case of this caliber.<br/><br/>
<b>Investigative Best Practices</b><br/>Here are some tips, tricks, and strategies to keep in mind as you conduct your investigation:</p>
<ol>
		<li>Remember to consistently use all of the tools at your disposal. </li>
		<li>Examine every piece of evidence very carefully. You never know when you’ll find a note written somewhere unexpected, or when an innocuous-seeming phrase will be the key to confirming an alibi.  </li>
		<li>Always keep an eye on the big picture, even when you’re combing through the small details. If something isn’t lining up right, try to cross-reference it with other pieces of evidence.</li>
		<li>Always take notes on your findings. Any piece of information, no matter how inconsequential it may seem at first, could prove crucial in cracking the case wide open.</li>
		<li>Hold on to every piece of evidence that comes your way. Evidence collected at the very beginning of your investigation could gain new significance in light of new discoveries.</li>

		</ol>
		<p><b>Suspects</b><br/>In order to eliminate certain suspects and elevate others, you’ll need to keep three key factors in mind: </p>
		<ol>
			<li>Motive: Did the suspect benefit from the murder in any way? Did they have a personal reason to commit the murder? Can their reason be proven?</li>
			<li>Means: Did the suspect have the ability to commit the murder? Did they have access to the crime scene and the murder weapon?</li>
			<li>Opportunity: Could the suspect have comitted the murder during the known timeframe of the crime? Were they somewhere close to the crime scene at the time of the murder? Can you disprove their alibi?</li>
		</ol>
		<p>If the answer to one or more of these questions is “no,” and there’s definitive evidence to support your claim, a suspect can be eliminated. <br/><br/>On the flip side, proving that a suspect had all three of these factors is, for our purposes, enough proof to get the police involved.
		<br/><br/>
		<b>Codes and Ciphers</b><br/>
During the course of your investigation, you might come across a few codes and ciphers. People with things to hide tend not to leave their secrets sitting out in the open unprotected. To prevent these encoding efforts from halting an investigation, I’ve created this list to help crack some of the most common cipher types. 
<br/><br/>
<b>Abjad:</b><br/><br/>
This is less a form of cipher and more a system of writing, but I’ve seen it used to encode information plenty of times in the past. In simple terms, it’s the omission of vowels from the text. Sometimes the writer will replace the vowels with a neutral symbol.
<br/><br/>
If you come across a word written in abjad that could be read several different ways depending on the missing vowels, try looking at the big picture. Context clues can be a lifesaver when you’re trying to decipher messages written in this format. 
<br/><br/>
Written in abjad, the word example would look like this: </p>
<ul>
<li>xmpl</li>
<li>-x-mpl-</li>
</ul>

<p><b>Atbash Cipher:</b><br/><br/>In an Atbash cipher, the alphabet is mapped onto itself backwards. 
<br/><br/>
Here’s a chart that shows what I mean:</p>
<img src="https://cdn.huntakiller.com/huntakiller/s7/cipherguide-atbashtable1.svg" alt="cipherguide-atbashtable1"/>
<img src="https://cdn.huntakiller.com/huntakiller/s7/cipherguide-atbashtable2.svg"  alt="cipherguide-atbashtable2"/>
<p><br/>In an Atbash cipher, the word example would be: vcznkov. </p>
<p><b>Book Cipher:</b><br/><br/>
A book cipher needs to be used in conjunction with a pretty large section of text. This text might be found on a separate piece of paper from the code itself <b>or</b> on the same page. 
<br/><br/>
The cipher tells the reader what words or letters <b>within</b> a passage to look at, and in what order, to find a hidden message. 
<br/><br/>
The text can be anything from a letter to the page of a book to a shopping list, so be vigilant! 
<br/><br/>
To decode a book cipher, be on the lookout for anything that might indicate on what line of a page, what word in a line, or what letter in a word you should focus.
<br/><br/>
If I wanted to call your attention to the word example in the following text, I could do it by providing you with the location of the word itself with this key: (2/7).

</p>
<ul>
<li>He was exacting in his standards and minimal in his praise.</li>
<li>He always strove to lead by example. </li>
</ul>
<p>The key (2/7) indicates the location of the hidden word. The word example is on the second line. It is the seventh word of that line. 
<br/><br/>
I could scatter the information even further by providing you with something like this, which gives you the necessary information in the format of <b>line/word/letter</b>:
<br/><br/>
1/1/2 1/3/2 1/6/3 1/8/1 1/11/1 2/5/1 2/7/7
</p>
<ul>
<li>H<b>e</b> was e<b>x</b>acting in his st<b>a</b>ndards and <b>m</b>inimal in his <b>p</b>raise.</li>
<li>He always strove to <b>l</b>ead by exampl<b>e</b>.</li>
</ul>
<p>When read in order, the letters indicated by the key spell out the word example.</p>
<p><b>Caesar Shift Cipher: </b><br/><br/>

This cipher is named based on the belief that Julius Caesar used it to encrypt his personal communications. To encode a sentence using a shift cipher, the writer shifts each letter of the alphabet forward by a certain fixed number. 
<br/><br/>
For example, the top row of the following chart is the regular, un-encoded alphabet. The bottom row is the alphabet shifted forward by five letters. 
</p>
<img src="https://cdn.huntakiller.com/huntakiller/s7/cipherguide-shifttable1.svg" alt="cipherguide-shifttable1"/>
<img src="https://cdn.huntakiller.com/huntakiller/s7/cipherguide-shifttable2.svg" alt="cipherguide-shifttable2"/>
<p>The word <i>example</i> shifted forward by five letters is jcfruqj. 
<br/><br/>
To decode jcfruqj, you must shift each individual letter backward by five places in the alphabet. 
<br/><br/>
The alphabet can be shifted by any number, although shifting by numbers greater than 26 can get a bit redundant (since, of course, the English alphabet only has 26 letters). In other words, shifting by 27 would be the same as shifting by one, shifting by 28 would be the same as shifting by two, and so on. 
<br/><br/>
Remember: there are plenty of ways to add complexity to a shift cipher. Because of this, not all shift ciphers will appear to be straightforward. Here are a few examples of potential complications you might encounter when decoding shift ciphers:
 </p>	
	<ul>
<li>The shift is only used on parts of the message. Perhaps every second word is shifted, while the other words in a sentence are left alone. This can also be done by letter. For example, every third letter might be shifted, while the rest of the message is left alone.</li>
<li>More than one shift is used within the same message. For example, one sentence in a message could be shifted by 12, while the next sentence is shifted by seven. On a more difficult level, this could even occur within the words themselves. Perhaps every even letter is shifted by two, while every odd letter is shifted by four. </li>
<li>The shifted word itself is spelled backwards or scrambled in some other manner.</li>
<li>On the most difficult level, you might encounter a cipher that is layered over another cipher. In this case, you might decode a phrase into a nonsensical answer and then have to decipher it again by some additional method that is discovered in a separate place from the original key.</li>
<li>To find the key to a shift cipher, look around for numbers that seem like they've been highlighted in some way or numbers that might be relevant to the cipher's writer. For example, you might find an underlined digit or a birthday. The number might be explicitly written out, or it might be hidden as part of an image or symbol. The clue might be in a painting that has five birds or twelve trees, telling you the shift number is five or twelve respectively. If you can't find a key, you can always try to "brute force" a cipher by running it through an online decryption service.
</li>
<li>A common variant of a Caesar shift is the date cipher. A date cipher uses a repeating number (usually a date) as the key to the shift. To encode a message with a date cipher, the numbers of the date are written above the message, with each letter being assigned one number from the date. If the message is longer than the date number, the date number is repeated as many times as necessary to reach the end of the message. For example, let’s say our message to encode is “This is a date cipher.” And the date to be used is December 25, 2001. The shift number is then 12252001. So we would shift “T” by 1, “h” by 2, “i” by 2, “s” by 5, and so on. When we reach the end of the date (shifting “d” by 1) we simply start the number over again and repeat the process (shifting “a” by 1, “t” by 2, etc.) Our final encoded message is then: Ujkx ks a ebvg hkphfs. A full date is not needed to use a date cipher. It can be only a day and month, or just a year, or even a string of numbers that isn’t actually a date at all but uses the same encoding process. </li>
 </ul>	 
<p>
<b>Keypad Cipher:</b><br/><br/>
The basic idea of this cipher is to assign all of the letters of the alphabet to specific places on a grid like this one: </p>
<img src="https://cdn.huntakiller.com/huntakiller/s7/cipherguide-keypadtable.svg"  alt="cipherguide-keypadtable"/>
<p>You might notice that it looks like the keypad of a telephone. While this example organizes the alphabet onto a 3x3 grid reminiscent of a phone, the alphabet can be spread across grids of any organization: 5x2, 3x6, and so on.
<br/><br/>
Once the writer has assigned letters to places on the grid, there must be some kind of shorthand to refer to the physical position of each letter on the grid. 
<br/><br/>
Keep in mind that these grids don’t necessarily have to start with A in the upper right hand corner. You may need to determine how the alphabet is supposed to fall on the grid before you can start decoding. 
<br/><br/>
To decode a keypad cipher, look for notes that might indicate the position of a specific letter. 
<br/><br/>
For example, since A is the first letter in the box that would be mapped onto the number 1 on a typical keypad (the model for my grid above), one way to denote its position would be 1-1. By the same logic, E would be 2-2, since it is the second letter in the second box.
<br/><br/>
The word example spelled out this way is therefore: 2-2 8-3 1-1 5-1 6-1 4-3 2-2.</p>
<p>
<b>Reverse Acronym:</b><br/><br/>
This one is a great way to hide short communications in plain sight. It uses one of the following to spell out a hidden message:</p>
<ul>
<li>all of the capital letters in a sentence</li>
<li>all of the first letters in a line</li>
<li>all of the first letters of each sentence</li>
</ul>
<p>
See if you can find the word example in the following text:
<br/><br/>
Everyone in the hospital knows the unlucky patient. X-ray technicians, nurses, doctors, and custodial staff see her all the time. All the infamy comes from the way her terrible luck plays out. Most people get injured every now and then, but the unlucky patient’s clumsiness is on another level. Pain and suffering seem to follow wherever she goes. Layers of bandages wind up and down her limbs. Everything bad that could possibly happen to someone has happened to her. 
<br/><br/>
See what I did there? Let me bold the letters that make up the word <i>example</i>: 
<br/><br/>
<b>E</b>veryone in the hospital knows the unlucky patient. <b>X</b>-ray technicians, nurses, doctors, and custodial staff see her all the time. <b>A</b>ll the infamy comes from the way her terrible luck plays out. <b>M</b>ost people get injured every now and then, but the unlucky patient’s clumsiness is on another level. <b>P</b>ain and suffering seem to follow wherever she goes. <b>L</b>ayers of bandages wind up and down her limbs. <b>E</b>verything bad that could possibly happen to someone has happened to her. 
<br/><br/>
It could also be done like this, using capitalized proper nouns: 
<br/><br/>
<b>E</b>dward <b>X</b>avier didn’t want an <b>A</b>pple computer, but <b>M</b>ary was planning on getting him one anyway. <b>P</b>leasing him was impossible, after all. <b>L</b>ucy and <b>E</b>leanor appreciated her efforts, but not he. 
</p>
<p><b>Symbol Substitution Cipher:</b><br/><br/>
A symbol substitution cipher uses symbols to represent different letters. The most common version of this cipher uses a one to one relationship, where one letter is represented by one symbol. To solve this type of cipher, look for short sections of symbols, usually 1-3 symbols long. Then try plugging in common words to see if you can determine if there are any likely symbol-letter pairs. This method requires a good bit of trial and error, but the code should reveal itself eventually. Sometimes there are keys or patterns to how the symbols are assigned, and if you are able to figure out that pattern, you will not have to use trial and error as much. If there seems to be a unifying theme or order to the symbols, that is a good sign that there is a key that can be used to make cracking the code more straightforward than simply guessing and checking.
<br/><br/>
For further information, you can check out these websites: <br/><br/>
<a href="http://practicalcryptography.com/ciphers/caesar-cipher/" target="_blank" rel="noopener noreferrer">http://practicalcryptography.com/ciphers/caesar-cipher/</a><br/>
<a href="https://cryptii.com/" target="_blank" rel="noopener noreferrer">https://cryptii.com/</a><br/><br/>
Good luck, <br/><br/>Michelle

</p>
		</div>
		  );
  	}

    
  }
};

