//Did anyone else get into singing because of J4F?

import React, { Component } from 'react'
import ReactAudioPlayer from 'react-audio-player';


class Thread7 extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      extended: false
    };
  }



  toggleExpand = () =>{
    this.setState({ extended: !this.state.extended });
  }


  render() {

    return (
      <div className="thread-content">
        <div className="thread-header" onClick={this.toggleExpand}>
          <p className="thread-username"> Posted by <span className="font-bold">SashaCat: </span></p>
          <h3 className={ !this.state.extended ? "thread-title-margin" : undefined }>Did anyone else get into singing because of J4F?</h3>

        </div>


        {
            this.state.extended &&
            <>
              <div className="thread-header"  onClick={this.toggleExpand}>
                  <p>I was in my preteens when J4F was big, and they really inspired me. I used to practice on my own since I couldn’t afford lessons, and it’s not like internet tutorials were a common thing back then... Anyone know how the guys used to train or what their vocal techniques were? Maybe it was the power of autotune, but their voices always seemed so pristine. Ugh.</p>
              </div>
              <div className="thread-reply-container">
                <div className="thread-reply">
                  <div className="inner-thread-reply  border-left">
                    <p className="font-bold reply-username">Lust4fun:</p>
                    <p className="reply-content">Autotune?? Blasphemy!!</p>
                  </div>
                  <p className="reply-btn font-bold">... reply</p>

                  <div className="thread-reply">
                    <div className="inner-thread-reply  border-left">
                      <p className="font-bold reply-username">just_funfunfunfun:</p>
                      <p className="reply-content">You know they did lmao let’s not be delusional here</p>
                    </div>

                    <p className="reply-btn font-bold">... reply</p>
                  </div>
                </div>

                 <div className="thread-reply">
                    <div className="inner-thread-reply  border-left">
                      <p className="font-bold reply-username">SereneTiger300:</p>
                      <p className="reply-content">I heard Star Sign hired the BEST people to train them, like top-of-the-line coaches and choreographers who won awards and stuff. That’s probably how the guys got so good. Plus, they all had natural talent.</p>
                    </div>
                    <p className="reply-btn font-bold">... reply</p>

                    <div className="thread-reply">
                      <div className="inner-thread-reply  border-left">
                        <p className="font-bold reply-username">ollie_oop111:</p>
                        <p className="reply-content">Ollie was the only good dancer though. Did their choreographer really even train the other guys??</p>
                      </div>
                      <p className="reply-btn font-bold">... reply</p>

                      <div className="thread-reply">
                        <div className="inner-thread-reply  border-left">
                          <p className="font-bold reply-username">SereneTiger300:</p>
                          <p className="reply-content">I mean, yeah their choreo wasn’t all that, but it was better than most of the boy bands at the time.</p>
                        </div>
                        <p className="reply-btn font-bold">... reply</p>
                      </div>

                    </div>

                    <div className="thread-reply">
                      <div className="inner-thread-reply  border-left">
                        <p className="font-bold reply-username">xoxo_inthesun:</p>
                        <p className="reply-content">Oh yeah, there was that rumor that their vocal coach was like a former opera singer who taught them some crazy breathing technique. Idk about all that</p>
                      </div>
                      <p className="reply-btn font-bold">... reply</p>

                      <div className="thread-reply">
                        <div className="inner-thread-reply  border-left">
                          <p className="font-bold reply-username">MarcusMyWordz:</p>
                          <p className="reply-content">Their coach is just a local guy who used to sing radio jingles though?</p>
                        </div>
                        <p className="reply-btn font-bold">... reply</p>

                        <div className="thread-reply">
                          <div className="inner-thread-reply  border-left">
                            <p className="font-bold reply-username">xxgoodbyegirlxx:</p>
                            <p className="reply-content">Whaaaaaat</p>
                          </div>
                          <p className="reply-btn font-bold">... reply</p>

                          <div className="thread-reply">
                            <div className="inner-thread-reply  border-left">
                              <p className="font-bold reply-username">MarcusMyWordz:</p>
                              <p className="reply-content">Yeah, I looked it up, and he had posted his demo reel online way back when:</p>

                              <div className="forum-audio-player">
                                <h5>Dean Castillo - ShopWay:</h5>
                                <ReactAudioPlayer title="Dean Castillo - ShopWay"  key="ShopWay" controlsList="nodownload" src="https://cdn.huntakiller.com/huntakiller/s8/audio/save-save-save-shopway.mp3"  controls/>
                              </div>
                              <div className="forum-audio-player">
                                <h5>Dean Castillo - Quick Burger:</h5>
                                <ReactAudioPlayer title="Dean Castillo - Quick Burger"  key="Quick Burger" controlsList="nodownload" src="https://cdn.huntakiller.com/huntakiller/s8/audio/sweet-bacon-cheddar-bites-quick-burger.mp3"  controls />
                              </div>
                              <div className="forum-audio-player">
                                <h5>Dean Castillo - Fraser’s Scottish Pub:</h5>
                                <ReactAudioPlayer title="Fraser’s Scottish Pub" key="Fraser’s Scottish Pub" controlsList="nodownload" src="https://cdn.huntakiller.com/huntakiller/s8/audio/auld-lang-syne-frasers-scottish-pub.mp3"  controls />
                              </div>
                              

                            </div>
                            <p className="reply-btn font-bold">... reply</p>

                            <div className="thread-reply">
                              <div className="inner-thread-reply  border-left">
                                <p className="font-bold reply-username">xxgoodbyegirlxx:</p>
                                <p className="reply-content">O shit lol</p>
                                
                              </div>
                              <p className="reply-btn font-bold">... reply</p>
                            </div>

                            <div className="thread-reply">
                              <div className="inner-thread-reply  border-left">
                                <p className="font-bold reply-username">SuperLavender:</p>
                                <p className="reply-content">I remember hearing these! I grew up around Santa Lucinda</p>
                                
                              </div>
                              <p className="reply-btn font-bold">... reply</p>
                            </div>

                          </div>

                        </div>

                      </div>



                    </div>


                 </div>

                 
              </div>
               <div className="thread-comment">
                  <div className="divider"></div>

                  <input placeholder="Sign In to Comment" disabled={true}/>
               </div>
               <div className="divider"></div>

            </>
        }



      </div>
    )
  }
}



export default Thread7
