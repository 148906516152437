import React, { Component } from 'react'

export default class Transcriptcodedletter extends Component{
  render (){
    return (
    	<div className='rtfdoc'>
    <p>
	  November 24th, 1933
<br/><br/>
Wziormt,
<br/><br/>
I’m taking precautions in case Nlgsvi finds this letter, which is why a few phrases might seem a bit backwards to you. If you find yourself at a loss, look to the gift I embroidered you for guidance. When Vanity becomes Elegance, the truth can emerge. 
<br/><br/>
The walls in the Cadence tend to have ears, so I don’t feel secure asking you this in person. But I was wondering if Ifgs has said anything to you about me lately. She pulled me aside earlier today and told me in no uncertain terms that she wished I had mvevi yvvm xzhg rm Wfl. I asked her what she meant, but she just said that I should be ashamed of myself and stormed away. I genuinely have no idea what any of that was about. Have I done something to offend her? Is it the stress of her pregnancy making itself manifest? 
<br/><br/>
This is the worst possible time for me to have made an enemy. It’s simply too much. With the show, the press, and the wedding to contend with, I barely have any time to breathe. I can count on one hand the number of times I’ve felt neither hungry nor tired nor miserable over the past few months, and during vevib hrmtov lmv lu gsvn R’ev yvvm drgs blf. 
<br/><br/>
There’s no sign of any real reprieve on the horizon, though. Nlgsvi has my life planned down to the matching outfits I’m wearing with Svxgli to our hypothetical uron kivnrvivh in ‘38. It’s a selfish thing to complain about, I know. But I can’t stop thinking about what you said the other day, about being able to step back and see the big picture. It made me realize that I’ve spent my entire life like a carriage horse fitted with blinders. I’ve never seen anything but the path I’m being driven down. I can’t go on like this forever. R mvvw gl tvg lfg lu gsrh xrgb. Yfg nb xlmgizxg zmw nb vmtztvnvmg nzpv gszg rnklhhryov. 
<br/><br/>
Thank you for being such a good confidante over these past few months. You’ve made me realize how good it can feel to nzpv z xslrxv lu nb ldm every now and again.  
<br/><br/>
I’ve asked Svxgli to go over ivxvkgrlm kozmh with Nlgsvi at six o’clock tomorrow. We can nvvg rm nb wivhhrmt illn dsrov gsvb’iv yfhb. 
<br/><br/>
Hvv blf hllm,<br/>
Erloz

    </p>

    
    </div>
    
  );
  }
};