import React, { Component } from 'react'

export default class Forensics extends Component{
  render (){
    return (
    <div className='rtfdoc'>
    <p>
	    <b>ABRASION</b><br/>
	    Superficial damage to the skin.<br/><br/>
	    <b>ADRENAL</b><br/>
	    Of or relating to the glands situated above the kidneys.<br/><br/>
	    <b>ANEURYSM</b><br/>
A blood-filled bulge in an artery. Bursting can cause death.<br/><br/>

<b>ANIMAL SCAVENGING </b><br/>
Interference to a dead body by an animal or animals.<br/><br/>

<b>ANTEMORTEM</b><br/>
Occurring before death.<br/><br/>

<b>ANTERIOR</b><br/>
Anatomical position meaning in front.<br/><br/>

<b>AORTA</b><br/>
Largest artery in the body.<br/><br/>

<b>ATHEROSCLEROSIS</b><br/>
Plaque buildup in and on artery walls.<br/><br/>

<b>AUTOPSY </b><br/>
Scientific examination of a dead body. <br/><br/>

<b>BIBASILAR ATELECTASIS</b><br/>
Partial lung collapse.<br/><br/>

<b>BLUNT FORCE TRAUMA </b><br/> 
Damage to a body sustained by a forceful impact, oftentimes with a blunt weapon.<br/><br/>

<b>BROW RIDGE </b><br/>
In humans, the ridge of bone above the eye sockets. <br/><br/>

<b>CAT SCAN </b><br/>
Computerized Axial Tomography scan. Produces a cross-sectional image of the inside of a body. <br/><br/>

<b>CEREBRAL</b><br/>
Of or relating to the head or brain.<br/><br/>

<b>CERVICAL</b><br/>
Of or relating to the neck or cervix.<br/><br/>

<b>CONGENITAL</b><br/>
Trait present since birth.<br/><br/>

<b>CONJUNCTIVAL</b><br/>
Of or relating to the eye.<br/><br/>

<b>CONTUSION</b><br/>
Bruise.<br/><br/>

<b>CORONAL SUTURE </b><br/>
Line in the skull that separates its front and back portions.<br/><br/> 

<b>CRANIUM </b><br/>
The skull that encloses the brain. <br/><br/>

<b>CUTANEOUS</b><br/>
Of or relating to the skin.<br/><br/>

<b>DEGRADATION </b><br/>
The act or process of wearing down.<br/><br/> 

<b>DEFENSIVE WOUND </b><br/>
Injury sustained by a victim while trying to ward off an assailant. Often found on the hands and arms. <br/><br/>

<b>DEPRESSED FRACTURE </b><br/>
A type of fracture, particularly of the skull, that causes the bone to cave inward.<br/><br/> 

<b>DISCOLORATION </b><br/>
A change in color, particularly of a body part.<br/><br/> 

<b>DISTAL</b><br/>
Situated away from the center of the body or the point of attachment.<br/><br/>

<b>DURA MATER</b><br/>
Thick membrane around the brain and spinal cord.<br/><br/>

<b>EDEMA</b><br/>
Swelling, either from injury or inflammation, caused by excess fluid in the body’s tissues.<br/><br/>

<b>FASCIA</b><br/>
Connective tissue that covers and binds together muscles.<br/><br/>

<b>FOREIGN BODY</b><br/>
Something lodged in the body that did not organically grow from it.<br/><br/> 

<b>FORENSIC ANTHROPOLOGY </b><br/>
The analysis of skeletal remains, often for the purpose of solving a criminal case.<br/><br/> 

<b>FRACTURE</b><br/>
A break in the bone.<br/><br/> 

<b>FRONTAL BONE</b><br/>
Bowl-shaped portion of the skull. Located in the forehead region.<br/><br/> 

<b>FRONTAL LOBE </b><br/>
Portion of the brain located toward the front of the skull and responsible for cognitive skills in humans. <br/><br/>

<b>GREENSTICK FRACTURE </b><br/>
When a bone bends and cracks, as opposed to a total break in the bone. Often occurs in children.<br/><br/> 

<b>HEMORRHAGE</b><br/>
Heavy discharge of blood from a ruptured blood vessel.<br/><br/>

<b>HEMOTHORAX</b><br/>
Collection of blood in the space between the chest wall and the lung.<br/><br/>

<b>HERNIA</b><br/>
Abnormal exit of an organ or tissue through a weakness in the peritoneum.<br/><br/>

<b>INCISION</b><br/>
Clean cut in the skin, often surgical.<br/><br/>

<b>INFERIOR</b><br/>
Anatomical position meaning below.<br/><br/>

<b>INTERCOSTAL</b><br/>
Situated or extending between the ribs.<br/><br/>

<b>JUGULAR</b><br/>
Of or relating to the throat or neck.<br/><br/>

<b>KASTLE-MEYER TEST</b><br/>
Chemical test used to detect the presence of blood on solid surfaces for scientific purposes.<br/><br/>

<b>LARYNX</b><br/>
Upper portion of the trachea, where the vocal cords are situated.<br/><br/>

<b>LATERAL</b><br/>
Away from the midline of the body or a part of the body.<br/><br/>

<b>LIGAMENT </b><br/>
Tissue that connects bones, holds joints together, and supports organs.<br/><br/>

<b>LINEAR FRACTURE </b><br/>
Break that resembles a thin, clean line. <br/><br/>

<b>LIVIDITY</b><br/>
Red or purple discoloration of the skin due to livor mortis.<br/><br/>

<b>LIVOR MORTIS</b><br/>
Settling of blood in the lower or dependent portion of the body after death. Can be used to determine the body’s position at the time of death.<br/><br/>

<b>LUNATE </b><br/>
Crescent-shaped bone, located in the center of the wrist. <br/><br/>

<b>MANDIBLE </b><br/>
The jawbone.<br/><br/> 

<b>MASTOID PROCESS</b><br/>
Cone-shaped formation of bone at the lower portion of the skull to which the neck muscles are attached.<br/><br/>

<b>MEDIAL</b><br/>
Toward the midline of the body or a part of the body.<br/><br/>

<b>MORPHOLOGY </b><br/>
The overall form and structure of a living object. <br/><br/>

<b>MRI SCAN</b><br/>
Magnetic Resonance Imaging scan. Produces an internal image of the body using magnetic fields and radio waves. <br/><br/>

<b>MUMMIFICATION </b><br/>
The intentional or unintentional preservation of a corpse’s skin and flesh through a number of techniques and factors.<br/><br/> 

<b>NASAL APERTURE </b><br/>
Pear- or heart-shaped opening in the front of the skull, to which the cartilage of the nose is attached.<br/><br/>

<b>ORBITAL STRUCTURE </b><br/>
The opening in the front of the skull that houses the eye, comprised of seven conjoined bones. <br/><br/>

<b>OSTEOLOGICAL</b><br/>
Of or relating to osteology, pertaining to bones.<br/><br/>

<b>PATHOLOGY </b><br/>
The causes and effects of diseases and injuries, and the study thereof.<br/><br/>

<b>PELVIS </b><br/>
The lower part of the human torso, located between the abdomen and thighs. <br/><br/>

<b>PELVIC LIGAMENT </b><br/>
Ligament connecting bones and tissues in and around the pelvis.<br/><br/>

<b>PERFORATION</b><br/>
Hole made by boring or piercing.<br/><br/>

<b>PERIADRENAL </b><br/>
Surrounding the adrenal glands.<br/><br/>

<b>PERIMORTEM </b><br/>
Taking place near or around the time of death, often directly before. <br/><br/>

<b>PERIOSTEUM </b><br/>
A layer of connective tissue that covers the bones.<br/><br/> 

<b>PERITONEUM</b><br/>
Membrane lining the abdominal cavity.<br/><br/>

<b>PETECHIAE</b><br/>
Red or purple spots on the skin due to broken capillary vessels. <br/><br/>

<b>PHALANGES </b><br/>
Bones in the fingers or toes.<br/><br/>

<b>PHYSIOLOGY </b><br/>
The organic processes and inner workings of an organism.<br/><br/> 

<b>POSTERIOR</b><br/>
Anatomical position meaning in back of or behind.<br/><br/>

<b>POSTMORTEM</b><br/>
Occurring after death.<br/><br/>

<b>PROXIMAL </b><br/>
Nearer to the center of the body or the point of a limb’s attachment to the body. <br/><br/>

<b>PULMONARY</b><br/>
Of or relating to the lungs.<br/><br/>

<b>RADIUS </b><br/>
Bone located on the thumb-side of the forearm. <br/><br/>

<b>RETROPERITONEAL</b><br/>
Situated or occurring behind the peritoneum.<br/><br/>

<b>RIGOR MORTIS</b><br/>
Stiffening of the joints and muscles of the body after death.<br/><br/>

<b>SACRAL FORAMINA</b><br/>
Any of the sixteen openings in the sacrum, in which nerves are located.<br/><br/> 

<b>SACROTUBEROUS LIGAMENT</b><br/>
A fan-shaped ligament located in the posterior pelvis. <br/><br/>

<b>SACRUM</b><br/>
Part of the spinal column that is directly connected with or forms part of the pelvis.<br/><br/> 

<b>SAGITTAL PLANE</b><br/>
Anatomical boundary separating the left and right sides of the body.<br/><br/> 

<b>SCIATIC NOTCH </b><br/>
Notch-shaped formation of the posterior pelvis. <br/><br/>

<b>SCAPHOID </b><br/>
Small bone in the wrist.<br/><br/>
 <b>SERRATION</b><br/>
Jagged edge or saw-like formation.<br/><br/>

<b>SHARP FORCE TRAUMA </b><br/>
Well-defined traumatic separation of tissues, occurring when a sharp or pointed object comes into contact with the skin and underlying tissue.<br/><br/>

<b>SPONTANEOUS MUMMIFICATION </b><br/>
Naturally occurring mummification. Can take place over the course of several weeks. <br/><br/>

<b>STERNOCLEIDOMASTOID</b><br/>
Largest and most superficial neck muscle, works to bend, rotate, flex, and extend the head.<br/><br/>

<b>SUBCUTANEOUS TISSUE</b><br/>
Innermost layer of skin consisting of loose connective tissue and fat, which contains large blood vessels and nerves.<br/><br/>

<b>SUBPUBIC CONCAVITY </b><br/>
Inward-curving formation at the bottom of the pelvis. <br/><br/>

<b>SUPERFICIAL</b><br/>
Of, relating to, or located near the outer surface.<br/><br/>

<b>SUPERIOR</b><br/>
Anatomical position meaning above.<br/><br/>

<b>THERMAL RECONSTITUTION </b><br/>
Using heat to stabilize or clarify in reconstruction.<br/><br/>

<b>THYROID</b><br/>
Gland in the anterior base of the neck that produces hormones.<br/><br/>

<b>TRACHEA</b><br/>
Hollow muscular organ forming an air passage to the lungs.<br/><br/>

<b>TRANSECT</b><br/>
To cut across.<br/><br/>

<b>TUFT FRACTURE </b><br/>
Break to the tip of the finger.<br/><br/>

<b>ULNA</b><br/>
The largest forearm bone, found on the little-finger side of the arm.<br/><br/>

<b>ULNAR</b><br/>
Of or relating to the ulna.<br/><br/>

<b>VENTRAL ARC </b><br/>
Curved ridge of the anterior pelvis. Commonly found in females, very rare in males. <br/><br/>

<b>X-RAY</b><br/>
Radiation-based imaging of the interior of the body.<br/><br/> 

	</p>
    
    </div>
  );
  }
};