import React, { Component } from 'react'

export default class Transcripcodedletter2 extends Component{
  render (){
    return (
    	<div className='rtfdoc'>
    <p>
February 22nd, 1934 
<br/><br/>
ilgxvS,
<br/><br/>
vD g’mzx gvt wvriizn, ilgxvS. n’R tmroozx uul vsg gmvnvtztmv. 
<br/><br/>
When I agreed to biizn flb, I didn’t understand what I was saying yes to. I’m not referring to anything about you as a person when I say that. What I mean is that I’d never been mr velo before. 
<br/><br/>
tmroozU mr velo destroyed my interest in our arrangement. And soon afterwards, our arrangement destroyed that velo. 
<br/><br/>
You know how a certain someone has always referred to himself as not the tmrbiizn type? He insisted he couldn’t let himself get too wvghvemr mr vn because of it. I hoped that his feelings would change. But when they did, it wasn’t in the way I wanted. 
<br/><br/>
It hurts so much to see him tmreln ml lg z oirt vs mzx boozfgxz wmvkh hrs vuro sgrd. I can’t blame either of them, though. It’s my own fault for not doing this sooner. 
<br/><br/>
The next time I wmru velo, I want to come by it honestly. With no need to sneak around. 
<br/><br/>
I know that’s a cruel thing to say to you of all people. But I’m not as strong as you. Not in that way. 
<br/><br/>
You’ve never been anything but kind and considerate toward me, ilgxvS. You deserve the world on a string. I hope someday we’ll find ourselves dining at the Waldorf once again, this time niz mr niz sgrd ifl vergxvkhvi hivelo. 
<br/><br/>
We should speak more about this in person, but please know that nothing you can say will change my mind. 
<br/><br/>
You’re destined for great things, ilgxvS. You don’t need a vhozu vxmznli to sell yourself to the public. And neither do I. 
<br/><br/>
iflB ozmlrhzxxl vmvxh ivmgizk wmz ozmivgv wmvriu, <br/>
zolrE<br/>
<br/><br/>
P.S. - If I could ask one favor - and I know how presumptuous it is to ask any favors of you just now - it would be that we deliver this news to ivsglN together. She’s always been good at tmrkvvp ivs ivknvg mr pxvsx around you.  

    </p>

    
    </div>
    
  );
  }
};