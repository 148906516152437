//DOES ANYONE HAVE...

import React, { Component } from 'react'



class Thread10 extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      extended: false
    };
  }



  toggleExpand = () =>{
    this.setState({ extended: !this.state.extended });
  }


  render() {

    return (
      <div className="thread-content">
        <div className="thread-header" onClick={this.toggleExpand}>
          <p className="thread-username"> Posted by <span className="font-bold">xoxo_inthesun: </span></p>
          <h3 className={ !this.state.extended ? "thread-title-margin" : "" }>DOES ANYONE HAVE...</h3>

        </div>


        {
            this.state.extended &&
            <>
              <div className="thread-header" onClick={this.toggleExpand} >
                  <p>gabe’s livestream vid from the day jake died? it expired from his page but im hoping someone saved or recorded it...</p>
              </div>
              <div className="thread-reply-container">
                <div className="thread-reply">
                  <div className="inner-thread-reply  border-left">
                    <p className="font-bold reply-username">arcangel4902:</p>
                    <p className="reply-content">omg he was livestreaming?</p>
                  </div>
                  <p className="reply-btn font-bold">... reply</p>

                  <div className="thread-reply">
                    <div className="inner-thread-reply  border-left">
                      <p className="font-bold reply-username">Honey_Arcade:</p>
                      <p className="reply-content">Yeah, I think it was before It Happened or something.</p>
                    </div>

                    <p className="reply-btn font-bold">... reply</p>

                    <div className="thread-reply">
                      <div className="inner-thread-reply  border-left">
                        <p className="font-bold reply-username">just_funfunfunfun:</p>
                        <p className="reply-content">I have it. Im figuring out how to embed it here, hold on</p>
                      </div>

                      <p className="reply-btn font-bold">... reply</p>

                      <div className="thread-reply">
                        <div className="inner-thread-reply  border-left">
                          <p className="font-bold reply-username">just_funfunfunfun:</p>
                          <p className="reply-content">Here we go</p>

                          <video  controls className="video" >
                            <source src="https://cdn.huntakiller.com/huntakiller/s8/video/just4fun-gabe-livestream.mp4" type="video/mp4" />
                          </video>

                         </div>

                        <p className="reply-btn font-bold">... reply</p>


                        <div className="thread-reply">
                          <div className="inner-thread-reply  border-left">
                            <p className="font-bold reply-username">Dryan:</p>
                            <p className="reply-content">This is so messed up</p>
                          </div>

                          <p className="reply-btn font-bold">... reply</p>

                          <div className="thread-reply">
                            <div className="inner-thread-reply  border-left">
                              <p className="font-bold reply-username">meadowgoth:</p>
                              <p className="reply-content">Wow i bet her birthday was pretty ruined</p>
                            </div>

                            <p className="reply-btn font-bold">... reply</p>
                          </div>

                        </div>
                      </div>
                    </div>

                  </div>
                </div>

              </div>
               <div className="thread-comment">
                  <div className="divider"></div>

                  <input placeholder="Sign In to Comment" disabled={true}/>
               </div>
               <div className="divider"></div>

            </>
        }



      </div>
    )
  }
}



export default Thread10
