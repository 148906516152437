//Back2Skool is still a modern classic

import React, { Component } from 'react'



class Thread8 extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      extended: false
    };
  }



  toggleExpand = () =>{
    this.setState({ extended: !this.state.extended });
  }


  render() {

    return (
      <div className="thread-content">
        <div className="thread-header" onClick={this.toggleExpand}>
          <p className="thread-username"> Posted by <span className="font-bold">SereneTiger300: </span></p>
          <h3 className={ !this.state.extended ? "thread-title-margin" : undefined }>Back2Skool is still a modern classic</h3>

        </div>


        {
            this.state.extended &&
            <>
              <div className="thread-header" onClick={this.toggleExpand} >
                  <p>Just wanted to state my eternal appreciation for that album! Periodic reminder that Jake is an iconic genius!</p>
              </div>
              <div className="thread-reply-container">
                <div className="thread-reply">
                  <div className="inner-thread-reply  border-left">
                    <p className="font-bold reply-username">MrsFontes24:</p>
                    <p className="reply-content">I mean that’s not a very controversial opinion here of all places</p>
                  </div>
                  <p className="reply-btn font-bold">... reply</p>
                </div>

                <div className="thread-reply">
                  <div className="inner-thread-reply  border-left">
                    <p className="font-bold reply-username">Lust4fun:</p>
                    <p className="reply-content">Hell yeah!!!!!!</p>
                  </div>

                  <p className="reply-btn font-bold">... reply</p>
                </div>


                <div className="thread-reply">
                  <div className="inner-thread-reply  border-left">
                    <p className="font-bold reply-username">rollercoaster420:</p>
                    <p className="reply-content">Idk, I’ll always like the stuff from J4F a little more. They sound so much better when they’re all together, you know?</p>
                  </div>
                  <p className="reply-btn font-bold">... reply</p>
                </div>

                
              </div>
               <div className="thread-comment">
                  <div className="divider"></div>

                  <input placeholder="Sign In to Comment" disabled={true}/>
               </div>
               <div className="divider"></div>

            </>
        }



      </div>
    )
  }
}



export default Thread8
