//Where were you when you heard your first Just4Fun song?

import React, { Component } from 'react'



class Thread1 extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      extended: false
    };
  }



  toggleExpand = () =>{
    this.setState({ extended: !this.state.extended });
  }


  render() {

    return (
      <div className="thread-content">
        <div className="thread-header" onClick={this.toggleExpand}>
          <p className="thread-username"> Posted by <span className="font-bold">Honey_Arcade: </span></p>
          <h3 className={ !this.state.extended ? "thread-title-margin" : undefined }>Where were you when you heard your first Just4Fun song?</h3>

        </div>


        {
            this.state.extended &&
            <>
              <div className="thread-header" onClick={this.toggleExpand}>
                  <p>Thought we could all do with an extra big scoop of nostalgia today! I’ll start...<br/><br/>
  I was at my best friend Megan’s house after our eighth-grade formal, and we were talking about some boys that had asked us to dance, yadda yadda, and then This Dance came on and the lyrics EXACTLY described the situation, like magic ​😍​ ​Hooked since then.
  Your turn!!</p>
              </div>
              <div className="thread-reply-container">
                <div className="thread-reply">
                  <div className="inner-thread-reply  border-left">
                    <p className="font-bold reply-username">jakesfan444:</p>
                    <p className="reply-content">
                      omg that’s so funny, i love it<br/><br/>
      not exactly the same for me, but i was listening with my friend! she said she got a new cd, and we went in her mom’s car and blasted it. A neighbor came out and told us we were being too loud haha
                    </p>
                  </div>
                  <p className="reply-btn font-bold">... reply</p>

                  <div className="thread-reply">
                    <div className="inner-thread-reply  border-left">
                      <p className="font-bold reply-username">Honey_Arcade:</p>
                      <p className="reply-content">I love this story, thank you so much for sharing!</p>
                    </div>

                    <p className="reply-btn font-bold">... reply</p>
                  </div>
                </div>

                 <div className="thread-reply">
                    <div className="inner-thread-reply  border-left">
                      <p className="font-bold reply-username">xxgoodbyegirlxx:</p>
                      <p className="reply-content">Heard Ultraviolet on the radio like everyone else. Played it to deathhhhhhh</p>
                    </div>
                    <p className="reply-btn font-bold">... reply</p>
                 </div>

                 <div className="thread-reply">
                    <div className="inner-thread-reply  border-left">
                      <p className="font-bold reply-username">MrsFontes24:</p>
                      <p className="reply-content">IDK when i first heard them but i remember not liking them at first. little did i know! Huge fan now, have been for years</p>
                    </div>
                    <p className="reply-btn font-bold">... reply</p>
                 </div>
              </div>
               <div className="thread-comment">
                  <div className="divider"></div>

                  <input placeholder="Sign In to Comment" disabled={true}/>
               </div>
               <div className="divider"></div>

            </>
        }



      </div>
    )
  }
}



export default Thread1
