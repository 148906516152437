import React, { Component } from 'react'

/*import './menuContent.css'*/

class MenuContentMRSite extends Component {
  constructor(props) {
    super(props)

    //console.log(this.props.currentView)
    this.items = ["Home","Town Council","Local Business Spotlight","Safety","What's New"]
    
  }

  render() {
    return (
      <ul className="menu">
        {this.items.map(i => 
          <li className={ i === this.props.currentView ? 'current menu-item ' : 'menu-item ' } key={i} onClick={() => this.props.tapMenu(i)} >{i}</li>
        )}    
      </ul>
    )
  }
}



export default MenuContentMRSite
