import React, { Component } from 'react'


class SafetyContentMRSite extends Component {
  
 render() {
    return (
      <>
      <div className="mrSite-home mrSite-safety">
      	<h1>General Safety Tips<br/></h1>


        <div className="mrSite-localBusiness-content">

        	<div className="mrSite-localBusinessimg-container">

	        	<img src="https://cdn.huntakiller.com/huntakiller/s9/photos/town/officer-sullivan-combs.jpg"  alt="officer picture"/>
	        	<p>Officer Sullivan Combs</p>
	        	<img src="https://cdn.huntakiller.com/huntakiller/s9/photos/town/general-safety-map.jpg"  alt="map picture" className="mrsite-map" onClick={() => {this.props.tapMap()}} />

        	</div>

            <div>
        	<p>
        		<b>As the tourist season approaches, here are some safety tips to keep in mind:</b><br/><br/>
            </p>
        		<ol type="1">
        			<li>Never swim alone. Always have a buddy, especially if no lifeguard is present.</li>
        			<li>Obey all signage and posted warnings.</li>
        			<li>Watch out for rip tides. While normal conditions make it nearly impossible to be swept out to sea, a strong rip current can take a swimmer out into deep water in seconds.</li>
        			<li>Wear sunscreen. SPF 35 or higher is best.</li>
        			<li>Stay hydrated. Drinking water will reduce the risk of sunstroke.</li>
        			<li>Enter any body of water feet first. Certain areas are shallower than they appear.</li>
        			<li>Use caution when operating watercraft. Only experienced boaters should be allowed to steer.</li>
        			<li>All passengers on any watercraft should wear lifejackets, regardless of their swimming ability.</li>
        			<li>Never leave personal items unattended on the beach, even on private property.</li>
        			<li>Use caution when driving near the beach at night. Coastal roads may have poor lighting, increasing the likelihood of accidents.</li>
        		</ol>
        	
            </div>

        </div>
        
        
        
      </div>
      </>
    )
  }
}




export default SafetyContentMRSite
