import React, { Component } from 'react'

export default class Transcripthotelrecords extends Component{
  render (){
    return (
    	<div className='rtfdoc'>
    	<p>
    	The Excelsior Hotel<br/>
200 Central Park South<br/>
<br/>
Concierge Reports
</p>
			<table>
				<thead>
				<tr>
					<th>Date</th>
					<th>Room #</th>
					<th>Name</th>
					<th>Notes</th>
					<th>Time</th>
				</tr>
				</thead>
				<tbody>
				<tr>
					<td>Nov 6</td>
					<td>102</td>
					<td>Walter Ghust</td>
					<td>Returned to complain of disheveled room upon check-in. One night paid for at hotel's expense.</td>
					<td>10:15 a.m.</td>
				</tr>
				<tr>
					<td>Nov 6</td>
					<td>146</td>
					<td>John Fuseli</td>
					<td>Patron stopped to compliment the hotel service upon check out, feeling well-rested and restored in vigor.</td>
					<td>10:30 a.m.</td>
				</tr>
				<tr>
					<td>Nov 6</td>
					<td>118</td>
					<td>Charles Wells</td>
					<td>Just after check-in, returned to the front desk to claim the hotel was missing a floor. Distressed, but reassured when told we would investigate.</td>
					<td>Noon</td>
				</tr>
				<tr>
					<td>Nov 6</td>
					<td>N/A</td>
					<td>Tom Burke</td>
					<td>Following minor scuffle, escorted from the premises after attempting to drink from hotel's unmanned bar. (Not currently a guest at the hotel. Recurring issue with this customer.)</td>
					<td>2-2:15 p.m.</td>
				</tr>
				<tr>
					<td>Nov 6</td>
					<td>117</td>
					<td>Robert Darwin</td>
					<td>Room service to be delivered at 5 p.m., not to be disturbed otherwise.</td>
					<td>3 p.m.</td>
				</tr>
				<tr>
					<td>Nov 6</td>
					<td>408</td>
					<td>Nick Jackson</td>
					<td>Complained of unruly guests on his floor.</td>
					<td>2:55 p.m., 3:02 p.m., 3:06 p.m.</td>
				</tr>
				<tr>
					<td>Nov 6</td>
					<td>415</td>
					<td>Delbert family</td>
					<td>Asked children to quiet down after neighbor complaint.</td>
					<td>3:15 p.m.</td>
				</tr>
				<tr>
					<td>Nov 6</td>
					<td>444</td>
					<td>Emily Elstree</td>
					<td>Chauffeur scheduled for 5:30 p.m. pickup.</td>
					<td>3:37 p.m.</td>
				</tr>
				<tr>
					<td>Nov 6</td>
					<td>217</td>
					<td>A.R. Lairde</td>
					<td>Pushed reservation following lengthy telephone conversation. Offered complementary meal and drinks sent to table during dinner; guest requested cocktails made to his specifications: champagne, absinthe, dash of bitters, shot of lime simple syrup, lime wedge garnish.</td>
					<td>4:54 p.m. - 6:15 p.m.</td>
				</tr>
				<tr>
					<td>Nov 6</td>
					<td>810</td>
					<td>Ullman family</td>
					<td>Requested towels for pool usage.</td>
					<td>5:14 p.m.</td>
				</tr>
				<tr>
					<td>Nov 6</td>
					<td>616</td>
					<td>Anastasia Landholdt, Larry Burton</td>
					<td>Room service order: (1) bottle of champagne, (2) glasses.</td>
					<td>5:45 p.m.</td>
				</tr>
				<tr>
					<td>Nov 6</td>
					<td>196</td>
					<td>Lisa and Louise Burns</td>
					<td>Requested additional linens.</td>
					<td>5:53 p.m.</td>
				</tr>
				<tr>
					<td>Nov 6</td>
					<td>618</td>
					<td>Edward Gadd</td>
					<td>Portrait in room was "unsettling." Requested its removal.</td>
					<td>6 p.m.</td>
				</tr>
				<tr>
					<td>Nov 6</td>
					<td>237</td>
					<td>Shelley King</td>
					<td>Lodged noise complaint of someone on her floor repeatedly bouncing a tennis ball against the wall.</td>
					<td>6:15 p.m., 6:30 p.m., 7 p.m.</td>
				</tr>
				<tr>
					<td>Nov 6</td>
					<td>818</td>
					<td>Benjamin Crothers</td>
					<td>Charged dinner to room.</td>
					<td>7 p.m.</td>
				</tr>
				<tr>
					<td>Nov 6</td>
					<td>217</td>
					<td>A.R. Lairde</td>
					<td>Delivered customer's specified drink as requested. Customer's guest choked during dinner. Nearby guest assisted, and professional medical assistance was not required.</td>
					<td>7 p.m.</td>
				</tr>
				<tr>
					<td>Nov 6</td>
					<td>161</td>
					<td>Anthony Grady</td>
					<td>Placed tab at bar, order added to room.</td>
					<td>9 p.m.</td>
				</tr>
				<tr>
					<td>Nov 6</td>
					<td>708</td>
					<td>Lloyd Turkel</td>
					<td>Charged bar order to room, requested to pay for Mr. Grady's tab.</td>
					<td>9 p.m.</td>
				</tr>
				</tbody>
			</table>

    
    </div>
    
  );
  }
};