import React, { Component } from 'react'


export default class UserProfileJust4Fun extends Component {



	render() {


		return(

			

<div>
		<div className="flex-1 flex signinJust4Fun">
	        <div className="profile-box flex p-4">
	        	<div className="profileInfosJust4Fun">
	        		<h3 className="font-bold text-center">lovetorevolt</h3>
	        		<img className="profilePictureJust4Fun mb-4" alt="user profile pic" src="https://cdn.huntakiller.com/huntakiller/s8/store-earphones.jpg"/>
	        		<p>
	        		<span className="font-bold">Last Login Date:</span> 3 months ago<br/>
	        		<span className="font-bold">Joined Date:</span> 12/07/00<br/>
	        		<span className="font-bold">Birthday Date:</span> 09/06/74
	        		</p>
	        	</div>
	          	<div className="profileSettingsJust4Fun rounded-lg p-6">
	          		<h3 className="text-white font-bold mb-6">Account Settings</h3>
	          		<p className="leading-none text-white ">This account has been banned for violating forum rules. The ban prevents this account from accessing settings and posting on the forum.<br/><br/>
Reason for ban: Misinformation and targeted slander.<br/><br/>
For more information, please contact a forum moderator.</p>
	          	</div>
	        </div>
      </div>
     </div>
        )

	}
}