import React, { Component } from 'react'

export default class Transcriptmemoir extends Component{
  render (){
    return (
    	<div className='rtfdoc'>
    <p>
Ira & Evelyn - thought I should run this chapter of my memoir by the two of you before sending it to my editor. If you don’t want this information to be made public just yet, simply say the word, and I’ll strike it from the manuscript.
<br/><br/><br/><br/>


Thank you for checking with us. I don’t think it’s the right time, old friend. 
<br/><br/>
Agreed. We haven’t even told J yet. 
<br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
    </p>

    
    </div>
    
  );
  }
};