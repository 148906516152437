import React, { Component } from 'react'
import store from 'store'
import SeasonDesktopView from './views/SeasonDesktopView'
import { seasonUsers } from './datas/usersSeason.json';
import { icons } from './datas/seasonicons.json';
import { getSeasonUserFromPw } from './utils.js';

class SeasonsDesktop extends Component {

	constructor(props) {
		super(props);
		//this.currentSeasonPW = ""
		this.state = {
			currentSeasonPW: "",
			icons: []

		}
		const passwordSeason = store.get("season")
		if (passwordSeason == null || passwordSeason === false) {
			console.log("notloggedin")
			props.history.push('/')
		} else {

			var user = getSeasonUserFromPw(passwordSeason, seasonUsers);
			if (user != null) {
				this.state.currentSeasonPW = user.pw.toLowerCase()
				//let iconsCurrent = icons.filter(icon => icon.season <= user.season);

				this.state.icons = icons;

			} else {

				props.history.push('/')
			}
		}

		//console.log(icons)
	}




	render() {


		return <SeasonDesktopView icons={this.state.icons} history={this.props.history} />


	}

}
export default SeasonsDesktop