import React, { Component } from 'react'


class ReviewsContentJWESite extends Component {
  

  render() {
    return (
      <>
      <div className="JWESite-home">

        <h1>REVIEWS</h1>
        
        <h2>ON BUYING FROM JW ENTERPRISES:</h2>
        <p>“You can’t spell JW Enterprises without spelling prize, and that’s exactly what you get when you buy one of Mr. Woodard’s remodeled homes.”</p>
        <p className="text-right mt-4">—Elizabeth O.</p>
       
        
        <div className="hr"></div>


        <p>“I will certainly never forget working with Jordan Woodard. He is really something. Truly astounding, that’s all I can say.”</p>
        
       <p className="text-right mt-4">—Terrence Cruise</p>
        
        <div className="hr"></div>

       
         <p>“Great houses for great prices! All around just great!”</p>
        
        <p className="text-right mt-4">—Ralph Underwood</p>
          <div className="hr"></div>

          <h2>ON BUYING FROM COMPETITORS:</h2>
        	
        <p>“If you rearrange the letters in Cica and Neb Homes, it spells ‘bad choices, man.’ Enough said.”</p>
         <p className="text-right mt-4">—Rowan J. Droadod</p>
         <div className="hr"></div>

         <p>“I’ll never do it again.”</p>
        <p className="text-right mt-4">—Terrence Cruise</p>
         <div className="hr"></div>

         <p>“I bought from Lux Home Remodeling. I give them a 0 out of 10 stars, and I’d give them negative if I could. The day after I bought the house, I found a nest of mice living behind the refrigerator!! Lux Home Remodeling would not even reimburse me for having to hire very expensive pest control services. I don’t know how Lux Home Remodeling is one of Maine’s most well known property developers/remodelers, because they are VERY TERRIBLE. DO NOT BUY OR REMODEL YOUR HOUSE WITH LUX HOME REMODELING.”</p>
        <p className="text-right mt-4">—William S.</p>
         <div className="hr"></div>


      </div>
      </>
    )
  }
}



export default ReviewsContentJWESite
