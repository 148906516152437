import React, { Component } from 'react'

export default class Transcriptlyrics extends Component{
  render (){
    return (
    	<div className='rtfdoc lyrics'>
    <p>
	 Odd Endings
<br/><br/>
When a situation like our ends, <br/>
one must hope to remain friends.<br/> 
There is no evil in your heart,<br/>
but circumstance dictates we part. 
<br/><br/>
After all, there’s been another for you from the start, a<br/>
man to whom you’ve promised your hand and your heart.  <br/>
That ring around your finger isn’t just a toy. <br/>
If you just want a distraction, you can find another boy. 
<br/><br/>
Even if you do take us seriously, a<br/>
mother like yours is no parent to me. <br/>
The screaming I heard, the violence I saw<br/>
caused me to feel that I had to withdraw. 
<br/><br/>
Yet where one door closes, a<br/>
window bursts open. My heart blooms with roses <br/>
for someone anew, and I hope and I pray<br/>
that perhaps you’ll both see this from my side, someday. 
<br/><br/>
January 29th, 1934 
<br/><br/>
My First and Last 
<br/><br/>
Every night, the thought of you delights. Ev- <br/>
ery day, I keep you in my sight. How artful<br/>
you are: you’ve given me a heart full of lovin’. 
<br/><br/>
When a troublesome gal tried to pull me away, I <br/>
looked right at you, and I knew she would pay. Will <br/>
you choose me? Will you, now that I’ve chosen you too?<br/>
Understand that from now on, I’ll always be true. I’m<br/>
already sure, just as sure as can be. Oh lover,<br/>
romantically, respectfully,
<br/><br/>
My heart lives in you like a bird in a tree. 
<br/><br/>
November 3rd, 1934 
    </p>

    
    </div>
    
  );
  }
};