import React, { Component } from 'react'


class HomeContentMRSite extends Component {
  

  render() {
    return (
      <>
      <div className="mrSite-home">

        <p><b>Welcome to the official website of the Town of Mallory Rock. Here you’ll find the most up-to-date information on our beloved island—from Town Council updates to local business discounts, and everything in between.<br/><br/></b></p>

        <div className="home-banner-just4Fun">
          <img src="https://cdn.huntakiller.com/huntakiller/s9/photos/town/largetown.jpg"  alt="home banner"/>
        </div>

        <div className="flex signature-container">

          <div>
             <img src="https://cdn.huntakiller.com/huntakiller/s9/photos/town/signature.svg"  alt="signature"/>
             <p>Amandine Noga, Town Manager</p>
          </div>
          <h2>Thanks for visiting!</h2>
        </div>
        
          
        
      </div>
      </>
    )
  }
}



export default HomeContentMRSite
