import React, { Component } from 'react'
import Draggable from 'react-draggable';
import {isMobile, isIOS13} from 'react-device-detect';
import Div100vh from 'react-div-100vh'
import 'react-perfect-scrollbar/dist/css/styles.css';
import PerfectScrollbar from 'react-perfect-scrollbar'
import ImageViewer from './ImageViewer'
import AudioViewer from './AudioViewer'
import VideoViewer from './VideoViewer'
import VideoWistiaViewer from './VideoWistiaViewer'
import DesktopIcon from './DesktopIcon'
import '../css/scrollbar.css'
import ReactToPrint from 'react-to-print';

import Cipherguide  from '../rtf/cipherguide';
import Forensics  from '../rtf/forensics';
import Cocktails  from '../rtf/cocktails';
import Transcriptruthnote  from '../rtf/transcriptruthnote';
import Transcriptrehearsalnotes  from '../rtf/transcriptrehearsalnotes';
import Transcriptledger  from '../rtf/transcriptledger';
import Transcriptcodedletter  from '../rtf/transcriptcodedletter';
import Transcriptdeliveryslip  from '../rtf/transcriptdeliveryslip';
import Transcriptticket  from '../rtf/transcriptticket';
import Transcriptposterproof  from '../rtf/transcriptposterproof';
import Transcripolgaletter  from '../rtf/transcripolgaletter';
import Transcripcodedletter2  from '../rtf/transcripcodedletter2';
import Transcriptscriptnotes  from '../rtf/transcriptscriptnotes';
import Transcriptlyrics  from '../rtf/transcriptlyrics';
import Transcripttheaterblueprint  from '../rtf/transcripttheaterblueprint';
import Transcriptjewelryappraisal  from '../rtf/transcriptjewelryappraisal';
import Transcripthotelrecords  from '../rtf/transcripthotelrecords';
import Transcriptcodednotes  from '../rtf/transcriptcodednotes';
import Transcriptmemoir  from '../rtf/transcriptmemoir';
import Transcriptcodedletter3  from '../rtf/transcriptcodedletter3';
import Transcriptevelyniranotes  from '../rtf/transcriptevelyniranotes';

import Investigationguide  from '../rtf/investigationguide';

const components = {
    "cipherguide.rtf": Cipherguide,
    "forensics.rtf": Forensics,
    "cocktails.rtf": Cocktails,
    "transcriptruthnote.rtf": Transcriptruthnote,
    "transcriptrehearsalnotes.rtf": Transcriptrehearsalnotes,
    "transcriptledger.rtf": Transcriptledger,
    "transcriptcodedletter.rtf": Transcriptcodedletter,
    "transcriptdeliveryslip.rtf": Transcriptdeliveryslip,
    "transcriptticket.rtf": Transcriptticket,
    "transcriptposterproof.rtf": Transcriptposterproof,
    "transcripolgaletter.rtf": Transcripolgaletter,
    "transcripcodedletter2.rtf": Transcripcodedletter2,
    "transcriptscriptnotes.rtf": Transcriptscriptnotes,
    "transcriptlyrics.rtf": Transcriptlyrics,
    "transcripttheaterblueprint.rtf": Transcripttheaterblueprint,
    "transcriptjewelryappraisal.rtf": Transcriptjewelryappraisal,
    "transcripthotelrecords.rtf": Transcripthotelrecords,
    "transcriptcodednotes.rtf": Transcriptcodednotes,
    "transcriptmemoir.rtf": Transcriptmemoir,
    "transcriptcodedletter3.rtf": Transcriptcodedletter3,
    "investigationguide.rtf":Investigationguide,
    "transcriptevelyniranotes.rtf":Transcriptevelyniranotes,

};



export default class Modal extends Component {
    constructor(props) {
        super(props);
        //console.log(this.props.modal)
        this.updateDimensions = this.updateDimensions.bind(this)
        this.state = {
            
            previousPosition: {
              x: 200, y: 200
            },
            modal: this.props.modal,
            
          };

    }


    onStop = (e, position) => {
        //this.setState({zindex:10});
        //const {x, y} = position;
        //this.setState({controlledPosition: {x, y}});
        //this.props.icon.left = x
       // this.props.icon.top = y

    };

    onStart = (e, position) => {
        //this.setState({zindex:10});
        //const {x, y} = position;
        //this.setState({controlledPosition: {x, y}});
        //this.props.icon.left = x
       // this.props.icon.top = y
       this.props.updateZindex(this.state.modal)
    };

    handleDrag = (e, position) => {
        const {x, y} = position;
        const modal = this.state.modal
        modal.left = x
        modal.top = y
        this.setState({modal: modal});
        this.setState({previousPosition: {x, y}});

    };


    componentDidMount() {
        this.updateDimensions()
        if (window.innerWidth < 800 || (isMobile || isIOS13)) {
            //this.setState({previousPosition: this.state.controlledPosition});
            //this.setState({controlledPosition: {x:0, y:0}});
            const modal = this.state.modal
            modal.left = 0
            modal.top = 0
            this.setState({modal: modal, previousPosition: this.state.controlledPosition});
        }else{
            //const modal = this.state.modal
            //modal.left = (window.innerWidth - parseInt(this.props.modal.width))/2
            //modal.top = (window.innerHeight - parseInt(this.props.modal.height))/2
            //this.setState({modal: modal});
            //this.setState({previousPosition: {x:(window.innerWidth -parseInt( this.props.modal.width))/2, y:(window.innerHeight - parseInt(this.props.modal.height))/2}});

        }

        window.addEventListener("resize", this.updateDimensions);
    };

    componentWillUnmount(){
        window.removeEventListener('resize', this.updateDimensions);
    };

    updateDimensions() {
        //console.log("resize")
        const modal = this.state.modal
        var newIcons = modal.icons
        //console.log(newIcons)
        if (window.innerWidth < 800 || (isMobile || isIOS13)) {
            const modal = this.state.modal
            this.setState({previousPosition: {x: modal.left , y: modal.top}});
            //this.setState({controlledPosition: {x:0, y:0}});
            
            modal.left = 0
            modal.top = 0
            this.setState({modal: modal});

            let i = 0
            let top = 10
            let left = 10

            newIcons.forEach(icon => {
              if (i % 3 === 0) {
                if (i !== 0) {
                  top += 124
                }
                left = 10
              } else {
                left += 105
              }
              icon.top = top
              icon.left = left
              i++
            })

        }else{
            this.setState({controlledPosition: this.state.previousPosition});
            let i = 0
            let top = 10
            let left = 10
            newIcons.forEach(icon => {
              if (i % 6 === 0) {
                if (i !== 0) {
                  top += 120
                }
                left = 10
              } else {
                left += 105
              }
              icon.top = top
              icon.left = left
              i++
            })
            
            
        }

        modal.icons = newIcons
        this.setState({modal:modal});

        
        

    }
    closeModal = (e) => {
        this.props.closeModal(this.props.modal)
    };
   
   
    render() {
        const dragHandlers = { onStop: this.onStop, onDrag:this.handleDrag, onStart:this.onStart};

    	//const modal = this.stat.modal
        const {modal} = this.state;

        let controlledPosition = {x: modal.left, y: modal.top}

        var modalStyle;

        
        if (window.innerWidth < 800 || (isMobile || isIOS13)) {
            modalStyle = {
                zIndex:modal.z,
                width:"100%",
                height:"100%",
            }

        }else{
            modalStyle = {
                zIndex:modal.z,
                
        	}
        }
        const iconviews = modal.icons.map((icon, index) => {
            if(icon.episode <= this.props.currentEpisode){
                return (
                  <DesktopIcon icon={icon} key={index}  iconSingleClick={this.props.iconSingleClick}/> 
                )
            }else return false
            
          })

        const clearStyle = {
          clear: 'both'
        };

        const Component = components[modal.name]
        
        //console.log(this.state.modal)

        return (
            <Draggable position={controlledPosition}  {...dragHandlers}  disabled={(isMobile || isIOS13) ? true : false}>
    			<div className="modal" style= {modalStyle} id={`modal-${modal.id}`}>
                <Div100vh   dontresize="true" className="modalDiv" style={{maxHeight: isMobile ? '' : '100%'}}>
                    <div className="modalcontainer">
                        <div className="header">
                           

                            <h2>{modal.name}</h2><
                            button className="close" data-modal-id={`modal-${modal.id}`} onClick={this.closeModal}/>
                        </div>
                        
                        
                        {modal.type === "folder" && (<PerfectScrollbar><div className="content-file folder-content">{iconviews}<div style={clearStyle}></div></div></PerfectScrollbar>)}
                        {modal.type === "image" && (
                                <ImageViewer img_url={modal.image_url} img_name={modal.name} originalImageWidth={modal.width} originalImageHeight={modal.height} openEmailer={this.props.openEmailer}/>

                        )}
                        
                        {modal.type === "audio" && (
                                <AudioViewer audio_url={modal.audio_url} audio_name={modal.name}/>

                        )}

                         {modal.type === "video" && (
                                <VideoViewer video_url={modal.video_url} ></VideoViewer>

                        )}

                         {modal.type === "videoWistia" && (
                               <VideoWistiaViewer video_url={modal.video_url} ></VideoWistiaViewer>

                        )}

                        {modal.type === "text" && (
                                <div className="imageViewer">
                               <PerfectScrollbar><Component currentSeason={this.props.currentSeason}  ref={el => (this.componentRef = el)} /></PerfectScrollbar>

                               <div className="bottom-imageViewer-controllers">
                                <ReactToPrint
                                      trigger={() => {
                                        // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
                                        // to the root node of the returned component as it will be overwritten.
                                        return <a className="print-icon" href="#">Print</a>;
                                      }}
                                      content={() => this.componentRef}
                                    />

        </div>


                               </div>
                         
                               

                        )}
                      
                        
        			</div>
                </Div100vh>
                </div>
            </Draggable>
        )
    }
}



