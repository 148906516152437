import React, { Component } from 'react'
import SeasonLoginView from './views/SeasonLoginView'
import { seasonUsers } from './datas/usersSeason.json';
class SeasonLogin extends Component {
  
  render() {

    return <SeasonLoginView users={seasonUsers} history={this.props.history} />
  }
}
export default SeasonLogin