import React, { Component } from 'react'



class MerchContentJust4Fun extends Component {
  constructor(props) {
	    super(props);

	  	this.state = {
	      showNewsletterForm:false,
	      showNewsletterSuccess:false,
	      error:false,
	      email:""
	    };
	    
	}

	handleChange = event => {
  		this.setState({ error: false });
	    this.setState({
	      [event.target.id]: event.target.value
	    });
	 }


	tapNewsletter = event => {

		this.setState({ showNewsletterForm: true });
	}



	handleSubmit = event => {
		//console.log("submit")
	    event.preventDefault();
	    if(this.state.email.length > 0){
	    	this.setState({ error: false });
	    	this.setState({ showNewsletterSuccess: true });
	    	
	    }else{
	    	this.setState({ error: true });
	    }

	}


	handleKeyPress = (event) => {
	  if(event.key === 'Enter'){
	    //console.log('enter press here! ')
	    document.activeElement.blur();
	    event.preventDefault();
	  }
	}

  render() {

  		if(!this.state.showNewsletterForm ) {


		    return (
		    	<div>
		     <div className="flex-1 flex ">
		        <div className="merch-container justify-center items-center">

			        <p className="text-center mt-8 mb-8"><span className="font-bold">UPDATE:</span> All merch is currently sold out!</p>

			        <div className="flex products-container justify-center items-center">
			        	<div>
			        		<img src="https://cdn.huntakiller.com/huntakiller/s8/store-shirt.png" alt=" product 1"/>
			        		<p className="text-center font-bold mt-4">SOLD OUT</p>
			        	</div>

			        	<p className="leading-none text-center mobile text-black mb-8">To be added to a<br/> waitlist and receive a <br/>notificatio when <br/>merch is back in stock, <br/>click <span className="font-bold cursor-pointer just4Fun-dark-blue-color" onClick={this.tapNewsletter}>HERE.</span></p>
			  

			        	<div>
			        		<img src="https://cdn.huntakiller.com/huntakiller/s8/store-hat.png" alt=" product 2"/>
			        		<p className="text-center font-bold mt-4">SOLD OUT</p>
			        	</div>
			        	<div>
			        		<img src="https://cdn.huntakiller.com/huntakiller/s8/store-tote.png" alt=" product 3"/>
			        		<p className="text-center font-bold mt-4">SOLD OUT</p>
			        	</div>
			        </div>


			        <p className="leading-none text-center desktop mt-8">To be added to a waitlist and receive a notification<br/> when merch is back in stock, click <span className="font-bold cursor-pointer just4Fun-dark-blue-color" onClick={this.tapNewsletter}>HERE.</span></p>
			    </div>
		        
		     </div>
		     </div>
		    )
		}else{
			return (
<div>
				<div className="flex-1 flex signinJust4Fun">
			    <div className="login-box flex flex-col justify-center items-center p-4 pt-10 pb-8">

			    	{!this.state.showNewsletterSuccess ? (
			    		<form className="  " onSubmit={this.handleSubmit}>
			    		<div className="">
			    			<p className="annoucement  mb-12 ">To be added to the waitlist, <br/>enter your email address:</p>

			  				<div className=" mb-3 mx-8">
			        			<input className="shadow appearance-none border  rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" 
			       type="email" id="email" placeholder="email address" onKeyPress={this.handleKeyPress} onChange={this.handleChange} />
			  				</div>
			  				<p className="error mt-8 mb-2">{this.state.error && "Cannot leave field blank."} &nbsp;</p>
			    		</div>
			    		<div className="flex items-center justify-between">
					      <button className="login-btn text-white px-12 rounded focus:outline-none focus:shadow-outline" id="just4FunSiteNewsletter" type="submit">
					        Submit
					      </button>
					    
					    </div>
			    	</form>

			    	) : (
			    	<p className="text-white text-center newsletterSuccessMsg m-12 py-12">Thank you for joining the waitlist!<br/><br/>You will be notified when merch is back in stock.</p>
			    	)}

					

			    </div>
			  </div>
</div>

				)
		}
  }
}



export default MerchContentJust4Fun
