import React, { Component } from 'react'
import Dock from '../components/Dock'
import DesktopIcon from '../components/DesktopIcon'
import LoginView from './LoginView'


//import { users } from '../datas/users.json';


import Div100vh from 'react-div-100vh'


const users = JSON.parse(process.env.REACT_APP_EPISODE_PW);

export default class SeasonDesktopView extends Component {

  constructor(props) {
    super(props);
    this.updateDimensions = this.updateDimensions.bind(this)
    this.state = {
      icons: this.props.icons,
      showModal: false,
      selectedIcon: null,
    }

    //console.log("process.env.EPISODE_PW")
    //console.log(JSON.stringify(users))


    //console.log(process.env.REACT_APP_EPISODE_PW)


    //console.log(JSON.parse(process.env.REACT_APP_EPISODE_PW))

  }





  handleClickIcon = e => {

    if (e.target.id == "icon-3") {
      window.location.href = "https://white-hilt-renfaire.com";
    }
    else {
      var iconIndex = e.target.id.substring(5, e.target.id.length);
      this.setState({ selectedIcon: this.state.icons[iconIndex], showModal: true })
    }
  }

  handleTapBack = e => {
    e.preventDefault()
    this.setState({ selectedIcon: null, showModal: false })

  }


  componentDidMount() {
    this.updateDimensions()
    window.addEventListener("resize", this.updateDimensions);
  }


  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  };




  updateDimensions() {
    //console.log("resize")
    var newIcons = this.props.icons
    if (window.innerWidth < 800) {
      let i = 0
      let top = 20
      let left = 20
      newIcons.forEach(icon => {
        if (i % 3 === 0) {
          if (i !== 0) {
            top += 140
          }
          left = 20
        } else {
          left += 115
        }
        icon.top = top
        icon.left = left
        i++
      })
      this.setState({ icons: newIcons });
    } else {
      let i = 0
      let top = 60
      let left = 60
      newIcons.forEach(icon => {
        if (i % 3 === 0) {
          if (i !== 0) {
            top += 140
          }
          left = 60
        } else {
          left += 115
        }
        icon.top = top
        icon.left = left
        i++
      })
      this.setState({ icons: newIcons });
    }
  }


  render() {
    const icons = this.state.icons

    //console.log(icons)
    const iconviews = icons.map((icon, index) => {
      return (
        <DesktopIcon icon={icon} key={index} iconSingleClick={this.handleClickIcon.bind(this)} />
      )
    })

    return (<div >
      {!this.state.showModal && (
        <Div100vh dontresize="true" className="app">
          <div className="flex-1">
            <div className="relative">{iconviews}</div>
          </div>




          <Dock logout="false" />
        </Div100vh>
      )}

      {this.state.showModal && (
        <Div100vh dontresize="true" className={this.state.selectedIcon.id} >
          <LoginView users={users} selectedSeason={this.state.selectedIcon} history={this.props.history} tapBack={this.handleTapBack.bind(this)} />
        </Div100vh>
      )}

    </div>)
  }
}
