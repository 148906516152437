import React, { Component } from 'react'


class TownConcilContentMRSite extends Component {
  

  render() {
    return (
      <>
      <div className="mrSite-home">

         <h1>Town Council</h1>
        

        <div className="mrSite-town-concil-item">

          <img src="https://cdn.huntakiller.com/huntakiller/s9/photos/town/amandine-noga.jpg" />

          <p><b>Amandine Noga, Town Manager</b><br/><br/>Amandine has been Mallory Rock’s Town Manager since 2018. When she’s not working at Town Hall, she’s busy mentoring the next generation of islanders at Mallory Rock High School, where she teaches English and Writing.</p>
        </div>

        <div className="mrSite-town-concil-item">
          
          <img src="https://cdn.huntakiller.com/huntakiller/s9/photos/town/jennifer-saracco.jpg" />

          <p><b>Jennifer Saracco, Town Clerk</b><br/><br/>Jennifer moved to the island twelve years ago in order to open Mallory Rock Urgent Care with her business partner, Dr. Eric Kovacs. She quickly fell in love with the town’s historic charm, and before long, she knew she was here for the long haul.</p>
        </div>

        <div className="mrSite-town-concil-item">
          
          <img src="https://cdn.huntakiller.com/huntakiller/s9/photos/town/chris-ballantine.jpg" />

          <p><b>Chris Ballantine, Harbormaster</b><br/><br/>Chris comes from a long line of fishermen and is honored to serve the hard-working folks in the fishing industry. In his spare time, he enjoys watching Acadian State University basketball and serving as a mentor in the Junior Navigators afterschool program.</p>
        </div>


        <div className="mrSite-town-concil-item">
          
          <img src="https://cdn.huntakiller.com/huntakiller/s9/photos/town/martin-hendricks.jpg" />

          <p><b>Martin Hendricks, Councilor</b><br/><br/>Martin Hendricks is the CEO of the Hendricks Group, a local development and construction company. He has been married to the beautiful Jody Hendricks for over forty years.</p>
        </div>

        <div className="mrSite-town-concil-item">
          
          <img src="https://cdn.huntakiller.com/huntakiller/s9/photos/town/edward-eddie-blue-g.jpg" />

          <p><b>Edward "Eddy Blue" Gundy, Councilor</b><br/><br/>Eddy is the newest member of the Town Council, filling the seat left by the beloved Beth Ferris-Hendricks. He hopes to use this opportunity to be a progressive advocate for the people of Mallory Rock.</p>
        </div>

        <div className="mrSite-town-concil-item">
          
          <img src="https://cdn.huntakiller.com/huntakiller/s9/photos/town/stephen-ballantine.jpg" />

          <p><b>Stephen Ballantine, Councilor</b><br/><br/>Stephen is proud to serve on the Town Council alongside his younger brother, Chris. Hailing from one of the oldest families on Mallory Rock, he pledges to continue the Ballantines’ legacy of service on the island. Stephen is the owner of The Oyster Bucket in downtown Mallory Rock.</p>
        </div>

        <div className="mrSite-town-concil-item">
          
          <img src="https://cdn.huntakiller.com/huntakiller/s9/photos/town/allie-bates-goode.jpg" />

          <p><b>Allie Bates-Goode, Councilor</b><br/><br/>Allie is an attorney with Journey & Ferris, specializing in estate and probate law. Her husband, Kevin Goode, is the football coach at Mallory Rock High. Together they have three sons: Rob, Silas, and Thom.</p>
        </div>

        <div className="mrSite-town-concil-item">
          
          <img src="https://cdn.huntakiller.com/huntakiller/s9/photos/town/sam-gundy.jpg" />

          <p><b>Sam Gundy, Secretary (Non-Voting)</b><br/><br/>Sam is the owner of the holistic wellness shop Mother Island and the daughter of Councilor Eddy Blue Gundy. She is proud to be the twelfth Gundy to serve on the Town Council in some capacity.</p>
        </div>

        <div className="mrSite-town-concil-item">
          
          <img src="https://cdn.huntakiller.com/huntakiller/s9/photos/town/beth.jpg" />

          <p>In Memoriam<br/><b>Beth Ferris-Hendricks, Councilor</b><br/><br/>Beth Ferris-Hendricks’s term as a member of the Mallory Rock Town Council was ended prematurely due to her tragic death. Taken from us far too soon, she will remain in our hearts forever.</p>
        </div>

       
        
      </div>
      </>
    )
  }
}



export default TownConcilContentMRSite
