import React, { Component } from 'react'


const CORRECT_USERNAME = "lovetorevolt";
const CORRECT_BIRTHDAY = "09/06";
const CORRECT_RESTAURANT = "Maison Belmont";
const CORRECT_SONG = "This Dance";

export default class ForgotPasswordJust4Fun extends Component {
	constructor(props) {
        super(props);
        
        this.state = {
          error:false,
          username:"",
          birthday:"",
          restaurant:"",
          song:""
        };

      }

  handleSubmit = event => {
    //console.log("submit")
      event.preventDefault();
      document.getElementById("username").blur();
      document.getElementById("birthday").blur();
      document.getElementById("restaurant").blur();
      document.getElementById("song").blur();

      

      if( (this.state.username.toLowerCase() === CORRECT_USERNAME.toLowerCase()) && (this.state.birthday.toLowerCase() === CORRECT_BIRTHDAY.toLowerCase()) && (this.state.restaurant.toLowerCase() === CORRECT_RESTAURANT.toLowerCase()) && (this.state.song.toLowerCase() === CORRECT_SONG.toLowerCase()) ){
        //this.setState({  error:false})
        this.props.handleGoToRPofile(event)


      }else{
        this.setState({ error: true });
      }


   
  }

 handleChange = event => {
      this.setState({ error: false });
      this.setState({
        [event.target.id]: event.target.value
      });
   }


  handleKeyPress = (event) => {
    if(event.key === 'Enter'){
      //console.log('enter press here! ')
      document.activeElement.blur();
      event.preventDefault();
    }
  }



	
	render() {


		return(

			

      <div>
			<div className="flex-1 flex signinJust4Fun">
        <div className="login-box flex flex-col justify-center items-center p-4 pt-10 pb-8">
          <form className="  " onSubmit={this.handleSubmit}>
            <div className="">
              <p className="annoucement mb-8 desktop">Forgot Your Password?</p>
              
              <p className="annoucement mx-8 mb-6"> Enter your username and answer the three security questions to access your account.</p>


              <div className=" mb-4 mx-12">
                <label className="leading-none text-white text-center block mb-2 mx-4">Username</label>
                <input className="shadow appearance-none border  rounded  w-full py-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"  type="text" autoFocus id="username" value={this.state.username}  onKeyPress={this.handleKeyPress} onChange={this.handleChange} />
              </div>

              <div className=" mb-4 mx-12">
                <label className="leading-none text-white text-center block mb-2 mx-4">What is your birthdate (MM/DD)?</label>
                <input className="shadow appearance-none border  rounded  w-full py-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"  type="text" autoFocus id="birthday" value={this.state.birthday}   onKeyPress={this.handleKeyPress} onChange={this.handleChange} />
              </div>

              <div className=" mb-4 mx-12">
                <label className="leading-none text-white text-center block mb-2 mx-4">What is your favorite restaurant?</label>
                <input className="shadow appearance-none border  rounded  w-full py-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"  type="text" autoFocus id="restaurant" value={this.state.restaurant}   onKeyPress={this.handleKeyPress} onChange={this.handleChange} />
              </div>

              <div className=" mb-8 mx-12">
                <label className="leading-none text-white text-center block mb-2 mx-4">What is your favorite Just4Fun song?</label>
                <input className="shadow appearance-none border  rounded  w-full py-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"  type="text" autoFocus id="song"  value={this.state.song} onKeyPress={this.handleKeyPress} onChange={this.handleChange} />
              </div>

              <p className="error mt-8 mb-2">{this.state.error && "Security Information Incorrect"} &nbsp;</p>


              <div className="flex items-center justify-between">
                <button className="login-btn text-white px-12 rounded focus:outline-none focus:shadow-outline" id="just4FunSiteForgotPassword" type="submit"  >
                  Submit
                </button>
              
              </div>
            </div>
          </form>
        </div>
      </div>
      </div>
        )

	}
}