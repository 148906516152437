import React, { Component } from 'react'

export default class Transcriptticket extends Component{
  render (){
    return (
    	<div className='rtfdoc'>
    <p>
	  Mrs. Ruth Erikson
<br/><br/>
Nov. 6
<br/><br/>
[Checked off] Call ahead to have nursery prepared<br/>
[Checked off] Drop off gift for George<br/>
[Checked off] Stop at Tannersville General Store<br/>
Igrr Rgcxktik?
<br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>

    </p>

    
    </div>
    
  );
  }
};