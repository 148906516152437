
import React, { Component } from 'react'

export default class Transcriptcodednotes extends Component{
  render (){
    return (
    	<div className='rtfdoc'>
    <p>
Such time I’ve given to this enterprise, only to have my whole world turned upside down. What was once my foundation is now over my head.
<br/><br/>
Face it, the play isn’t working, even though my love is in it. I’m going to need to call 8/3 thm Bebucqas again. And before they have to be the ones to pick up the telephone. 4/3 Chmcako ozergoaxs happen to men who don’t repay men like him.
<br/><br/>
<u>I need to pay them what I owe</u>. Write it down. Look at it. Maybe then it will finally sink in.
<br/><br/>
I’ll forgive you, my darling. I know you’ll make it up to me.
<br/><br/>
What could I do to get what they need? Well... There is one thing I could use. My <u>only</u> thing. It is my livelihood. It is everything. It is what brought my love to me in the first place. But if I don’t, what could that mean for me and you? 
<br/><br/>
5/2 If I stli tme yhjayew, where would that leave us? If I left this place and these people, I’m sure I could convince you to come with me. I can imagine it in verse, in the way I know you would appreciate, a way of which I know you are fond:
<br/><br/>
<span className="strikethrough">The two of us atop the world, atop the Empire<br/>
State Building. You, not in the arms 
<br/><br/>
of any old buffoon, but<br/>
in mine.
<br/><br/>
A world that means to keep us apart<br/>
would make us spend <br/>
120 Days in Sodom <br/>
as</span> No! No, no, no no no! 
<br/><br/>
Such vulgarity, such mundanity, is inappropriate for a beauty such as you, my darling. Will anything be good enough? Some people just aren’t poets, I suppose. Oh, if only I could write something for you as the Bard wrote for the Dark Lady. I too do ‘rise at thy name!’ If only my nobler part were more inclined, I would write tales of you as my Bright Lady. Maybe one day... Maybe an epic...
<br/><br/>
Of course, there is always the problem of 7/1 tf dpml, but such problems shall be dealt with before we can be together. What I have to deal with now is what’s directly in front of me, what’s waiting on the West Side if I don’t get them what I owe. I’d never let such problems fall to your feet.
<br/><br/>
I’ve come to terms that I might have to sell. Even if just to pay off the 4/3 Bexucmas. I don’t want to, and I don’t think you’ll be pleased, my Bright Lady, but even so, it seems like good hands await the 4/2 Cedinge with Ira. 
<br/><br/>
This may have brought us together, but I know we don’t need it to stay together.

    </p>

    
    </div>
    
  );
  }
};