import React, { Component } from 'react'


class RetailPropertiesContentJWESite extends Component {
  

  render() {
    return (
      <>
      <div className="JWESite-home">

        <h1>RETAIL PROPERTIES</h1>
        
        <h2>THE ROCK LOUNGE</h2>
        <div className="flex JWESite-currentprop-content">
        	<img src="https://cdn.huntakiller.com/huntakiller/s9/photos/jwe/rocklounge-1.jpg"  alt="home banner"/>
          	<div>
          		<img src="https://cdn.huntakiller.com/huntakiller/s9/photos/jwe/rocklounge-2.jpg"  alt="home banner"/>
          		<img src="https://cdn.huntakiller.com/huntakiller/s9/photos/jwe/rocklounge-3.jpg"  alt="home banner"/>
          	</div>
            
        </div>
        
       
        
        <div className="hr"></div>

        <h2>LOUIE’S LOBSTER POUND</h2>

        <div className="flex JWESite-currentprop-content">
        	<img src="https://cdn.huntakiller.com/huntakiller/s9/photos/jwe/louieslobsterpound-1.jpg"  alt="home banner"/>
          	<div>
          		<img src="https://cdn.huntakiller.com/huntakiller/s9/photos/jwe/louieslobsterpound-2.jpg"  alt="home banner"/>
          		<img src="https://cdn.huntakiller.com/huntakiller/s9/photos/jwe/louieslobsterpound-3.jpg"  alt="home banner"/>
          	</div>
            
        </div>
       
        
        <div className="hr"></div>
       </div>
      </>
    )
  }
}



export default RetailPropertiesContentJWESite
