//did anyone else hear...?

import React, { Component } from 'react'



class Thread1 extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      extended: false
    };
  }



  toggleExpand = () =>{
    this.setState({ extended: !this.state.extended });
  }


  render() {

    return (
      <div className="thread-content">
        <div className="thread-header" onClick={this.toggleExpand}>
          <p className="thread-username"> Posted by <span className="font-bold">meadowgoth: </span></p>
          <h3 className={ !this.state.extended ? "thread-title-margin" : undefined }>did anyone else hear...?</h3>

        </div>


        {
            this.state.extended &&
            <>
              <div className="thread-header"  onClick={this.toggleExpand}>
                  <p>...about this reunion concert? is it real??</p>
              </div>
              <div className="thread-reply-container">
                <div className="thread-reply">
                  <div className="inner-thread-reply  border-left">
                    <p className="font-bold reply-username">SashaCat:</p>
                    <p className="reply-content">YES, IT’S REAL!!! At that old amusement park in Santa Lucinda where they debuted lol, so yeah i wanna go!!!! I don’t think it’s a full tour, just one show, but maybe if it sells out they’ll see they still have fans and they’ll start touring again!</p>
                  </div>
                  <p className="reply-btn font-bold">... reply</p>

                  <div className="thread-reply">
                    <div className="inner-thread-reply  border-left">
                      <p className="font-bold reply-username">meadowgoth:</p>
                      <p className="reply-content">Oh my god no way</p>
                    </div>

                    <p className="reply-btn font-bold">... reply</p>
                  </div>

                  <div className="thread-reply">
                    <div className="inner-thread-reply  border-left">
                      <p className="font-bold reply-username">ollie_oop111:</p>
                      <p className="reply-content">noooooO!!!!! I live in chicago, there’s no way i can make it!</p>
                    </div>

                    <p className="reply-btn font-bold">... reply</p>

                    <div className="thread-reply">
                      <div className="inner-thread-reply  border-left">
                        <p className="font-bold reply-username">serenetiger300:</p>
                        <p className="reply-content">You think you have it bad, i live in the UK</p>
                      </div>

                      <p className="reply-btn font-bold">... reply</p>

                      <div className="thread-reply">
                        <div className="inner-thread-reply  border-left">
                          <p className="font-bold reply-username">Lust4fun:</p>
                          <p className="reply-content">Same<span role="img" aria-label="very-sad-imojo">😭</span></p>
                        </div>

                        <p className="reply-btn font-bold">... reply</p>
                      </div>

                    </div>

                  </div>

                </div>

                 <div className="thread-reply">
                    <div className="inner-thread-reply  border-left">
                      <p className="font-bold reply-username">angeldemonxx:</p>
                      <p className="reply-content">Wait really? When and where?</p>
                    </div>
                    <p className="reply-btn font-bold">... reply</p>

                    <div className="thread-reply">
                      <div className="inner-thread-reply  border-left">
                        <p className="font-bold reply-username">SereneTiger300:</p>
                        <p className="reply-content">Scroll up</p>
                      </div>
                      <p className="reply-btn font-bold">... reply</p>
                   </div>

                 </div>

                 
              </div>
               <div className="thread-comment">
                  <div className="divider"></div>

                  <input placeholder="Sign In to Comment" disabled={true}/>
               </div>
               <div className="divider"></div>

            </>
        }



      </div>
    )
  }
}



export default Thread1
