//Just dug up an old piece of...creative writing.

import React, { Component } from 'react'



class Thread12 extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      extended: false
    };
  }



  toggleExpand = () =>{
    this.setState({ extended: !this.state.extended });
  }


  render() {

    return (
      <div className="thread-content">
        <div className="thread-header" onClick={this.toggleExpand}>
          <p className="thread-username"> Posted by <span className="font-bold">meadowgoth: </span></p>
          <h3 className={ !this.state.extended ? "thread-title-margin" : "" }>Just dug up an old piece of...creative writing.</h3>

        </div>


        {
            this.state.extended &&
            <>
              <div className="thread-header" onClick={this.toggleExpand} >
                  <p>Thought you’d all get a kick out of this! While I was visiting my parents this weekend I went through an old desktop and happened to unearth a treasure trove of my teenage writing, much of which included the boys in some way or another.<br/><br/>Here’s the opening of my magnum opus, which was titled ‘Written in the Stars’ and clocked in at around 150,000 words:<br/><br/><i>Sir Jake of the Xceptional Kingdom, his trusty, glowing sword Ultraviolet at his side, strode gallantly into the throne room of the stern king.<br/><br/>“What is the meaning of this?” Sir Jake bellowed assertively.<br/><br/>Jake’s squire, Ollie, who was sitting at the edge of the room with the king’s scribe, Marcus, turned his limpid cerulean orbs toward Sir Jake in shock.<br/><br/>“Sir Jake, you must not speak to the king like that!” gasped Ollie.<br/><br/>“I will speak however I wish,” replied Sir Jake bravely. “Ollie, the king has reassigned you to another knight.” Ollie gasped.<br/><br/>“If you’ve got a problem with that, you can duel me for him.” A smirking voice sounded out from behind Sir Jake.<br/><br/>Leaning cockily against the wall behind Sir Jake was none other than the new knight in town... Sir Gabriel.</i></p>
              </div>
              <div className="thread-reply-container">
                <div className="thread-reply">
                  <div className="inner-thread-reply  border-left">
                    <p className="font-bold reply-username">jakesfan444:</p>
                    <p className="reply-content">Where’s the rest OP??!</p>
                  </div>
                  <p className="reply-btn font-bold">... reply</p>

                </div>

                 <div className="thread-reply">
                    <div className="inner-thread-reply  border-left">
                      <p className="font-bold reply-username">Honey_Arcade:</p>
                      <p className="reply-content">Oh boy, I remember when stuff like this was all over the fan blogs. I wonder if the boys ever read any. YIKES.</p>
                    </div>
                    <p className="reply-btn font-bold">... reply</p>

                    <div className="thread-reply">
                      <div className="inner-thread-reply  border-left">
                        <p className="font-bold reply-username">Ollie_oop111:</p>
                        <p className="reply-content">Oh boy, I sure hope not!!!</p>
                      </div>
                      <p className="reply-btn font-bold">... reply</p>
                   </div>


                 </div>

                 <div className="thread-reply">
                    <div className="inner-thread-reply  border-left">
                      <p className="font-bold reply-username">SashaCat:</p>
                      <p className="reply-content">Wow, you’re a really talented writer! Thanks for sharing!</p>
                    </div>
                    <p className="reply-btn font-bold">... reply</p>
                 </div>

                 <div className="thread-reply">
                    <div className="inner-thread-reply  border-left">
                      <p className="font-bold reply-username">xxgoodbyegirlxx:</p>
                      <p className="reply-content">this is so cringey lmao</p>
                    </div>
                    <p className="reply-btn font-bold">... reply</p>
                 </div>
                 
              </div>
               <div className="thread-comment">
                  <div className="divider"></div>

                  <input placeholder="Sign In to Comment" disabled={true}/>
               </div>
               <div className="divider"></div>

            </>
        }



      </div>
    )
  }
}



export default Thread12
